import './stepper.scss';
import { NexusCard, NexusStepper, NexusStepperItem } from '@nexus/react';
import { IStep } from 'constants/commonExportedInterfaces';
import { getUniqueKey } from 'utils/generateButtonIndex';

interface StepperComponentProps {
    steppertype: 'row' | 'column';
    currentStep?: string;
    steps: IStep[];
}

const StepperComponent = ({ steppertype, currentStep, steps }: StepperComponentProps) => {
    let activeStepIndex = steps.findIndex(function (step) {
        return step.name === currentStep;
    });
    const handleStatus = (index: number, status: any) => {
        // to add skipped status
        if (status) {
            return status;
        }
        if (index === activeStepIndex) {
            return 'active';
        }
        if (index < activeStepIndex) {
            return 'complete';
        } else {
            return 'default';
        }
    };
    return (
        <div className='nexus-pb-2'>
            <NexusCard className='stepper-card nexus-padding-0'>
                <NexusStepper
                    className='nexus-subtitle-sm nexus-ml-3 nexus-mempty'
                    steppertype={steppertype}
                    data-testid='stepper'
                >
                    {steps?.map((stepperItem, idx) => {
                        return (
                            <NexusStepperItem
                                key={getUniqueKey(idx)}
                                data-testid={`step-${idx}`}
                                status={handleStatus(idx, stepperItem.status)}
                            >
                                <span className='nexus-ml-1'>{stepperItem.label}</span>
                            </NexusStepperItem>
                        );
                    })}
                </NexusStepper>
            </NexusCard>
        </div>
    );
};
export default StepperComponent;
