import { NexusCard, NexusChip, NexusIcon } from '@nexus/react';
import { SessionStatus, Update, asterik, EXPRESSION, fileStatusFlag } from 'constants/constants';
import { useAppDispatch } from 'store';
import { useEffect, useState } from 'react';

import ButtonComponent from 'app/components/nds/button/button';
import DataSelectionTable from './dataSelectionTable';
import DownloadIcon from '@nexus/core/dist/assets/icons/action/ic_download_24px.svg';
import FileUpload from 'app/components/fileUpload/fileUpload';
import ModalComponent from 'app/components/nds/modal/modal';
import ResetIcon from '@nexus/core/dist/assets/icons/media/ic_replay_24px.svg';
import Select from 'app/components/nds/select/select';
import { createFileFromResponse } from 'services/services';
import { getUniqueKey } from 'utils/generateButtonIndex';
import { stepperRoutes } from 'constants/stepperConstants';
import { useTranslation } from 'react-i18next';
import {
    getCanonicalModels,
    getTemplateFile,
    uploadDataSourceFile,
    getDataSourceTemplateInfo,
    getFile,
    updateTestTemplate,
    updateTestTemplateScriptConfiguration,
    updateCurrentTestTemplate,
    updateActiveStep,
    updateUserFlow,
    showToastMessage,
    getCannonicalModelById,
} from '../../redux/testTemplateReducer';
import { canonicalModelsList, currentTestTemplate } from '../../redux/testTemplateSelectors';
import { useSelector } from 'react-redux';

const DataSelection = () => {
    const dispatch = useAppDispatch();
    const [t] = useTranslation('lang');
    const [showUpload, setShowUpload] = useState<boolean>(false);
    const [intervalID, setIntervalID] = useState<any>(undefined);
    const canonicalModelsData = useSelector(canonicalModelsList);
    const testTemplate = useSelector(currentTestTemplate);
    const {
        userFlow,
        canonicalModelId,
        selectedCanonicalModel,
        id: testTemplateId,
        dataInputFile: uploadedDataSource,
        scriptConfiguration,
    } = testTemplate;
    // selectedCanonicalModelColumns exist only during till expression builder step is active
    const {
        selected_user_selected_columns: selectedCanonicalModelColumns,
        user_selected_columns: savedCanonicalModelColumns,
    } = scriptConfiguration;
    // savedCanonicalModelColumns exist if the user has already saved the test template with user_selected_columns

    // tempSelectedCanonicalModelColumns preffers selectedCanonicalModelColumns over savedCanonicalModelColumns
    const tempSelectedCanonicalModelColumns = selectedCanonicalModelColumns || savedCanonicalModelColumns || [];
    const dataSourceDropdownValues =
        canonicalModelsData.length > 0
            ? canonicalModelsData?.map(function (item: any) {
                  return {
                      label: item.name,
                      value: item.id,
                  };
              })
            : [];
    const dataSourceColumns = selectedCanonicalModel?.schema?.properties
        ? Object.keys(selectedCanonicalModel?.schema?.properties).map((key) => {
              const selectedCanonicalModelProperties = selectedCanonicalModel?.schema?.properties;
              return {
                  col: key,
                  dataType: selectedCanonicalModelProperties[key]?.type,
                  format: selectedCanonicalModelProperties[key]?.format || '',
                  sampleData: selectedCanonicalModelProperties[key]
                      ? selectedCanonicalModelProperties[key]?.examples
                          ? selectedCanonicalModelProperties[key]?.examples?.join(', ')
                          : selectedCanonicalModelProperties[key]?.example
                      : 'No Data Available',
              };
          })
        : [];
    useEffect(() => {
        dispatch(getCanonicalModels());
    }, []);
    useEffect(() => {
        return () => {
            intervalID !== undefined && clearInterval(intervalID);
        };
    }, [intervalID]);
    useEffect(() => {
        if (uploadedDataSource?.status === fileStatusFlag.ready) {
            dispatch(
                showToastMessage({
                    autoClose: 3000,
                    message: t('dataSelection.validationSuccessMsg'),
                    type: 'success',
                }),
            );
        }
        if ([fileStatusFlag.invalid, fileStatusFlag.ready].includes(uploadedDataSource?.status)) {
            clearInterval(intervalID);
        }
    }, [uploadedDataSource?.status]);
    useEffect(() => {
        if (
            canonicalModelId &&
            (selectedCanonicalModel === undefined || Object.keys(selectedCanonicalModel).length === 0)
        ) {
            dispatch(getCannonicalModelById(canonicalModelId));
        }
    }, [canonicalModelId]);
    useEffect(() => {
        if (
            uploadedDataSource?.id &&
            ![fileStatusFlag.invalid, fileStatusFlag.ready].includes(uploadedDataSource?.status)
        ) {
            triggerTestTemplateUpdate(uploadedDataSource.id);
        } else {
            uploadedDataSource?.id && dispatch(getDataSourceTemplateInfo(uploadedDataSource.id));
        }
    }, [uploadedDataSource?.id]);
    const handleSingleCheckBox = (event: any, colValue: string) => {
        const checked = event.target.checked;
        if (checked === undefined) return;
        const selectedCol = dataSourceColumns?.find((obj) => obj?.col === colValue);
        const isAlreadySelected = tempSelectedCanonicalModelColumns?.find((col: any) => col === colValue);
        if (checked) {
            if (selectedCol && !isAlreadySelected) {
                dispatch(
                    updateTestTemplateScriptConfiguration({
                        selected_user_selected_columns: [...tempSelectedCanonicalModelColumns, selectedCol?.col],
                    }),
                );
            }
        } else {
            if (selectedCol && isAlreadySelected) {
                const filteredArray = tempSelectedCanonicalModelColumns
                    ?.filter((col: any) => col !== colValue)
                    .map((col: any) => col);
                dispatch(updateTestTemplateScriptConfiguration({ selected_user_selected_columns: filteredArray }));
            }
        }
    };
    const handleAllCheckBox = (event: any) => {
        const checked = event.target.checked;
        if (checked === undefined) return;
        if (checked) {
            dispatch(
                updateTestTemplateScriptConfiguration({
                    selected_user_selected_columns: dataSourceColumns.map((col) => col?.col),
                }),
            );
        } else {
            dispatch(updateTestTemplateScriptConfiguration({ selected_user_selected_columns: [] }));
        }
    };

    const handleDropDown = (value: number) => {
        if (value && value !== undefined) {
            const selectedCanonicalModel = canonicalModelsData?.find((obj: any) => obj?.id === Number(value));
            dispatch(updateCurrentTestTemplate({ selectedCanonicalModel: selectedCanonicalModel }));
            dispatch(updateTestTemplateScriptConfiguration({ selected_user_selected_columns: [] }));
        }
    };

    const handleUploadData = () => {
        setShowUpload(true);
    };

    const closeModal = () => {
        setShowUpload(false);
    };

    const handleReset = () => {
        intervalID !== undefined && clearInterval(intervalID);
        dispatch(updateCurrentTestTemplate({ dataInputFile: {} }));
    };

    const downLoadErrorFile = () => {
        dispatch(getFile(uploadedDataSource?.dqResultsFileId)).then((response: any) => {
            if (response && response.payload) {
                createFileFromResponse(response);
            }
        });
    };

    const triggerTestTemplateUpdate = (dataFileId: any) => {
        const payload = {
            requestBody: {
                canonicalModelId: selectedCanonicalModel?.id,
                dataInputFile: dataFileId,
                draftStatus: SessionStatus.step2,
                isDraft: true,
            },
            testTemplateId: testTemplateId,
        };
        //PATCH CALL TO COMMENCE DQ CHECK
        dispatch(updateTestTemplate(payload)).then((data: any) => {
            closeModal();
            if (data.payload.status >= 200 && data.payload.status < 300) {
                pollUploadedFileStatus(dataFileId);
            }
        });
    };

    //POLLING API
    const pollUploadedFileStatus = (dataFileId: any) => {
        let count = 0;
        const id = setInterval(() => {
            ++count;
            if (count >= 60) {
                clearInterval(intervalID);
                return;
            }
            dispatch(getDataSourceTemplateInfo(dataFileId));
        }, 5000);
        setIntervalID(id);
    };

    const handleNextButton = () => {
        const scriptConfigurationData = {
            user_selected_columns: tempSelectedCanonicalModelColumns,
            type: EXPRESSION,
        };
        const payload = {
            requestBody: {
                canonicalModelId: selectedCanonicalModel.id,
                dataInputFile: uploadedDataSource?.id || null,
                draftStatus: SessionStatus.step3,
                isDraft: true,
                scriptConfiguration: scriptConfigurationData,
            },
            testTemplateId: testTemplateId,
        };
        dispatch(updateTestTemplate(payload)).then((data: any) => {
            if (data && data?.payload?.status === 200) {
                dispatch(updateActiveStep(stepperRoutes.step_3));
                dispatch(updateUserFlow(Update));
            }
        });
    };

    const handleDownloadFile = () => {
        dispatch(getTemplateFile(selectedCanonicalModel.id))?.then((response: any) => {
            if (response && response.payload && response.payload.status >= 200 && response.payload.status < 300) {
                createFileFromResponse(response);
            }
        });
    };
    const handleUpload = (queue: any) => {
        const payload = {
            file: queue[0],
            id: selectedCanonicalModel?.id,
        };
        dispatch(uploadDataSourceFile(payload)).then((response: any) => {
            if (response && response.payload && response.payload.status >= 200 && response.payload.status < 300) {
                dispatch(
                    showToastMessage({
                        autoClose: 5000,
                        id: 'fileUploadValidation_uploaded_template',
                        message: 'Data validation in progress, you will be notified when document is ready',
                        type: 'info',
                    }),
                );
            } else {
                closeModal();
            }
        });
    };
    const handleBackButton = () => {
        dispatch(updateUserFlow(Update));
        dispatch(updateActiveStep(stepperRoutes.step_1));
    };
    // handle next button state based on column selection and manual upload different scenarios
    const isNextButtonEnabled =
        tempSelectedCanonicalModelColumns.length > 0 && //check if column is selected
        (typeof uploadedDataSource === 'undefined' || // dataInputFile obj will not be present as user lands
            Object.keys(uploadedDataSource).length === 0 || // dataInputFile obj will be empty when reset
            uploadedDataSource.status === fileStatusFlag.ready); // in case of upload in progress
    const eqButtonList = [
        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn nexus-rhythm-top-5'],
            clickButton: handleBackButton,
            label: t('buttons.back'),
        },

        {
            buttonDisabled: !isNextButtonEnabled,
            buttonStyles: ['nexus-btn-primary nexus-rhythm-top-5 nexus-ml-3 nexus-mr-2'],
            clickButton: handleNextButton,
            label: t('buttons.next'),
            labelStyles: 'nexus-btn-default',
        },
    ];
    const getStatusComponent = (status: string) => {
        switch (status) {
            case fileStatusFlag.invalid:
                return (
                    <div className='nexus-row nexus-mt-1 failedValidation'>
                        <div className={'nexus-subtitle-sm nexus-ml-1 top-align2'}>
                            {t('dataSelection.validationFailed')}
                        </div>
                        {uploadedDataSource?.dqResultsFileId && (
                            <>
                                <NexusIcon src={DownloadIcon} className='top-align2 nexus-ml-1' size='sm'></NexusIcon>
                                <div className={'nexus-body-sm reset'} onClick={() => downLoadErrorFile()}>
                                    <u>{t('dataSelection.downloadError')}</u>
                                </div>
                            </>
                        )}
                        {uploadedDataSource?.validationErrors && (
                            <div className={'nexus-body-sm reset'}>{uploadedDataSource.validationErrors}</div>
                        )}
                    </div>
                );
            case fileStatusFlag.ready:
                return;
            case fileStatusFlag.uploaded:
            case fileStatusFlag.validation:
                return (
                    <div className='nexus-row nexus-mt-1 inValidation'>
                        <div className='loader-styles nexus-ml-1' />
                        <div className={'nexus-subtitle-sm nexus-ml-2 nexus-mt-2'}>
                            {t('dataSelection.validationInProgress')}
                        </div>
                    </div>
                );
        }
    };
    return (
        <div data-testid='data-selection-test-designer'>
            {showUpload && (
                <ModalComponent
                    show={showUpload}
                    closeModal={closeModal}
                    header={t('dataSelection.uploadData')}
                    size='lg'
                    extraClass='header-alignment'
                >
                    <FileUpload
                        closeModal={closeModal}
                        handleDownloadFile={handleDownloadFile}
                        handleUpload={handleUpload}
                    />
                </ModalComponent>
            )}
            <NexusCard className='nexus-card-border-0 nexus-p-2'>
                <div className='nexus-subtitle nexus-mb-1'>
                    {t('dataSelectionTestDesigner.header1')}
                    <span className={'asterik-styles'}>{asterik}</span>
                </div>
                <div className='nexus-caption-copy'>{t('dataSelectionTestDesigner.subHeader1')}</div>
                <div className='nexus-row'>
                    <div className='nexus-col-2xs-1 nexus-col-xs-1 nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-3 nexus-col-xl-3 nexus-col-2xl-3 nexus-mt-1'>
                        <Select
                            disabled={(userFlow === Update && canonicalModelId) || uploadedDataSource !== undefined}
                            key={
                                selectedCanonicalModel?.id +
                                '' +
                                dataSourceDropdownValues[0]?.value +
                                '' +
                                testTemplateId
                            }
                            options={dataSourceDropdownValues}
                            customOnChange={handleDropDown}
                            selectedValue={selectedCanonicalModel?.name || t('dataSelectionTestDesigner.pleaseSelect')}
                            disabledOption={t('dataSelectionTestDesigner.pleaseSelect')}
                        />

                        {uploadedDataSource?.status && getStatusComponent(uploadedDataSource?.status)}
                        {uploadedDataSource?.id && (
                            <div>
                                {/* RESET COMPONENT */}
                                <div className={`nexus-row nexus-mt-1 left-align`}>
                                    <NexusIcon src={ResetIcon} className='nexus-mt-1' size='sm'></NexusIcon>
                                    <div
                                        className={'nexus-body-sm reset'}
                                        onClick={() => handleReset()}
                                        data-testid={'reset-button'}
                                    >
                                        <u>{t('dataSelection.resetToDefault')}</u>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='nexus-col-lg-1 nexus-col-xl-1 nexus-col-2xl-1'>
                        <ButtonComponent
                            type={['nexus-btn nexus-btn-medium']}
                            label={t('buttons.uploadData')}
                            click={handleUploadData}
                            disabled={
                                selectedCanonicalModel?.id === undefined ||
                                uploadedDataSource?.status === fileStatusFlag.uploaded ||
                                uploadedDataSource?.status === fileStatusFlag.validation
                            }
                        />
                    </div>
                    <div className='nexus-col-lg-2 nexus-col-xl-2 nexus-col-2xl-2 nexus-mt-1 nexus-ml-5'>
                        {uploadedDataSource?.name && (
                            <NexusChip removable={false} key={uploadedDataSource.name}>
                                {uploadedDataSource?.name}
                            </NexusChip>
                        )}
                    </div>
                </div>
                <div className='nexus-subtitle nexus-mb-1 nexus-mt-2'>{t('dataSelectionTestDesigner.header2')}</div>
                <div className='nexus-caption-copy'>{t('dataSelectionTestDesigner.subHeader2')}</div>
                <div className='nexus-mt-2'>
                    <DataSelectionTable
                        handleSingleCheckBox={handleSingleCheckBox}
                        rows={dataSourceColumns}
                        handleAllCheckBox={handleAllCheckBox}
                        checkedItems={tempSelectedCanonicalModelColumns}
                    />
                </div>
                <div className='nexus-flex-row-reverse nexus-mt-3'>
                    <div className='nexus-row'>
                        {eqButtonList.map(
                            ({ label, clickButton, buttonDisabled, buttonStyles, labelStyles }, index) => {
                                return (
                                    <ButtonComponent
                                        type={buttonStyles}
                                        key={getUniqueKey(index)}
                                        label={label}
                                        disabled={buttonDisabled}
                                        click={clickButton}
                                        labelStyle={labelStyles}
                                        index={index}
                                    />
                                );
                            },
                        )}
                    </div>
                </div>
            </NexusCard>
        </div>
    );
};
export default DataSelection;
