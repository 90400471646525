import './table.scss';
import React, { useEffect, useState } from 'react';
import AscIcon from '@nexus/core/dist/assets/icons/action/ic_arrow_upward_alt_24px.svg';
import DefaultIcon from '@nexus/core/dist/assets/icons/action/ic_sort_24px.svg';
import DescIcon from '@nexus/core/dist/assets/icons/action/ic_arrow_downward_alt_24px.svg';
import { NexusTable } from '@nexus/react';

type InputProps = {
    attrId?: string;
    row: { [id: string]: any }[];
    columns: { [id: string]: any }[];
    currentPage?: number;
    enablePageSizeOption?: boolean;
    maxHeight?: string;
    pageSize?: number;
    pageSizeLabel?: string;
    pageSizeOpt?: number[];
    pagination?: boolean;
    rowSelection?: boolean;
    sortAscIcon?: string;
    sortDefaultIcon?: string;
    sortDescIcon?: string;
    totalItems?: number;
    type?: 'basic' | 'custom';
    handleRow?: any;
    onRowClick?: any;
};

const Table: React.FC<InputProps> = ({
    attrId = '',
    currentPage = 0,
    enablePageSizeOption = true,
    maxHeight = '',
    pageSize = 10,
    pageSizeLabel = 'Items per page',
    pageSizeOpt = [5, 10, 25, 100],
    pagination = false,
    rowSelection = false,
    row,
    columns,
    sortAscIcon = AscIcon,
    sortDefaultIcon = DefaultIcon,
    sortDescIcon = DescIcon,
    totalItems = 0,
    type = 'basic',
    handleRow,
    onRowClick,
}) => {
    const [tableData, setTableData] = useState(row);
    useEffect(() => {
        setTableData([...row]);
    }, [row]);

    return (
        <div>
            {/* <div className='filter-data-text'>{dataFilterText}</div> */}
            <NexusTable
                data-testid='nexus-table'
                attrId={attrId}
                rows={row}
                key={tableData + 't'}
                columns={columns}
                pagination={pagination}
                type={type}
                pageSize={pageSize}
                rowSelection={rowSelection}
                pageSizeLabel={pageSizeLabel}
                pageSizeOpt={pageSizeOpt}
                sortDescIcon={sortDescIcon}
                sortAscIcon={sortAscIcon}
                sortDefaultIcon={sortDefaultIcon}
                maxHeight={maxHeight}
                currentPage={currentPage}
                enablePageSizeOption={enablePageSizeOption}
                totalItems={totalItems}
                onRowSelect={handleRow}
                onClick={($event) => onRowClick($event, row)}
            />
        </div>
    );
};

export default Table;
