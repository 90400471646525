/* eslint-disable no-console */

import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'store';

import ActionIcSearch24px from '@nexus/core/dist/assets/icons/action/ic_search_24px.svg';
import ButtonComponent from 'app/components/nds/button/button';
import { Create } from 'constants/constants';
import CustomTable from 'app/components/nds/customTable/customTable';
import InputComponent from 'app/components/nds/input/input';
import ModalComponent from 'app/components/nds/modal/modal';
import { NexusIcon } from '@nexus/react';
import RadioComponent from 'app/components/nds/radio/radio';
import { stepperRoutes } from 'constants/stepperConstants';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { TestCaseStatus } from 'app/api/atAPI';
import {
    getPublishedTestTemplateData,
    cloneTestTemplate,
    updateActiveStep,
    updateUserFlow,
} from '../redux/testTemplateReducer';
import { useSelector } from 'react-redux';
import { publishedTestTemplateDataList } from '../redux/testTemplateSelectors';

function CreateTemplate() {
    const [t] = useTranslation('lang');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [openClonePopUp, setOpenClonePopUp] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');
    const handleNewTemplate = () => {
        dispatch(updateUserFlow(Create));
        dispatch(updateActiveStep(stepperRoutes.step_1));
        navigate('/test-template/template');
    };
    const handleExistingTemplate = () => {
        setOpenClonePopUp(true);
    };
    //------------------table data----------------------------------------------------------
    const publishedTestTemplates = useSelector(publishedTestTemplateDataList);
    const { content: publishedTestTemplateData, totalElements } = publishedTestTemplates || 0;
    const handleRadioBtn = (templateId: string) => {
        setSelectedTemplateId(templateId);
    };
    const tableData = publishedTestTemplateData
        ? publishedTestTemplateData?.map((data: any) => {
              return {
                  description: data?.testCaseDescription,
                  name: data?.testCaseName,
                  radioBtn: (
                      <RadioComponent
                          name={data?.testCaseName}
                          value={data?.testCaseId}
                          OnChangeHandler={() => {
                              handleRadioBtn(data?.testCaseId);
                          }}
                          selectedValue={selectedTemplateId}
                      />
                  ),
              };
          })
        : [];
    //------------------------------------------------------------------------------------------
    //-----------------------pagination states-------------------------------------------------
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    //------------------------------------------------------------------------------------------
    //---------------------------useEffect to update table data-----------------------------
    useEffect(() => {
        const payload = {
            page: currentPage - 1 < 0 ? 0 : currentPage - 1,
            search: searchText || '',
            size: pageSize,
            status: TestCaseStatus.Published,
        };
        openClonePopUp && dispatch(getPublishedTestTemplateData(payload));
    }, [searchText, currentPage, pageSize, openClonePopUp]);
    //------------------------------------------------------------------------------------------
    const eqButtonList = [
        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn nexus-btn-fluid nexus-mt-2 nexus-p-2 nexus-ml-2'],
            clickButton: handleExistingTemplate,
            label: t('testDesigner.newFromExisting'),
            labelStyles: 'handle-buttons',
        },

        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn nexus-btn-fluid nexus-btn-primary nexus-mt-2'],
            clickButton: handleNewTemplate,
            label: t('testDesigner.newTemplate'),
            labelStyles: 'handle-buttons',
        },
    ];

    const testTemplateTableHeader = [
        {
            field: 'radioBtn',
            id: 'radioBtn',
            isSortable: false,
            label: '',
            minWidth: 10,
        },
        {
            field: 'name',
            id: 'name',
            isSortable: false,
            label: t('templateSelection.testTemplate'),
            minWidth: 100,
        },
        {
            field: 'description',
            id: 'description',
            isSortable: false,
            label: t('templateSelection.testDescription'),
            minWidth: 350,
        },
    ];
    const paginationHandler = (currentPage: number, currentPageSize: number) => {
        setPageSize(currentPageSize);
        setCurrentPage(currentPage);
    };
    let searchTimeout: NodeJS.Timeout | null = null;

    const handleSearchText = (event: React.FormEvent<HTMLNexusInputElement>) => {
        const target = event.target as HTMLNexusTextareaElement;
        const searchStr = target.value?.trim();

        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        // debouncing added
        searchTimeout = setTimeout(() => {
            setSearchText(searchStr);
        }, 500);
    };
    const createCloneTestTemplate = () => {
        dispatch(cloneTestTemplate(parseInt(selectedTemplateId))).then((response: any) => {
            if (response?.payload?.status === 201) {
                navigate('/test-template/template/' + response?.payload?.data?.id || '');
            }
        });
    };
    const cloneTestTemplateComponent = () => {
        return (
            <>
                <div className='nexus-row'>
                    <InputComponent
                        placeholder={t('riskControlTestcase.search')}
                        initialValue={searchText}
                        onChange={handleSearchText}
                    >
                        <NexusIcon data-testid='search-icon' src={ActionIcSearch24px}></NexusIcon>
                    </InputComponent>
                </div>
                <div key='clone-table' className='nexus-mt-3'>
                    <div className={'scroller'}>
                        <CustomTable
                            columnsDef={testTemplateTableHeader}
                            data={tableData}
                            totalItems={totalElements}
                            max={Math.ceil(totalElements / pageSize)}
                            paginationFunc={paginationHandler}
                            currentPageNo={currentPage}
                            pageSizeCustom={pageSize}
                            noDataMessage={t('norecordAvailable')}
                            pagination
                        />
                    </div>
                </div>
                <div className='nexus-flex-row-reverse'>
                    <div className='nexus-row'>
                        <div className='nexus-mr-2'>
                            <ButtonComponent
                                type={['nexus-btn nexus-rhythm-top-3']}
                                label={t('buttons.back')}
                                click={() => setOpenClonePopUp(false)}
                            />
                        </div>
                        <div className='nexus-mr-1'>
                            <ButtonComponent
                                extraClass='nexus-btn-default'
                                type={['nexus-btn-primary nexus-rhythm-top-3']}
                                label={t('buttons.next')}
                                disabled={!selectedTemplateId}
                                click={createCloneTestTemplate}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return (
        <div className='nexus-container' data-testid='create-test-template'>
            <ModalComponent
                show={openClonePopUp}
                closeModal={() => setOpenClonePopUp(false)}
                children={cloneTestTemplateComponent()}
                header={t('testDesigner.selectTemplate')}
                size='md'
                extraClass='header-alignment'
            />
            <div className='nexus-row'>
                <div className='nexus-col-lg-3 nexus-col-xl-3 nexus-mt-2 nexus-h4'>
                    {t('testDesigner.createTemplate')}
                </div>
                <div className='nexus-col-lg-offset-6 nexus-col-xl-offset-6 nexus-col-lg-3 nexus-col-xl-3'>
                    <div className='nexus-flex-row-reverse'>
                        {eqButtonList.map(({ label, clickButton, buttonDisabled, buttonStyles, labelStyles }) => {
                            return (
                                <ButtonComponent
                                    type={buttonStyles}
                                    label={label}
                                    disabled={buttonDisabled}
                                    click={clickButton}
                                    labelStyle={labelStyles}
                                    key={label}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateTemplate;
