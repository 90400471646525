import { ASC, DESC, pageSizeOptions } from 'constants/constants';
import { NexusCheckbox, NexusIcon, NexusPagination, NexusTable } from '@nexus/react';
import React, { useEffect, useState } from 'react';

import AscIcon from '@nexus/core/dist/assets/icons/action/ic_arrow_upward_alt_24px.svg';
import ContentSort24px from '@nexus/core/dist/assets/icons/action/ic_sort_24px.svg';
import DescIcon from '@nexus/core/dist/assets/icons/action/ic_arrow_downward_alt_24px.svg';
import styles from './customTable.module.scss';
import ContentIcAdd24px from '@nexus/core/dist/assets/icons/action/ic_add_24px.svg';
import ContentIcRemove24px from '@nexus/core/dist/assets/icons/action/ic_remove_24px.svg';
import Select from '../select/select';

type CustomTableProps = {
    attrId?: string;
    pagination?: boolean;
    expand?: boolean;
    pageSizeCustom?: number;
    currentPageNo?: number;
    totalItems?: number;
    columnsDef: {
        id: string;
        field: string;
        label: any;
        isSortable?: boolean;
        headerClass?: string;
        cellClass?: string;
        minWidth?: number;
        sortAscending?: any;
        enableCheckBox?: boolean;
    }[];
    data: { [id: string]: string }[];
    paginationFunc?: (currentPage: number, currentPageSize: number) => void;
    elementLabel?: string;
    handleSort?: (element: string, field: string, index: number, id: string) => void;
    sortIcon?: boolean | string;
    sortCol?: number;
    noDataMessage?: string;
    handleAllCheckBox?: any;
    type?: string;
    selectAllCheckBox?: boolean;
    enableExpand?: boolean;
    expandedRowComponent?: any;
    enableRowExpand?: boolean;
    className?: string;
    max?: number;
    selectedItems?: any;
    reviewHandler?: any;
};

const CustomTable: React.FC<CustomTableProps> = ({
    pagination = false,
    pageSizeCustom,
    columnsDef,
    currentPageNo,
    data,
    paginationFunc,
    handleSort,
    sortIcon,
    sortCol,
    noDataMessage,
    handleAllCheckBox,
    selectAllCheckBox,
    enableRowExpand = false,
    expandedRowComponent: ExpandedRowComponent,
    max,
    attrId,
    selectedItems,
    reviewHandler,
}) => {
    const [customRowListData, setCustomRowListData] = useState(data);
    const [sortIconStyle, setSortIconStyle] = useState<string>(ContentSort24px);

    useEffect(() => {
        setCustomRowListData(data);
        if (sortIcon === undefined) {
            setSortIconStyle(ContentSort24px);
        } else if (sortIcon === ASC) {
            setSortIconStyle(AscIcon);
        } else if (sortIcon === DESC) {
            setSortIconStyle(DescIcon);
        }
    }, [data, sortIcon]);

    const setPaginationChangeEvent = (event: any) => {
        const currentPageSize = pageSizeCustom ?? 5;
        const currentPage = event.detail ?? 1;
        if (currentPageNo !== currentPage) {
            setCustomRowListData([]);
            paginationFunc && paginationFunc(currentPage, currentPageSize);
        }
    };
    const setPageSize = (pageSize: number) => {
        if (pageSizeCustom !== pageSize) {
            setCustomRowListData([]);
            paginationFunc && paginationFunc(1, pageSize);
        }
    };

    const getColumnDefIndex = (_index: number) => {
        return `column-def-${_index}`;
    };

    const getRowIndex = (ind: number) => {
        return `row-${ind}`;
    };

    const getRowColIdx = (ind: number, _index: number) => {
        return `row-${ind}-col-${_index}`;
    };

    const setExpandRow = (rowId: number) => {
        const updatedData = customRowListData.map((d: any) => {
            if (d.id === rowId) {
                d.expand = !d.expand;
            }
            return d;
        });
        setCustomRowListData(updatedData);
    };
    const tHeadSection = columnsDef.map((element: any, _index) => (
        <>
            {element?.field === 'expand' && <th className={`${styles['width-1']}`}></th>}
            <th
                className={`${styles[`width-${element.minWidth}`]}`}
                key={getColumnDefIndex(_index)}
                onClick={() =>
                    element.isSortable && handleSort && handleSort(element.label, element.field, _index, element.id)
                }
                data-testid={element.label}
            >
                {element?.enableCheckBox ? (
                    <>
                        <NexusCheckbox
                            data-testid='headerCheckBox-cep'
                            onClick={handleAllCheckBox}
                            checked={selectAllCheckBox}
                            className={styles['checkbox-style']}
                        ></NexusCheckbox>
                        <span className={styles['checkbox-label-style']}>{element.label}</span>
                    </>
                ) : (
                    element.label
                )}

                <NexusIcon
                    src={sortCol === _index ? sortIconStyle : ContentSort24px}
                    className={`${element.isSortable ? styles['image-height'] : 'nexus-hidden'}`}
                />
            </th>
        </>
    ));
    const tBodySection = customRowListData.length ? (
        customRowListData.map((data: any, ind) => (
            <>
                <tr data-testid='table-body-row' key={getRowIndex(ind)}>
                    {enableRowExpand && (
                        <td className={`${styles['width-10']}`}>
                            <NexusIcon
                                src={data.expand ? ContentIcRemove24px : ContentIcAdd24px}
                                onClick={() => setExpandRow(data.id)}
                                data-testid='expand-all'
                                size='sm'
                                className={styles['expand-icon']}
                            ></NexusIcon>
                        </td>
                    )}
                    {columnsDef &&
                        columnsDef.map((elem: any, _index) => (
                            <td
                                className={`${styles[`width-${elem.minWidth}`]}` || ''}
                                data-testid={`table-body-row-${elem.field}`}
                                key={getRowColIdx(ind, _index)}
                            >
                                {data[elem?.field as keyof typeof data]}
                            </td>
                        ))}
                </tr>
                {data.expand && ExpandedRowComponent && (
                    <tr>
                        <td colSpan={2}></td>
                        <ExpandedRowComponent {...data.expandedRowData} />
                    </tr>
                )}
            </>
        ))
    ) : (
        <td className={styles['center-align']} colSpan={columnsDef.length || 8}>
            {noDataMessage}
        </td>
    );

    return (
        <>
            <NexusTable type='custom' className={styles['table-container']}>
                <thead slot='thead'>
                    <tr>{tHeadSection}</tr>
                </thead>
                <tbody slot='tbody' data-testid='custom-table-wrapper'>
                    {tBodySection}
                </tbody>
            </NexusTable>
            {pagination && (
                <div className={styles['table-pagination']}>
                    <div className={styles['align-content']}>
                        <span className={styles['show-text']}>Show: </span>
                        <Select
                            name={'pageSize'}
                            key={'pageSize'}
                            selectedValue={`${pageSizeCustom}`}
                            options={pageSizeOptions}
                            customOnChange={setPageSize}
                        />
                        {selectedItems?.length > 1 && (
                            <div className={styles['selected-templates']}>
                                {`${selectedItems?.length} selected`}
                                <span className={styles['review-text']} onClick={reviewHandler}>
                                    Review All
                                </span>
                            </div>
                        )}
                    </div>
                    <NexusPagination
                        attrId={attrId || ''}
                        current={currentPageNo}
                        max={max}
                        onChangeEvent={setPaginationChangeEvent}
                    ></NexusPagination>
                </div>
            )}
        </>
    );
};

export default CustomTable;
