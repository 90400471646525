import './expressionBuilder.scss';

import { EXPRESSION, MVEL, SessionStatus, VALID } from 'constants/constants';
import { useAppDispatch } from 'store';
import React, { useEffect } from 'react';
import { stepperRoutes } from 'constants/stepperConstants';
import { useTranslation } from 'react-i18next';
import AccordionExpressionBuilder from './accordionExpressionBuilder';
import {
    getCanonicalModelById,
    getExpPreview,
    updateTestSession,
    updateActiveStep,
} from '../../redux/testSessionReducer';
import { currentTestSession } from '../../redux/testSessionSelectors';
import { useSelector } from 'react-redux';
import { NexusCard } from '@nexus/react';

const ExpressionBuilder: React.FC = () => {
    const [t] = useTranslation('lang');
    const dispatch = useAppDispatch();
    const testSession = useSelector(currentTestSession);
    const {
        testSessionName,
        testSessionDescription,
        testSessionId,
        testSessionIsDraft,
        testSessionTestCases,
        dataSources,
    } = testSession || {};

    // get columns info from cannonical model
    useEffect(() => {
        dataSources?.forEach((dataSource: any) => {
            dispatch(getCanonicalModelById(dataSource?.id));
        });
    }, []);

    const handleBackButton = () => {
        dispatch(updateActiveStep(stepperRoutes.step_4));
    };

    const handleNextButton = async () => {
        const updatedTestSessionTestCase = testSessionTestCases?.map((testCase: any) => {
            const { scriptConfiguration, testCaseParameters } = testCase;
            const { execution_parameters: test_execution_parameters } = testCaseParameters || {};
            const { execution_parameters } = scriptConfiguration;
            return {
                testCaseId: testCase.id || testCase.testCaseId,
                testCaseParameters: {
                    execution_parameters: test_execution_parameters || execution_parameters,
                },
            };
        });
        const filteredDataSources = dataSources?.map((dataSource: any) => ({
            commonKey: dataSource?.commonKey,
            dataInputFile: dataSource?.dataInputFile?.id ? { id: dataSource?.dataInputFile?.id } : null,
            dateFrom: dataSource?.dateFrom,
            dateTo: dataSource?.dateTo,
            filterBy: dataSource?.filterBy,
            id: dataSource.id,
            name: dataSource.name,
        }));
        const payload = {
            testSessionRequest: {
                dataSources: filteredDataSources,
                description: testSessionDescription,
                draftStatus: SessionStatus.step6,
                isDraft: testSessionIsDraft ?? true,
                name: testSessionName,
                testSessionTestCases: updatedTestSessionTestCase,
            },
            id: testSessionId,
        };
        let valid = true;
        for (const testCase of testSessionTestCases) {
            if (testCase.scriptConfiguration?.type === MVEL) continue;
            if (testCase.testCasePreview !== '') continue;
            let response: any = await getPreviewExpression(testCase);
            if (response?.payload?.previewStatus !== VALID) {
                valid = false;
            }
        }
        if (!valid) return;
        dispatch(updateTestSession(payload)).then((data: any) => {
            if (data && data?.payload?.status >= 200 && data?.payload?.status < 300) {
                dispatch(updateActiveStep(stepperRoutes.step_6));
            }
        });

        // mvel type expression does not need validation
    };

    const getPreviewExpression = (testCase: any) => {
        const { scriptConfiguration, testCaseParameters } = testCase;
        let executionParameters;
        if (
            testCaseParameters?.execution_parameters &&
            Object.keys(testCaseParameters?.execution_parameters).length > 0
        ) {
            executionParameters = testCaseParameters?.execution_parameters;
        } else {
            executionParameters = testCaseParameters;
        }
        const payload = {
            canonicalModelId: testCase?.canonicalModelId,
            scriptConfiguration: {
                execution_parameters: executionParameters,
                expression_object: scriptConfiguration?.expression_object,
                type: EXPRESSION,
                user_selected_columns: scriptConfiguration?.user_selected_columns,
            },
        };
        return dispatch(getExpPreview({ testTemplatePreviewRequest: payload, testCaseId: testCase?.testCaseId }));
    };
    const sortedTestSessionTestCases = testSessionTestCases?.slice().sort((a: any, b: any) => {
        if (a.canonicalModelId < b.canonicalModelId) return -1;
        if (a.canonicalModelId > b.canonicalModelId) return 1;
        return 0;
    });

    return (
        <div data-testid='expression-builder'>
            <NexusCard>
                <div className='nexus-row'>
                    <div className='circle nexus-ml-1'>1</div>
                    <div className='page-title nexus-ml-1'>{t('expressionBuilder.header1')}</div>
                </div>
                <div className='page-title-text nexus-mt-1 nexus-ml-3 nexus-mb-2'>
                    {t('expressionBuilder.headerText')}
                </div>
                <div className='templates-info'>
                    <div className='templates-header'>{t('expressionBuilder.templateNames')}</div>
                    {sortedTestSessionTestCases?.map((testCase: any, index: number) => {
                        const updatedTestCase = { ...testCase, showAccordion: index === 0 };
                        return <AccordionExpressionBuilder key={testCase?.testCaseId} testCase={updatedTestCase} />;
                    })}
                </div>
                <div className='nexus-flex-row-reverse nexus-mt-3'>
                    <div className='nexus-row'>
                        <button
                            type='button'
                            onClick={handleBackButton}
                            data-testid='expression-builder-back'
                            className={`nexus-ml-4 nexus-btn nexus-btn nexus-rhythm-top-5`}
                        >
                            {t('buttons.back')}
                        </button>
                        <button
                            type='submit'
                            onClick={handleNextButton}
                            data-testid='expression-builder-next'
                            className={`nexus-ml-4 nexus-btn nexus-btn-primary nexus-rhythm-top-5 nexus-mx-3`}
                            disabled={false}
                        >
                            {t('buttons.next')}
                        </button>
                    </div>
                </div>
            </NexusCard>
        </div>
    );
};

export default ExpressionBuilder;
