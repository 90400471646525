import './testSessionDetails.scss';

import { Fail, doesNotRepeat, once, completeBadge } from 'constants/constants';
import { NexusCard, NexusIcon } from '@nexus/react';
import ArrowDown from '@nexus/core/dist/assets/icons/action/ic_expand_more_24px.svg';
import ArrowUp from '@nexus/core/dist/assets/icons/action/ic_expand_less_24px.svg';
import BreadcrumbComponent from 'app/components/nds/breadcrumb/breadcrumb';
import ButtonComponent from 'app/components/nds/button/button';
import TestSessionDetailsTable from './TestSessionDetailsTable';
import { getHomeUrl } from 'utils/redirectionUtils';
import { useAppDispatch } from 'store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { generateExpression } from 'utils/expressionBuilderUtils';
import {
    getTestSession,
    getTestCases,
    getCanonicalModelById,
    runTestSession,
    fetchTestSessionRuns,
} from '../redux/testSessionReducer';
import { currentTestSession } from '../redux/testSessionSelectors';
import { useSelector } from 'react-redux';

const TestSessionDetails: React.FC = () => {
    const [t] = useTranslation('lang');
    const dispatch = useAppDispatch();
    const [showTemplate, setShowTemplate] = useState(false);
    const [fetchedTestCases, setFetchedTestCases] = useState(false);
    const { id } = useParams();
    const testSession = useSelector(currentTestSession);
    const { testSessionId, testSessionName, scheduler, testSessionTestCases, canonicalModelData } = testSession;
    const breadcrumbData = [
        { isEnabled: false, name: 'Home', path: getHomeUrl() },
        { isEnabled: false, name: testSessionName, path: '' },
    ];

    useEffect(() => {
        if (id && testSessionId !== id && !fetchedTestCases) {
            dispatch(getTestSession(parseInt(id)));
        }
        if (testSessionTestCases.length > 0 && !fetchedTestCases) {
            setFetchedTestCases(true);
            const ids = testSessionTestCases.map((item: any) => item.testCaseId);
            dispatch(getTestCases(ids));
        }
    }, [id, testSessionTestCases]);

    // to get the template details and the data source details
    useEffect(() => {
        if (testSessionTestCases?.[0]?.canonicalModelId) {
            dispatch(getCanonicalModelById(testSessionTestCases?.[0]?.canonicalModelId));
        }
    }, [testSessionTestCases?.[0]?.canonicalModelId]);

    let intervalID: any;
    const handleRunButton = async () => {
        try {
            dispatch(runTestSession(testSessionId));
            const getTestExecutionpayload = {
                page: 0,
                size: 5,
                sort: ['timestamp,desc'],
                testSessionId: testSessionId,
            };
            dispatch(fetchTestSessionRuns(getTestExecutionpayload));
            let count = 0;
            intervalID = setInterval(() => {
                if (window.location.pathname?.includes('/test-session-details')) {
                    ++count;
                    dispatch(fetchTestSessionRuns(getTestExecutionpayload)).then((response: any) => {
                        if (response && response.payload) {
                            const content = response?.payload?.data?.content?.[0];
                            const { status } = content;
                            if (status === completeBadge || status === Fail) {
                                clearInterval(intervalID);
                            }
                        }
                    });
                    if (count >= 10) {
                        clearInterval(intervalID);
                        return;
                    }
                } else {
                    intervalID && clearInterval(intervalID);
                    return;
                }
            }, 4000);
        } catch (error) {
            intervalID && clearInterval(intervalID);
        }
    };

    return (
        <div className='nexus-container' data-testid='test-session-details'>
            <p className='nexus-h4 margin-0'>{t('testSessionDetails.header')}</p>
            <div className='nexus-row nexus-mb-2 align-content'>
                <div className='nexus-ml-1'>
                    <BreadcrumbComponent separator='>' data={breadcrumbData} />
                </div>
                <div className='nexus-mr-3'>
                    <ButtonComponent
                        type={['nexus-btn-primary']}
                        label={t('testSessionDetails.runTest')}
                        click={handleRunButton}
                    />
                </div>
            </div>
            <NexusCard className='nexus-card-border-0 nexus-p-1'>
                <div className='nexus-row card-content'>
                    <div className='nexus-col-lg-8 align-content'>
                        <div className='nexus-col-lg-3'>
                            <div className='label-style nexus-mt-1 nexus-ml-2 text-opacity'>
                                {t('testSessionDetails.testCadence')}
                            </div>
                            <div className='nexus-ml-2 text-style'>
                                {scheduler?.frequency === once ? doesNotRepeat : ''}
                            </div>
                        </div>
                        <div className='nexus-col-lg-3'>
                            <div className='label-style nexus-mt-1 text-opacity'>
                                {t('testSessionDetails.dataSource')}
                            </div>
                            <div className='text-style'>
                                {canonicalModelData?.dataInputFile?.name || canonicalModelData?.tableName || ''}
                            </div>
                        </div>
                        <div className='nexus-col-lg-6'>
                            <div className='label-style nexus-mt-1 text-opacity'>{t('testSessionDetails.nextRun')}</div>
                            <div className='text-style'>
                                {scheduler?.frequency === once ? 'N/A' : scheduler?.nextRun}
                            </div>
                        </div>
                    </div>
                    <div className='btn-align'>
                        <ButtonComponent
                            type={['nexus-btn-icon']}
                            label={!showTemplate ? t('testSessionDetails.seeMore') : t('testSessionDetails.seeLess')}
                            click={() => setShowTemplate(!showTemplate)}
                            icon={
                                !showTemplate ? (
                                    <NexusIcon src={ArrowDown} size='md'></NexusIcon>
                                ) : (
                                    <NexusIcon src={ArrowUp} size='md'></NexusIcon>
                                )
                            }
                            extraClass='btn-direction'
                        />
                    </div>
                </div>
                {showTemplate && (
                    <div className='nexus-col-lg-12'>
                        <div className='nexus-row'>
                            <div className='nexus-col-lg-2'>
                                <div className='label-style nexus-mt-1 nexus-ml-2 text-opacity'>
                                    {t('testSessionDetails.templateSelection')}
                                </div>
                            </div>
                            <div className='nexus-col-lg-10'>
                                <div className='label-style nexus-mt-1 text-opacity'>
                                    {t('testSessionDetails.expression')}
                                </div>
                            </div>
                            {testSessionTestCases?.map((data: any) => {
                                const { name, scriptConfiguration } = data;
                                return (
                                    <div className='nexus-row nexus-col-lg-12'>
                                        <div className='nexus-col-lg-2 nexus-ml-2 text-style'>{name}</div>
                                        <div className='text-style'>
                                            {generateExpression(scriptConfiguration) || ''}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </NexusCard>
            <NexusCard className='nexus-card-border-0 nexus-p-3 nexus-mt-3'>
                <TestSessionDetailsTable />
            </NexusCard>
        </div>
    );
};
export default TestSessionDetails;
