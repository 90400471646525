import './recursiveStyle.scss';

import { useEffect, useState } from 'react';

import CloneIcon from '@nexus/core/dist/assets/icons/action/ic_content_copy_24px.svg';
import DeleteIcon from '@nexus/core/dist/assets/icons/action/ic_delete_24px.svg';
import { Field } from 'react-final-form';
import LockIcon from '@nexus/core/dist/assets/icons/action/ic_lock_24px.svg';
import { NexusIcon, NexusCheckbox, NexusToggle } from '@nexus/react';
import NexusInput from '../nds/input/input';
import OpenLockIcon from '@nexus/core/dist/assets/icons/action/ic_lock_open_24px.svg';
import Select from '../nds/select/select';
import { IS_NULL, defaultOperators } from './constants';
import { input_class } from 'constants/constants';
import './ruleExpression.scss';

interface IAddExp {
    item: any;
    id: number;
    onDelete: (item: []) => void;
    onClone: (id: number) => void;
    handleExpDropdown: (id: number, key: string, selection: any) => void;
    handleFieldVal: (event: any, item: any, id: number) => void;
    columnsDef: { label: string; value: string; type: string; format?: string }[];
    validate: boolean;
    disable: boolean;
    cloneButon: boolean;
    fieldOperators?: any;
    enableLockButton: boolean;
    disableField?: boolean;
    caseSensitive?: boolean;
    enableNot?: boolean;
}
const getTime = (hour: any = '00', minute: any = '00', second: any = '00') => {
    return `${hour}:${minute || '00'}:${second || '00'}.000`;
};
const RuleExpression = ({
    cloneButon,
    onClone,
    onDelete,
    item,
    id,
    handleExpDropdown,
    columnsDef,
    validate,
    disable,
    enableLockButton,
    disableField,
    caseSensitive,
    enableNot,
}: IAddExp) => {
    const [lock, setLock] = useState(disable);
    const [compare, setCompare] = useState(item?.compare_with_field || false); //[{label: 'test', value: 'test'}
    const [fieldType, setFieldType] = useState(item.type);
    let col_format = columnsDef.find((col: any) => col.label === item.field)?.format || '';
    const [format, setFormat] = useState(col_format);
    const [compareFields, setCompareFields] = useState<any[]>([]); //[{label: 'test', value: 'test'}
    const required = (value: any) => (value || value === 0 || value === '' ? undefined : 'Required');
    const getColmunCampareData = () => {
        if (
            item.compare_with_field &&
            !columnsDef.find((col) => col.label === item.compare_field_name) &&
            item.compare_field_name
        ) {
            columnsDef.push({
                format: format,
                label: item.compare_field_name,
                type: fieldType,
                value: item.compare_field_name,
            });
        }
        return columnsDef.filter(
            (col: any) =>
                col.type === fieldType &&
                (format === 'date' || fieldType === 'string' ? col?.format === format : true) &&
                col.label !== item.field,
        );
    };
    useEffect(() => {
        setLock(disable);
    }, [disable]);
    useEffect(() => {
        const fields = getColmunCampareData();
        setCompareFields(fields);
        if (fields.length === 0) {
            setCompare(false);
            handleExpDropdown(id, 'compare_with_field', false);
        }
    }, [item.field]);
    const getClass = (error: string, val: any): string => {
        let className = error ? input_class : '';
        if (validate && !val) {
            className = className + ' expression-invalid';
        }

        return className;
    };
    const handleLock = (val: boolean) => {
        setLock(val);
        handleExpDropdown(id, 'disable', val);
    };

    const handleCaseSensivity = (val: boolean) => {
        handleExpDropdown(id, 'ignore_case', val === true);
    };
    const getRuleIndex = (_index: any) => {
        return `rule-${_index}`;
    };

    const getBooleanFieldOptions = () => {
        return [
            {
                format: format,
                label: 'True',
                type: fieldType,
                value: 'true',
            },
            {
                format: format,
                label: 'False',
                type: fieldType,
                value: 'false',
            },
        ];
    };
    const handleDropdown = (event: any, date: string) => {
        const timeArray = event?.target?.value ? event.target.value.split(':') : ['00', '00'];
        handleExpDropdown(id, 'user_value', `${date} ${getTime(...timeArray)}`);
        handleCaseSensivity(false);
    };

    const getFiled = (type: string) => {
        col_format = columnsDef.find((col: any) => col.label === item.field)?.format || '';
        switch (`${type}${col_format ? '_' : ''}${col_format}`) {
            case 'boolean':
                return (
                    <Select
                        name='compare-select'
                        key={'boolean-' + fieldType + format + item.user_value}
                        selectedValue={item.user_value}
                        disabled={lock}
                        options={getBooleanFieldOptions()}
                        classes='rule-field'
                        customOnChange={(selection) => {
                            handleExpDropdown(id, 'user_value', selection);
                        }}
                    ></Select>
                );
            case 'string_timestamp':
                const [date, time] = item?.user_value?.split(' ') || ['', ''];
                const timeArray = time?.split(':') || ['00', '00'];
                const displayTimeFormat = `${timeArray[0]}:${timeArray[1]}`;
                return (
                    <>
                        <NexusInput
                            dataID='input-value'
                            initialValue={date || ''}
                            key={'date' + date}
                            type={'date'}
                            onChange={(event) => {
                                handleExpDropdown(
                                    id,
                                    'user_value',
                                    `${event.target.value} ${getTime(...displayTimeFormat.split(':'))}`,
                                );
                                handleCaseSensivity(false);
                            }}
                            extraClass={'rule-input'}
                        />
                        <input
                            value={displayTimeFormat}
                            key={'timestamp' + time}
                            className='timeStamp-input'
                            type='time'
                            name='timestamp'
                            onChange={(event) => handleDropdown(event, date)}
                        ></input>
                    </>
                );
            default:
                return (
                    <Field
                        name={'id' + item.id + 'lat'}
                        value={item.user_value}
                        initialValue={item.user_value}
                        validate={required}
                    >
                        {(props) => {
                            return (
                                <div className='rule-value'>
                                    <NexusInput
                                        {...props.input}
                                        dataID='input-value'
                                        initialValue={props.input.value}
                                        type={format.indexOf('date') > -1 ? 'date' : item.type}
                                        onChange={(event) => {
                                            props.input.onChange(event);
                                            handleExpDropdown(id, 'user_value', event.target.value);
                                            if (item.type !== 'string' || format.indexOf('date') > -1) {
                                                handleCaseSensivity(false);
                                            }
                                        }}
                                        extraClass={'rule-input ' + getClass(props.meta.error, props.input.value)}
                                    />
                                    {props.meta.error && (
                                        <span className={`nexus-my-1 warning-style`}>{props.meta.error}</span>
                                    )}
                                </div>
                            );
                        }}
                    </Field>
                );
        }
    };
    const getOperators = (type: string) => {
        switch (`${type}${format ? '_' : ''}${format}`) {
            case 'string_date':
            case 'string_timestamp':
                return defaultOperators.date.filter((opt: any) => (compare ? opt.label !== IS_NULL : true));
            default:
                return defaultOperators[type]?.filter((opt: any) => (compare ? opt.label !== IS_NULL : true));
        }
    };
    return (
        <div id='expression-div' className='flex-container' key={id}>
            {columnsDef !== undefined && columnsDef.length > 0 && (
                <Select
                    key={columnsDef ? columnsDef[0].label : item.field}
                    selectedValue={item.field}
                    disabled={lock || disableField}
                    customOnChange={(selection) => {
                        handleExpDropdown(id, 'field', selection);
                        const { type: fieldType, format } = columnsDef.filter((col: any) => col.label === selection)[0];
                        const defaultOperatorsForType = defaultOperators?.[fieldType] ?? [];
                        const { value: fieldOperator } = defaultOperatorsForType?.[0] ?? {};
                        handleExpDropdown(id, 'operator', fieldOperator);
                        handleExpDropdown(id, 'type', fieldType);
                        setFormat(format || '');
                        setFieldType(fieldType);
                        if (compare) {
                            delete item.compare_field_name;
                        }
                    }}
                    options={columnsDef}
                    classes='rule-field'
                ></Select>
            )}
            <div className='select-div'>
                {item.type !== undefined && (
                    <select
                        data-testid='operator-select'
                        className='nexus-select width-select'
                        value={item?.operator}
                        disabled={lock || disableField}
                        onChange={(selection) => handleExpDropdown(id, 'operator', selection?.target?.value)}
                    >
                        {getOperators(item.type)?.map((opt: { value: any; label: string }, _index: any) => (
                            <option value={opt.value} key={getRuleIndex(_index)}>
                                {opt.label}
                            </option>
                        ))}
                    </select>
                )}
            </div>
            {item.operator !== IS_NULL &&
                (columnsDef !== undefined && compareFields.length && compare ? (
                    <Select
                        name='compare-select'
                        key={fieldType + format + item.field}
                        selectedValue={item.compare_field_name}
                        disabled={lock || disableField}
                        options={compareFields}
                        classes='rule-field'
                        customOnChange={(selection) => {
                            handleExpDropdown(id, 'compare_field_name', selection);
                        }}
                    ></Select>
                ) : (
                    getFiled(item.type)
                ))}

            {cloneButon && (
                <NexusIcon
                    data-testid='clone-icon'
                    className={lock ? 'disable' : ''}
                    size='xs'
                    src={CloneIcon}
                    onClick={() => onClone(item)}
                />
            )}
            {enableLockButton &&
                (lock ? (
                    <NexusIcon
                        data-testid='lock-icon'
                        className={disable ? 'disable' : ''}
                        size='xs'
                        src={LockIcon}
                        onClick={() => handleLock(false)}
                    />
                ) : (
                    <NexusIcon
                        data-testid='unlock-icon'
                        className={disable ? 'disable' : ''}
                        size='xs'
                        src={OpenLockIcon}
                        onClick={() => handleLock(true)}
                    />
                ))}
            {enableNot && (
                <NexusCheckbox
                    checked={item.notOperator}
                    disabled={disable || disableField}
                    data-testid={'test'}
                    onClick={(e: any) => {
                        handleExpDropdown(item.id, 'notOperator', e.target.checked);
                    }}
                >
                    {'NOT'}
                </NexusCheckbox>
            )}
            {item.operator !== IS_NULL && caseSensitive && item.type === 'string' && format.indexOf('date') < 0 && (
                <NexusCheckbox
                    className='option'
                    disabled={disable || disableField}
                    checked={item?.ignore_case}
                    onClick={(e: any) => handleCaseSensivity(e.target.checked)}
                >
                    {'Ignore Case'}
                </NexusCheckbox>
            )}

            {item.operator !== IS_NULL && (
                <NexusToggle
                    data-testid='compare-toggle'
                    labelActive='column compare'
                    size='sm'
                    key={'compare-toggle' + compareFields.length}
                    className={lock || disableField || compareFields.length === 0 ? 'disable' : ''}
                    disabled={lock || disableField || compareFields.length === 0}
                    toggled={compare}
                    onClick={(e) => {
                        e.preventDefault();
                        if (!compare) {
                            handleExpDropdown(id, 'compare_with_field', true);
                            handleExpDropdown(id, 'compare_field_name', getColmunCampareData()[0].label);
                        } else {
                            handleExpDropdown(id, 'compare_with_field', false);
                        }
                        setCompare(!compare);
                    }}
                />
            )}

            <NexusIcon
                key={'delete-icon' + id}
                data-testid='delete-icon'
                className={lock || disableField ? 'cursor disable' : 'cursor'}
                size='xs'
                src={DeleteIcon}
                onClick={() => onDelete(item)}
            />
        </div>
    );
};

export default RuleExpression;
