import React from 'react';
import CustomTable from '../../../components/nds/customTable/customTable';
import './testSessionDetails.scss';
interface NestedTableBodyProps {
    data?: any;
    columnsDef?: {
        id: string;
        field: string;
        label: any;
        isSortable?: boolean;
        headerClass?: string;
        cellClass?: string;
        minWidth?: number;
        sortAscending?: any;
        enableCheckBox?: boolean;
        enableExpand?: boolean;
    }[];
    expand?: boolean;
    noDataMessage?: string;
}
const NestedTableBody: React.FC<any> = ({ data, columnsDef, noDataMessage }: NestedTableBodyProps) => {
    return (
        <td colSpan={6} data-testid='nested-table' className='nexus-ml-5 animate-table'>
            {columnsDef && data.length > 0 && (
                <CustomTable
                    attrId='nested-table'
                    columnsDef={columnsDef}
                    data={data}
                    noDataMessage={noDataMessage}
                    className='session-nested-table'
                />
            )}
        </td>
    );
};

export default NestedTableBody;
