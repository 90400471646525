/* eslint-disable sort-keys */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import { AuthenticationDetails, CognitoUserPool, CognitoUser, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { ICognitoAuthProps, ICognitoSilentAuthProps } from '../authProviderTypes';
import { REACT_APP_CLIENT_ID as clientId, REACT_APP_AUTH_POOL_ID as poolId } from '../../config/settings';

/**
 * Authenticate by hitting an authorize endpoint. Will redirect to the url and return the request back
 * to the calling/redirect uri.
 */
const storeSession = (session: any) => {
    // Convert the session object to JSON and store it in local storage
    sessionStorage.setItem('userSession', JSON.stringify(session));
};

const retrieveSession = () => {
    // Retrieve the session JSON from local storage and parse it
    const session = sessionStorage.getItem('userSession');
    return session ? JSON.parse(session) : null;
};
export const cognitoAuthenticate = (data: ICognitoAuthProps) => {
    const { email, password, callBack } = data;
    const poolData = { UserPoolId: poolId || '', ClientId: clientId || '' };
    const userPool = new CognitoUserPool(poolData);
    const authenticationData = {
        Username: email,
        Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
        Username: email,
        Pool: userPool,
        Storage: window.sessionStorage,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result: any) {
            const accessToken: any = result.getAccessToken();
            cognitoUser.getSession((error: any, session: any) => {
                if (!error) storeSession(session);
            });
            callBack &&
                callBack({
                    accessToken: accessToken.jwtToken,
                    expiresOn: accessToken.payload.exp,
                    idToken: result.idToken.jwtToken,
                });
            /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer */
        },
        newPasswordRequired: function (userAttributes) {
            // User was signed up by an admin and must provide new
            // password and required attributes, if any, to complete
            // authentication.

            // the api doesn't accept this field back
            delete userAttributes.email_verified;
            delete userAttributes.email;
        },
        onFailure: function (err) {
            alert(err);
        },
    });
};

export const authenticateSilently = (data: ICognitoSilentAuthProps) => {
    const { callBack, email } = data;
    const poolData = { UserPoolId: poolId || '', ClientId: clientId || '' };
    const userPool = new CognitoUserPool(poolData);
    const session = retrieveSession();

    if (!session) {
        console.error('User not logged in. Please login first.');
        return;
    }
    const refreshToken = new CognitoRefreshToken({ RefreshToken: session.refreshToken.token });

    const userData = {
        Username: email,
        Pool: userPool,
        Storage: window.sessionStorage,
    };
    const cognitoUser = new CognitoUser(userData);

    if (!cognitoUser) {
        console.error('Cannot find user in user pool. Please login again.');
        return;
    }
    cognitoUser.refreshSession(refreshToken, (refreshError: any, refreshResult: any) => {
        if (refreshError) {
            console.error('Error refreshing session:', refreshError);
            return;
        }

        const accessToken: any = refreshResult.getAccessToken();
        callBack && callBack({ accessToken: accessToken.jwtToken, expiresOn: Number(accessToken.payload.exp) });
        // Perform any desired actions with the obtained access token
    });
};
export const cognitoLogout = async ({ callBack, email }: ICognitoSilentAuthProps) => {
    const poolData = { UserPoolId: poolId || '', ClientId: clientId || '' };
    const userPool = new CognitoUserPool(poolData);
    const userData = {
        Username: email,
        Pool: userPool,
        Storage: window.sessionStorage,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.signOut();
    callBack && callBack();
    return;
};
