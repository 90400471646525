import { getRandomNumber } from './mathUtils';
export const constructExpJson = (groupJSON: any, parentId: any, parsedData: any, columnProperties: any) => {
    groupJSON.id = getRandomNumber();
    groupJSON.parentId = parentId || groupJSON.id;
    groupJSON.combinator = groupJSON.combinator && groupJSON.combinator.toUpperCase();

    for (let rule of groupJSON.rules) {
        rule.id = getRandomNumber();
        if (rule?.field && rule?.value) {
            let placeholderKey = rule?.value;
            rule.type = columnProperties[rule.field].type === 'integer' ? 'number' : columnProperties[rule.field].type;
            if (parsedData[placeholderKey]) {
                rule.user_value = parsedData[placeholderKey];
            }
            rule.parentId = groupJSON.id;
        }
        if (rule?.rules) {
            rule.category = 'group';
            rule.combinator = rule.combinator && rule.combinator.toUpperCase();

            constructExpJson(rule, '', parsedData, columnProperties);
        }
    }
    return groupJSON;
};

export const generateExpression = (scriptConfiguration: any) => {
    const { execution_parameters, expression_string } = scriptConfiguration || {};
    let expression = expression_string;
    if (execution_parameters && expression_string) {
        Object.keys(execution_parameters).forEach((key) => {
            const regex = new RegExp(key, 'g');
            expression = expression.replace(regex, execution_parameters[key]);
        });
    }
    return expression;
};
