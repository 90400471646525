import { buildCreateSlice, asyncThunkCreator, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';

export const createAppSlice = buildCreateSlice({
    creators: { asyncThunk: asyncThunkCreator },
});
interface GenericState<T> {
    data?: T;
    status: 'loading' | 'finished' | 'error';
}
export const createGenericSlice = <T, Reducers extends SliceCaseReducers<GenericState<T>>>({
    name = '',
    initialState,
    reducers,
}: {
    name: string;
    initialState: GenericState<T>;
    reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>;
}) => {
    return createAppSlice({
        initialState,
        name,
        reducers: {
            ...reducers,
        },
    });
};
