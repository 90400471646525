import React, { FC } from 'react';
import BreadcrumbComponent from 'app/components/nds/breadcrumb/breadcrumb';
import StepperComponent from '../stepper/Stepper';

interface StepperLayoutProps {
    children: JSX.Element;
    steps: Array<any>;
    activeStep: string;
    breadcrumbData: Array<any>;
    header: string;
}

const StepperLayout: FC<StepperLayoutProps> = ({ children, steps, activeStep, breadcrumbData, header }) => {
    return (
        <div className='nexus-container' data-testid='session'>
            <p className='nexus-h5 margin-0'>{header}</p>
            <BreadcrumbComponent separator='>' data={breadcrumbData} />
            <StepperComponent steppertype='row' currentStep={activeStep} steps={steps} />
            <div>{children}</div>
        </div>
    );
};

export default StepperLayout;
