import React, { useEffect, useState } from 'react';
import { NexusCard } from '@nexus/react';
import AccordionComponent from 'app/components/nds/accordion/accordion';
import { constructExpressionBuilderObject } from './expressionBuilderHelper';
import ExpressionBuilderComponent from 'app/components/expressionBuilder/ExpressionBuilderComponent';
import { useAppDispatch } from 'store';
import ButtonComponent from 'app/components/nds/button/button';
import { useTranslation } from 'react-i18next';
import { EXPRESSION, MVEL, VALID, INVALID } from 'constants/constants';
import CheckCircle from '@nexus/core/dist/assets/icons/action/ic_check_circle_24px.svg';
import WarningIcon from '@nexus/core/dist/assets/icons/alert/ic_warning_24px.svg';
import { getExpPreview, updateTestSessionTestCaseById } from '../../redux/testSessionReducer';
import { currentTestSession } from '../../redux/testSessionSelectors';
import { useSelector } from 'react-redux';

function AccordionExpressionBuilder({ testCase }: { testCase: any }) {
    const dispatch = useAppDispatch();
    const [t] = useTranslation('lang');
    const { scriptConfiguration, testCasePreview } = testCase;
    const [icon, setIcon] = useState<any>({ icon: CheckCircle, iconClass: 'icon-complete' });
    const [showIcon, setShowIcon] = useState<boolean>(false);
    const [showAccordion, setShowAccordion] = useState<boolean>(testCase?.showAccordion || false);
    const [initialPreviewCall, setInitialPreviewCall] = useState<boolean>(true); // to avoid multiple preview calls on initial load
    const allRules = constructExpressionBuilderObject(
        scriptConfiguration?.expression_object,
        scriptConfiguration?.execution_parameters,
    );
    const testSession = useSelector(currentTestSession);
    const { canonicalModelData } = testSession;
    const cannonicalModelColumns = canonicalModelData?.schema?.properties;

    useEffect(() => {
        if (
            (testCasePreview === '' || testCasePreview === undefined) &&
            scriptConfiguration?.type === EXPRESSION &&
            initialPreviewCall
        ) {
            setInitialPreviewCall(false);
            getPreviewExpression(testCase);
        }
    }, [testCase]);
    const columns = cannonicalModelColumns
        ? Object.keys(cannonicalModelColumns).map((col) => {
              return {
                  format: cannonicalModelColumns[col].format || '',
                  label: col,
                  type: cannonicalModelColumns[col]?.type === 'integer' ? 'number' : cannonicalModelColumns[col].type,
                  value: col,
              };
          })
        : [];
    const expressionBuilderOnchange = (datafromChild: any, testCase: any) => {
        dispatch(
            updateTestSessionTestCaseById({
                data: {
                    previewStatus: '',
                    testCaseParameters: {
                        ...testCase?.testCaseParameters,
                        execution_parameters: datafromChild?.execution_parameters,
                    },
                    testCasePreview: '',
                },
                testCaseId: testCase?.testCaseId,
            }),
        );
    };
    const getPreviewExpression = async (testCase: any) => {
        const { scriptConfiguration, testCaseParameters } = testCase;
        const payload = {
            canonicalModelId: testCase.canonicalModelId,
            scriptConfiguration: {
                execution_parameters:
                    testCaseParameters?.execution_parameters || scriptConfiguration?.execution_parameters,
                expression_object: scriptConfiguration?.expression_object,
                type: EXPRESSION,
                user_selected_columns: scriptConfiguration?.user_selected_columns,
            },
        };
        return dispatch(getExpPreview({ testTemplatePreviewRequest: payload, testCaseId: testCase.testCaseId }));
    };
    useEffect(() => {
        if (testCase?.scriptConfiguration?.type === MVEL) {
            setIcon({ icon: CheckCircle, iconClass: 'icon-complete' });
            setShowIcon(true);
        } else {
            if (!testCase?.testCasePreview && !testCase?.previewStatus) {
                setShowIcon(false);
            } else {
                setShowIcon(true);
                if (testCase?.previewStatus === VALID) {
                    setIcon({ icon: CheckCircle, iconClass: 'icon-complete' });
                }
                if (testCase?.previewStatus === INVALID) {
                    setIcon({ icon: WarningIcon, iconClass: 'icon-warning' });
                }
            }
        }
    }, [testCase?.testCasePreview, testCase?.previewStatus]);

    return (
        <AccordionComponent
            size='xl'
            accordionContent={{
                isOpen: showAccordion,
                title: testCase?.name,
            }}
            handleToggle={() => setShowAccordion(!showAccordion)}
            icon={icon}
            dataSource={testCase?.dataSourceName}
            showIcon={showIcon}
            iconStyle='align-accordion-icon'
        >
            {
                <NexusCard className='nexus-card-border-0 nexus-p-3 nexus-mt-3'>
                    <div className='nexus-subtitle-sm nexus-mb-1'>{t('expressionBuilder.header1')}</div>
                    <div className='nexus-caption-copy nexus-mb-1'>{t('expressionBuilder.subHeader1')}</div>
                    <div className='nexus-data'>{t('expressionBuilder.subHeader2')}</div>
                    <div className='card-expression-editor nexus-card-border-0'>
                        {scriptConfiguration?.type === MVEL ? (
                            <div className='nexus-caption-copy nexus-mt-2'>
                                {t('expressionBuilder.warningManualEditor')}{' '}
                            </div>
                        ) : (
                            <ExpressionBuilderComponent
                                key={JSON.stringify(allRules)}
                                enableDragDrop={false}
                                jsonExpression={allRules}
                                disable={false}
                                fields={columns}
                                enableLockButton={false}
                                enableNot={true}
                                onChange={(data: any) => expressionBuilderOnchange(data, testCase)}
                                validate={false}
                                cloneButon={false}
                                disableTextField={true}
                                caseSensitive
                            />
                        )}

                        {scriptConfiguration?.type === EXPRESSION && (
                            <div className='nexus-flex-row-reverse'>
                                <ButtonComponent
                                    type={['nexus-btn nexus-btn-primary nexus-mx-3']}
                                    label={t('buttons.preview')}
                                    disabled={false}
                                    click={() => getPreviewExpression(testCase)}
                                />
                            </div>
                        )}
                        <h5 className=''>{t('expressionBuilder.header2')}</h5>
                        <NexusCard className='card-expression-editor nexus-caption-copy'>
                            {scriptConfiguration?.type === EXPRESSION
                                ? testCasePreview
                                : scriptConfiguration?.expression_string}
                        </NexusCard>
                    </div>
                </NexusCard>
            }
        </AccordionComponent>
    );
}

export default AccordionExpressionBuilder;
