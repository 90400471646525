export const constructExpressionBuilderObject = (expressionBuilderObject: any, parameters: any) => {
    if (!parameters || !expressionBuilderObject) {
        return expressionBuilderObject || {};
    }
    const finalObject = JSON.parse(JSON.stringify(expressionBuilderObject));
    const formatObject = (object: any, parameters: any) => {
        if (object.category === 'group') {
            object?.rules?.forEach((rule: any) => {
                formatObject(rule, parameters);
            });
        } else if (object?.category === 'rule') {
            object.user_value = parameters[object?.value];
        }
    };
    formatObject(finalObject, parameters);
    return finalObject;
};
