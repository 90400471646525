import React, { useEffect } from 'react';

import CardComponent from 'app/components/nds/card/card';
import { statusDetails } from 'constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getTestSessionCountInfo } from '../redux/testSessionReducer';
import { testSessionCountInfo } from '../redux/testSessionSelectors';
function CountInfo() {
    const [t] = useTranslation('lang');
    const dispatch = useDispatch();
    const count = useSelector(testSessionCountInfo);
    useEffect(() => {
        dispatch(getTestSessionCountInfo());
    }, []);
    return (
        <div className='count-cards' data-testid='test-session-count-cards'>
            {count &&
                statusDetails?.map((item: any) => {
                    return (
                        count[item?.key] > -1 && (
                            <div
                                key={item?.key}
                                className='count-card nexus-col-xs-1 nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2 nexus-col-xl-2 nexus-mt-1'
                            >
                                <CardComponent
                                    imageSrc={item?.icon}
                                    status={t(item?.key)}
                                    count={count[item?.key]}
                                    bgcolor={item?.bg}
                                    height='80px'
                                    iconBg={item?.key}
                                />
                            </div>
                        )
                    );
                })}
        </div>
    );
}

export default CountInfo;
