import './header.scss';

import { Link, useLocation } from 'react-router-dom';
import { NexusHeader, NexusHeaderLogo, NexusHeaderMenu, NexusIcon, NexusMenuItem } from '@nexus/react';

import DropdownComponent from './dropdown/dropdown';
import Logo from '@nexus/core/dist/assets/images/nexus-logo.svg';
import React, { useEffect, useState } from 'react';
import { retrieveUserName, setTestFlowType } from '../../../../auth';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TEST_FLOW_TYPE, userProfile } from 'constants/constants';
import { useAppDispatch, useAppSelector } from 'store';
import AvatarComponent from './avatar/avatar';
import { getUniqueKey } from 'utils/generateButtonIndex';
import { getAvatarLabel } from 'utils/actionUtils';
import Bell from '../../../assets/images/bell-icon.svg';
import Notifications from 'services/notifications/index';
import BadgeComponent from 'app/components/nds/badge/badge';
import { getNotificationsCount } from '../../../../services/notifications/redux/notificationsReducer';
interface MenuItem {
    icon?: string;
    link: string;
    title: string;
}
interface Props {
    home: string;
    menuItems: MenuItem[];
}
const HeaderComponent: React.FC<Props> = ({ home, menuItems }) => {
    const [t] = useTranslation('lang');
    const path = useLocation();
    const fullName = useSelector(retrieveUserName);
    const dispatch = useAppDispatch();
    const [toggleDropDown, setToggleDropDown] = useState<boolean>(false);
    const userRoles = useAppSelector((state) => state.auth?.roles);
    const [showNotifications, setShowNotifications] = useState<boolean>(false);
    const unreadCount = useAppSelector((state) => state.notificationsData?.unreadCount);

    useEffect(() => {
        dispatch(getNotificationsCount());
    }, []);

    const closeMenu = () => {
        const closeBtn = document.getElementsByTagName('nexus-menu')[0]?.getElementsByTagName('nexus-icon');
        return closeBtn && closeBtn.length > 0 ? closeBtn[0].click() : null;
    };
    const dropDownMenu = () => {
        setToggleDropDown(!toggleDropDown);
    };
    const handleClickOutside = (event: MouseEvent) => {
        const dropdown = document.querySelector('.user-profile-flyout');
        if (dropdown && !dropdown.contains(event.target as Node)) {
            setToggleDropDown(false);
        }
    };
    const handleAnalyst = () => {
        dispatch(setTestFlowType(TEST_FLOW_TYPE.TestSession));
        closeMenu();
    };
    const handleDesigner = () => {
        dispatch(setTestFlowType(TEST_FLOW_TYPE.TestTemplate));
        closeMenu();
    };

    const roleBasedOptions = userRoles.includes(userProfile.TestDesigner)
        ? [
              {
                  clickButton: handleAnalyst,
                  extraStyle: 'link-color',
                  label: t('menuOptions.testSession'),
                  link: '/test-session',
              },

              {
                  clickButton: handleDesigner,
                  extraStyle: 'link-color',
                  label: t('menuOptions.testTemplate'),
                  link: '/test-template',
              },
          ]
        : [];
    const dropdownOptions = [
        ...roleBasedOptions,
        {
            clickButton: closeMenu,
            extraStyle: 'link-color',
            label: t('loginProfile.profile'),
            link: '/profile',
        },
        {
            extraStyle: 'link-color',
            label: t('loginProfile.logout'),
            link: '/logout',
        },
    ];

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
    }, []);

    return (
        <NexusHeader class='nexus-header-sticky nexus-header hydrated header-width' data-testid='nexus-header'>
            <NexusHeaderLogo className='display-inline padding nexus-py-2'>
                <Link to={home}>
                    <NexusIcon src={Logo} />
                    <span className='nexus-visually-hidden'></span>
                </Link>
                <div className='nexus-padding-2 separator'>Automated Testing</div>
            </NexusHeaderLogo>
            <NexusHeaderMenu data-testid='nexus-header-menu'>
                {menuItems.map((menuItem, idx) =>
                    menuItem.icon ? (
                        <NexusMenuItem key={getUniqueKey(idx)} data-testid={menuItem.title} onClick={closeMenu}>
                            <Link to={menuItem.link}>
                                <NexusIcon src={menuItem.icon}>{menuItem.title}</NexusIcon>
                            </Link>
                        </NexusMenuItem>
                    ) : (
                        <NexusMenuItem
                            className={`${menuItem.link}` === path.pathname ? 'menu-item-highlighter' : ''}
                            key={getUniqueKey(idx)}
                            data-testid={menuItem.title}
                            onClick={closeMenu}
                        >
                            <Link to={menuItem.link}>{menuItem.title}</Link>
                        </NexusMenuItem>
                    ),
                )}
                <NexusMenuItem
                    className='notification-wrapper'
                    onClick={() => setShowNotifications(!showNotifications)}
                >
                    <NexusIcon data-testid='bell-icon' src={Bell} className='nexus-mt-2 bell-icon' />
                    <BadgeComponent color='unread-count' variant='success' label={unreadCount}></BadgeComponent>
                </NexusMenuItem>
                <Notifications
                    title='Notifications'
                    open={showNotifications}
                    onClose={() => setShowNotifications(false)}
                />
                <NexusMenuItem>
                    <div className='nexus-mt-1 user-profile-flyout' onClick={() => dropDownMenu()}>
                        <DropdownComponent
                            toggleDropDown={toggleDropDown}
                            dropdownOptions={dropdownOptions}
                            triggererContent={<AvatarComponent name={getAvatarLabel(fullName)} />}
                            placement='left'
                            dropdownType='basic'
                            height={280}
                            width={245}
                        />
                    </div>
                </NexusMenuItem>
            </NexusHeaderMenu>
        </NexusHeader>
    );
};

export default HeaderComponent;
