import './errorBoundary.scss';

import React, { Component, ErrorInfo, ReactNode } from 'react';

import ModalComponent from '../nds/modal/modal';
import ReactPortal from '../portals/portals';
import { ErrorBoundaries } from 'constants/constants';

interface Props {
    children?: ReactNode;
}

interface ErrorState {
    hasError?: boolean;
    isActive?: boolean;
}

class ErrorBoundary extends Component<Props, ErrorState> {
    constructor(props: Props | Readonly<Props>) {
        super(props);
        this.state = {
            isActive: true,
        };
    }

    public state: ErrorState = {
        hasError: false,
    };

    public static getDerivedStateFromError(): ErrorState {
        return { hasError: true };
    }

    /* eslint-disable no-console */
    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    closeModal = () => {
        this.setState({
            isActive: false,
        });
    };

    public render() {
        if (this.state.hasError) {
            return (
                <ReactPortal wrapperId='portal-root'>
                    <ModalComponent
                        show={this.state.isActive!}
                        size={'lg'}
                        header=''
                        closeModal={this.closeModal}
                        type={ErrorBoundaries}
                        footerContent={
                            <button onClick={this.closeModal} data-testid='btn-confirm' className='nexus-btn-primary'>
                                Apply
                            </button>
                        }
                    >
                        <h3 className='error-boundary-text' data-testid='error-boundary-text'>
                            Something went wrong
                        </h3>
                        <p>Please try again later</p>
                    </ModalComponent>
                </ReactPortal>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
