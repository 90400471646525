import { NexusSelectCustomEvent, ValueType } from '@nexus/core';
import './multiSelect.scss';
import { NexusSelect } from '@nexus/react';
import React from 'react';

interface InputProps {
    attrId?: string;
    delimiter?: string;
    disabled?: boolean;
    multiple?: boolean;
    required?: boolean;
    value?: string;
    type?: 'custom' | 'native';
    selectedValue?: string;
    showSelectAll?: boolean;

    options:
        | {
              label?: string;
              value: string;
              disabled?: boolean;
              isSelected?: boolean;
              id?: string;
          }[]
        | any;
    onChange?: (nextValue?: any) => void;
    selectAllOptions?: boolean;
    extraClass?: string;
    name?: string;
    key?: string;
    disabledOption?: string;
}

const config = {
    selectAll: {
        checked: false,
        disabled: false,
        label: 'Select All',
    },
};

const MultiSelect: React.FC<InputProps> = (props: InputProps) => {
    return (
        <div key={props.key}>
            <NexusSelect
                disabled={props.disabled}
                data-testid='custom-multi-select'
                attr-id='custom-multi-select-1'
                type='custom'
                config={config}
                options={props.options.map((option: any) => option.label)}
                onTriggerSelection={(eventData: NexusSelectCustomEvent<ValueType[]>) => {
                    const selectedDataIds = props.options
                        .filter((element: any) => eventData.detail.includes(element.label))
                        .map((element: any) => element.value);
                    props.onChange && props.onChange(selectedDataIds);
                }}
                multiple
            />
        </div>
    );
};

export default MultiSelect;
