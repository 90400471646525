import './testSessionDetails.scss';

import { ASC, DESC, completeBadge, inProgress } from 'constants/constants';
import { useAppDispatch } from 'store';
import React, { useEffect, useState } from 'react';

import BadgeComponent from 'app/components/nds/badge/badge';
import CustomTable from 'app/components/nds/customTable/customTable';
import DownLoadIcon from '@nexus/core/dist/assets/icons/action/ic_download_24px.svg';
import { NexusIcon } from '@nexus/react';
import ViewReportIcon from '@nexus/core/dist/assets/icons/action/ic_visibility_24px.svg';
import { createFileFromResponse } from 'services/services';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import NestedTableBody from 'app/views/testSession/testSessionDetails/nestedTableBody';
import { getFile, fetchTestSessionRuns } from '../redux/testSessionReducer';
import { currentTestSession } from '../redux/testSessionSelectors';
import { useSelector } from 'react-redux';

const TestSessionDetailsTable = () => {
    const [t] = useTranslation('lang');
    const dispatch: any = useAppDispatch();
    const { id } = useParams();
    const testSession = useSelector(currentTestSession);
    const { testSessionId, testSessionTestCases, testExecution, testExecutionData } = testSession;
    //pagination states
    const totalElements: number = testExecution?.totalElements || 0;
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    //sort states
    const [sortCol, setSortCol] = useState<string>('timestamp');
    const [sort, setSort] = useState<string>(DESC);

    const testExecutionTableHeader = [
        {
            field: 'expand',
            id: 'expand',
            isSortable: false,
            label: '',
            minWidth: 1,
        },
        {
            field: 'id',
            id: 'id',
            isSortable: false,
            label: t('testSessionDetails.testInstanceID'),
            minWidth: 150,
        },
        {
            field: 'executionTimestamp',
            id: 'executionTimestamp',
            isSortable: false,
            label: t('testSessionDetails.dateTimeRun'),
            minWidth: 150,
        },
        {
            field: 'runType',
            id: 'runType',
            isSortable: true,
            label: t('testSessionDetails.testRunType'),
            minWidth: 110,
        },
        {
            field: 'status',
            id: 'status',
            isSortable: false,
            label: t('testSessionDetails.testExecutionStatus'),
            minWidth: 100,
        },
        {
            field: 'outputFile',
            id: 'outputFile',
            isSortable: false,
            label: t('testSessionDetails.outputFile'),
            minWidth: 100,
        },
        {
            field: 'dashboardAndReport',
            id: 'dashboardAndReport',
            isSortable: false,
            label: '',
            minWidth: 110,
        },
    ];
    const childTableHeader = [
        {
            field: 'testCaseId',
            id: 'testCaseId',
            isSortable: false,
            label: t('testSessionDetails.testTemplateId'),
            minWidth: 110,
        },
        {
            field: 'testCaseName',
            id: 'testCaseName',
            isSortable: false,
            label: t('testSessionDetails.testTemplateName'),
            minWidth: 260,
        },
        {
            field: 'outputFile',
            id: 'outputFile',
            isSortable: false,
            label: t('testSessionDetails.outputFile'),
            minWidth: 10,
        },
        {
            field: 'dashboardAndReport',
            id: 'dashboardAndReport',
            isSortable: false,
            label: '',
            minWidth: 80,
        },
    ];
    const getTestCaseName = (id: number) => {
        const testCase = testSessionTestCases.find((testCase: any) => testCase.id === id);
        return testCase?.name || '';
    };
    const rowData: Array<any> =
        testExecutionData?.content !== undefined
            ? testExecutionData?.content.map((data: any) => {
                  let badgeColor: string = 'fail-badge';
                  if (data?.status) {
                      switch (data.status) {
                          case completeBadge:
                              badgeColor = 'complete-badge';
                              break;
                          case inProgress:
                              badgeColor = 'in-progress-badge';
                              break;
                      }
                  }
                  return {
                      dashboardAndReport: data.status === completeBadge &&
                          data.aggregatedReportUrl &&
                          data.aggregatedReportUrl !== undefined && (
                              <div className='view-icon'>
                                  <NexusIcon
                                      src={ViewReportIcon}
                                      onClick={() => handleViewReport(data.aggregatedReportUrl)}
                                      data-testid='view-report-icon'
                                  ></NexusIcon>
                              </div>
                          ),
                      executionTimestamp:
                          data.executionTimestamp !== undefined &&
                          data?.executionTimestamp?.substring(0, 10) +
                              ' ' +
                              data?.executionTimestamp?.substring(11, 19),
                      expandedRowData: {
                          columnsDef: childTableHeader,
                          data: data?.executions?.map((testExecution: any) => {
                              return {
                                  dashboardAndReport: testExecution.status === completeBadge &&
                                      testExecution.reportUrl &&
                                      testExecution.reportUrl !== undefined && (
                                          <div className='view-icon'>
                                              <NexusIcon
                                                  src={ViewReportIcon}
                                                  onClick={() => handleViewReport(testExecution.reportUrl)}
                                                  data-testid='view-report-icon'
                                              ></NexusIcon>
                                          </div>
                                      ),
                                  outputFile:
                                      testExecution.status === completeBadge &&
                                      testExecution.resultFile &&
                                      testExecution.resultFile !== undefined ? (
                                          <div
                                              className='download-pointer'
                                              data-testid='download-icon'
                                              onClick={() => handleDownloadFile(testExecution.resultFile)}
                                          >
                                              <NexusIcon src={DownLoadIcon}></NexusIcon>
                                              <span className='download-text'>{t('testSessionDetails.download')}</span>
                                          </div>
                                      ) : (
                                          testExecution.status === completeBadge &&
                                          testExecution.resultFile !== undefined && <div className='download-loader' />
                                      ),
                                  testCaseId: testExecution?.testCaseId,
                                  testCaseName: getTestCaseName(testExecution?.testCaseId),
                              };
                          }),
                      },
                      id: data?.id,
                      outputFile:
                          data.status === completeBadge &&
                          data.aggregatedResultFile &&
                          data.aggregatedResultFile !== undefined ? (
                              <div
                                  className='download-pointer'
                                  data-testid='download-icon'
                                  onClick={() => handleDownloadFile(data.aggregatedResultFile)}
                              >
                                  <NexusIcon src={DownLoadIcon}></NexusIcon>
                                  <span className='download-text'>{t('testSessionDetails.download')}</span>
                              </div>
                          ) : (
                              data.status === completeBadge &&
                              data.aggregatedResultFile !== undefined && <div className='download-loader' />
                          ),
                      runType: data?.runType,
                      showExpandIcon: true,
                      status: data.status && <BadgeComponent color={badgeColor} label={data.status}></BadgeComponent>,
                  };
              })
            : [];
    useEffect(() => {
        const payload = {
            page: currentPage - 1 < 0 ? 0 : currentPage - 1,
            size: pageSize,
            sort: sort ? [sortCol + ',' + sort] : undefined,
            testSessionId: testSessionId || id,
        };
        dispatch(fetchTestSessionRuns(payload));
    }, [currentPage, sort, sortCol, pageSize]);

    const handleViewReport = (reportLink: string) => {
        sessionStorage.setItem('powerBiReportLink', reportLink);
        window.open('/session/report');
        sessionStorage.removeItem('powerBiReportLink');
    };

    const handleDownloadFile = (downloadLink: string) => {
        if (downloadLink.length) {
            dispatch(getFile(downloadLink)).then((response: any) => {
                if (response && response.payload) {
                    createFileFromResponse(response);
                }
            });
        }
    };

    const paginationHandler = (currentPage: number, currentPageSize: number) => {
        setPageSize(currentPageSize);
        setCurrentPage(currentPage);
    };
    const handleSort = (label: string, field: string) => {
        setSortCol(field);
        if (sortCol === field) {
            setSort(sort === DESC ? ASC : DESC);
        } else {
            setSort(ASC);
        }
    };
    return (
        <div data-testid='test-session-details-table'>
            <CustomTable
                enableRowExpand={true}
                expandedRowComponent={(data: any) => <NestedTableBody {...data} />}
                attrId='customTable'
                pagination={true}
                columnsDef={testExecutionTableHeader}
                pageSizeCustom={pageSize}
                totalItems={totalElements}
                data={rowData}
                paginationFunc={paginationHandler}
                handleSort={handleSort}
                currentPageNo={currentPage}
                sortIcon={sort}
                sortCol={testExecutionTableHeader.map((e: any) => e?.field).indexOf(sortCol)}
            />
        </div>
    );
};

export default React.memo(TestSessionDetailsTable);
