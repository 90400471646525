import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './loginPage.scss';
import Login from '../../components/login/login';
import { decodeIdToken, formatTokenData, getDataFromUri } from '../../../auth/authHelper';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRoles, selectAuthToken, setAuthToken, setLoading } from '../../../auth';

const LoginPage = () => {
    const accessToken = useSelector(selectAuthToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getRoles = async () => {
        dispatch(getUserRoles());
    };

    useEffect(() => {
        getDataFromUri(dispatch, getRoles);
    }, []);

    useEffect(() => {
        if (accessToken) {
            setTimeout(() => {
                navigate('/test-session');
            }, 2000);
        }
    }, [accessToken]);

    const handleLogin = async (data: any) => {
        if (data.accessToken) {
            const tokenData = formatTokenData(data.accessToken, Number(data.expiresOn));
            await dispatch(setAuthToken(tokenData));
            decodeIdToken(data.idToken, dispatch);
            await getRoles();
            dispatch(setLoading(false));
            navigate('/test-session');
        }
    };
    return <Login onLogin={handleLogin} />;
};
export default LoginPage;
