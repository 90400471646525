import { IStep } from './commonExportedInterfaces';

export enum stepperRoutes {
    TestHierarchy = 'testHierarchy',
    TemplateSelection = 'templateSelection',
    DataSelection = 'dataSelection',
    ExpressionBuilder = 'expressionBuilder',
    Confirmation = 'confirmation',
    TestParameterDesigner = 'testParameter',
    DataSelectionDesigner = 'dataSelectionDesigner',
    ExpressionBuilderDesigner = 'expressionBuilderDesigner',
    ConfirmationDesigner = 'confirmationDesigner',
    step_1 = 'step_1',
    step_2 = 'step_2',
    step_3 = 'step_3',
    step_4 = 'step_4',
    step_5 = 'step_5',
    step_6 = 'step_6',
}

export enum stepperLabels {
    TestHierarchy = 'Test Hierarchy',
    TemplateSelection = 'Template Selection',
    Mapping = 'Mapping',
    DataSelection = 'Data Selection',
    ExpressionBuilder = 'Expression Builder',
    Confirmation = 'Confirmation',
    TestParameterDesigner = 'Test Parameters',
    DataSelectionDesigner = 'Data Selection',
    ExpressionBuilderDesigner = 'Expression Builder',
    ConfirmationDesigner = 'Confirmation',
}

export const testSessionSteps: IStep[] = [
    { label: stepperLabels.TestHierarchy, name: stepperRoutes.step_1 },

    { label: stepperLabels.TemplateSelection, name: stepperRoutes.step_2 },

    { label: stepperLabels.Mapping, name: stepperRoutes.step_3 },

    { label: stepperLabels.DataSelection, name: stepperRoutes.step_4 },

    { label: stepperLabels.ExpressionBuilder, name: stepperRoutes.step_5 },

    { label: stepperLabels.Confirmation, name: stepperRoutes.step_6 },
];
export const testTemplateSteps: IStep[] = [
    { label: stepperLabels.TestParameterDesigner, name: stepperRoutes.step_1 },

    { label: stepperLabels.DataSelectionDesigner, name: stepperRoutes.step_2 },

    { label: stepperLabels.ExpressionBuilderDesigner, name: stepperRoutes.step_3 },

    { label: stepperLabels.ConfirmationDesigner, name: stepperRoutes.step_4 },
];
