import './testTemplateTable.scss';

import { ASC, DESC, Update, Status, DELETE_MODAL_TYPE, draft } from 'constants/constants';
import { NexusDropdown, NexusIcon, NexusOption, NexusCheckbox } from '@nexus/react';
import React, { useEffect, useState } from 'react';
import ButtonComponent from 'app/components/nds/button/button';
import CustomTable from 'app/components/nds/customTable/customTable';
import ModalComponent from 'app/components/nds/modal/modal';
import NavigationIcMoreVert24px from '@nexus/core/dist/assets/icons/action/ic_more_vert_24px.svg';
import TableColumnFilter from 'app/components/tableColumnFilter/TableColumnFilter';
import { formatDateSelected, getDropdownValues } from 'services/services';
import { getButtonIndex } from 'utils/generateButtonIndex';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { disableDeleteSelected, isMainCheckboxSelected } from 'utils/actionUtils';
import { TestCaseStatus } from 'app/api/atAPI';
import BadgeComponent from 'app/components/nds/badge/badge';
import {
    getTestTemplateTableData,
    getTestTemplate,
    deleteTestTemplate,
    deleteSelectedTemplate,
    updateCheckedItems,
    updateUserFlow,
    getCanonicalModels,
} from '../redux/testTemplateReducer';
import {
    testTemplateTableData as testTemplateData,
    checkedItems,
    canonicalModelsList,
} from '../redux/testTemplateSelectors';

function TestTemplateTable() {
    const [t] = useTranslation('lang');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //delete testTemplate modal states
    const [testTemplateTobeDeleted, setTestTemplateTobeDeleted] = useState<any>({});
    //filter states
    const [searchText, setSearchText] = useState<string>('');
    const [dateFrom, setDateFrom] = useState<string | null>();
    const [dateTo, setDateTo] = useState<string | null>();
    const [status, setStatus] = useState<TestCaseStatus>(Status.Published);
    const [selectedCanonicalModel, setSelectedCanonicalModel] = useState<any>();
    //pagination states
    const testTemplate = useSelector(testTemplateData);
    const { totalElements, content: testTemplateTableData } = testTemplate;
    const checkedTemplates = useSelector(checkedItems);
    const canonicalModelsData = useSelector(canonicalModelsList);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    //sort states
    const [sortCol, setSortCol] = useState<string>('createdAt');
    const [sort, setSort] = useState<string>(DESC);

    //delete modal states
    const [deleteModalData, setDeleteModalData] = useState<{
        header: string;
        open: boolean;
        type: DELETE_MODAL_TYPE;
        bodyData: any;
    }>({
        bodyData: '',
        header: '',
        open: false,
        type: DELETE_MODAL_TYPE.DELETE,
    });

    useEffect(() => {
        dispatch(getCanonicalModels());
    }, []);

    const canonicalModels =
        canonicalModelsData.length > 0
            ? canonicalModelsData?.map(function (item: any) {
                  return {
                      label: item.name,
                      value: item.id,
                  };
              })
            : [];

    const handleCanonicalModel = (data: any) => {
        if (data && data !== undefined) {
            const selectedCanonicalModel = canonicalModels?.find((obj: any) => obj?.value === Number(data));
            setSelectedCanonicalModel(selectedCanonicalModel);
            setCurrentPage(1);
        }
    };

    const tableData = testTemplateTableData
        ? testTemplateTableData?.map((data: any) => {
              return {
                  action: (
                      <NexusDropdown
                          placement={'right'}
                          dropdown-type={'basic'}
                          height={150}
                          width={180}
                          className={`custom-table-dropdown hand-icon`}
                          data-testid='nexus-dropdown'
                      >
                          <NexusIcon
                              slot={'trigerer'}
                              id={'iconId'}
                              src={NavigationIcMoreVert24px}
                              data-testid='view-dropdown-icon'
                          ></NexusIcon>
                          <NexusOption data-testid='edit-template-icon' onClick={() => handleEdit(data)}>
                              {t('home.editTemplate')}
                          </NexusOption>
                          <NexusOption data-testid='delete-template-icon' onClick={() => deleteSessionPopUp(data)}>
                              {t('home.deleteTemplate')}
                          </NexusOption>
                      </NexusDropdown>
                  ),
                  checkBoxBtn: (
                      <>
                          <div className={'nexus-row checkbox-position'}>
                              <NexusCheckbox
                                  data-testid={`rowCheckBox`}
                                  onClick={(event) => handleSingleCheckBox(event, data?.testCaseId)}
                                  checked={checkedTemplates?.includes(data?.testCaseId)}
                                  className='checkbox-styles'
                              ></NexusCheckbox>
                              {data?.col}
                          </div>
                      </>
                  ),
                  createdAt:
                      data.testCaseCreationDate && data.testCaseCreationDate !== undefined && data.testCaseCreationDate,
                  description: data?.testCaseDescription,
                  id: data?.testCaseId,
                  name: data?.testCaseName,
                  status: data?.testCaseIsDraft && <BadgeComponent color='draft-status' label={draft} />,
              };
          })
        : [];
    //useEffect to update table data
    useEffect(() => {
        if ((dateFrom && dateTo) || (!dateFrom && !dateTo) || searchText) getLatestTableData();
    }, [searchText, dateFrom, dateTo, currentPage, pageSize, sort, sortCol, status, selectedCanonicalModel]);
    const getLatestTableData = async () => {
        const payload = {
            canonicalModelIds: selectedCanonicalModel?.value,
            page: currentPage - 1 < 0 ? 0 : currentPage - 1,
            search: searchText || '',
            size: pageSize,
            sort: sort ? [sortCol + ',' + sort] : undefined,
            status: status,
            testCaseCreationDateFrom: formatDateSelected(dateFrom),
            testCaseCreationDateTo: formatDateSelected(dateTo),
        };
        await dispatch(getTestTemplateTableData(payload));
    };
    const testTemplateTableHeader = [
        {
            enableCheckBox: true,
            field: 'checkBoxBtn',
            id: 'checkBoxBtn',
            isSortable: false,
            label: '',
            minWidth: 1,
        },
        {
            field: 'name',
            id: 'name',
            isSortable: true,
            label: t('templateSelection.testTemplate'),
            minWidth: 10,
        },
        {
            field: 'status',
            id: 'status',
            isSortable: false,
            label: '',
            minWidth: 10,
        },
        {
            field: 'description',
            id: 'description',
            isSortable: true,
            label: t('templateSelection.testDescription'),
            minWidth: 350,
        },
        {
            field: 'createdAt',
            id: 'createdAt',
            isSortable: true,
            label: t('testDesigner.dateCreated'),
            minWidth: 100,
        },
        {
            field: 'action',
            id: 'action',
            isSortable: false,
            label: '',
            minWidth: 50,
        },
    ];
    const handleSingleCheckBox = (event: any, testCaseId: number) => {
        const checked = event.target.checked;
        if (checked === undefined) return;
        const selectedCol = tableData?.find((obj: any) => obj?.id === testCaseId);
        const isAlreadySelected = checkedTemplates?.find((id: any) => id === testCaseId);
        if (checked) {
            if (selectedCol && !isAlreadySelected) {
                dispatch(updateCheckedItems([...checkedTemplates, selectedCol.id]));
            }
        } else {
            if (selectedCol && isAlreadySelected) {
                const filteredArray = checkedTemplates?.filter((id: any) => id !== testCaseId).map((id: any) => id);
                dispatch(updateCheckedItems(filteredArray));
            }
        }
    };
    const handleAllCheckBox = (event: any) => {
        const checked = event.target.checked;
        if (checked === undefined) return;
        if (checked) {
            const allCases = tableData.map((obj: any) => obj?.id);
            dispatch(updateCheckedItems([...checkedTemplates, ...allCases]));
        } else {
            dispatch(updateCheckedItems([]));
        }
    };
    const handleDeleteSelected = async () => {
        setDeleteModalData({
            bodyData: <div className='nexus-body-sm'>{t('home.deleteTemplateText')}</div>,
            header: t('home.deleteTemplateHeader'),
            open: true,
            type: DELETE_MODAL_TYPE.BULK_DELETE,
        });
    };
    const handleEdit = async (data: any) => {
        await dispatch(updateUserFlow(Update));
        await dispatch(getTestTemplate(data?.testCaseId));
        navigate(`/test-template/template/${data?.testCaseId}`);
    };

    let searchTimeout: NodeJS.Timeout | null = null;

    const handleSearchText = (event: React.FormEvent<HTMLNexusInputElement>) => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        const target = event.target as HTMLNexusTextareaElement;
        const searchStr = target.value.trim();

        // debouncing added
        searchTimeout = setTimeout(() => {
            setSearchText(searchStr);
            dispatch(updateCheckedItems([]));
        }, 500);
    };

    const handleDateFromFilter = (date: string) => {
        setDateFrom(date);
    };

    const handleDateToFilter = (date: string) => {
        setDateTo(date);
        dispatch(updateCheckedItems([]));
    };

    const handleStatus = (status: TestCaseStatus) => {
        setStatus(status);
        setCurrentPage(0);
    };

    const statusDropdown = () => {
        const values = Object.values(Status).map((value: any) => ({ name: value, id: value }));
        return getDropdownValues(values);
    };

    const handleClearAll = () => {
        setDateTo(null);
        setStatus(Status.Published);
        setDateFrom(null);
        setSearchText('');
        setCurrentPage(0);
        dispatch(updateCheckedItems([]));
        setSelectedCanonicalModel(null);
    };
    const paginationHandler = (currentPage: number, currentPageSize: number) => {
        setCurrentPage(currentPage);
        setPageSize(currentPageSize);
    };
    const handleSort = (label: string, field: string) => {
        setSortCol(field);
        if (sortCol === field) {
            setSort(sort === DESC ? ASC : DESC);
        } else {
            setSort(ASC);
        }
    };

    const deleteSessionPopUp = (data: any) => {
        setTestTemplateTobeDeleted(data);
        setDeleteModalData({
            bodyData: (
                <div className='nexus-body-sm'>
                    {t('home.deleteTemplateName')}
                    {':'}
                    <span className='text-bold'>{' ' + data?.testCaseName + '?'}</span>
                </div>
            ),
            header: t('home.deleteTemplate'),
            open: true,
            type: DELETE_MODAL_TYPE.DELETE,
        });
    };
    const handleNoButton = () => {
        if (deleteModalData?.type === DELETE_MODAL_TYPE.DELETE) {
            setTestTemplateTobeDeleted({});
        }
        if (deleteModalData?.type === DELETE_MODAL_TYPE.BULK_DELETE) {
            dispatch(updateCheckedItems([]));
        }
        setDeleteModalData({ bodyData: '', header: '', open: false, type: DELETE_MODAL_TYPE.DELETE });
    };
    const handleYesButton = async () => {
        if (deleteModalData?.type === DELETE_MODAL_TYPE.DELETE) {
            await dispatch(deleteTestTemplate(testTemplateTobeDeleted?.testCaseId));
            setTestTemplateTobeDeleted({});
        }
        if (deleteModalData?.type === DELETE_MODAL_TYPE.BULK_DELETE) {
            await dispatch(deleteSelectedTemplate(checkedTemplates));
            dispatch(updateCheckedItems([]));
        }
        setDeleteModalData({ bodyData: '', header: '', open: false, type: DELETE_MODAL_TYPE.DELETE });
        setCurrentPage(1);
        await getLatestTableData();
    };
    const eqButtonList = [
        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn nexus-rhythm-top-5'],
            clickButton: handleNoButton,
            label: t('buttons.No'),
        },
        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn-primary nexus-rhythm-top-5 nexus-mx-3'],
            clickButton: handleYesButton,
            label: t('buttons.Yes'),
        },
    ];
    //Delete Modal Footer Data
    const deleteModalFooterData = (
        <div className={`nexus-mt-2 align-delete-modal-btn`}>
            {eqButtonList?.map((data: any, _index: any) => {
                return (
                    <ButtonComponent
                        key={getButtonIndex(_index)}
                        click={data?.clickButton}
                        disabled={data?.buttonDisabled}
                        label={data?.label}
                        type={data?.buttonStyles}
                    />
                );
            })}
        </div>
    );
    return (
        <div data-testid='test-template-table'>
            <TableColumnFilter
                showDeleteButton={tableData?.length > 0}
                handleSearchText={handleSearchText}
                handleDateFromFilter={handleDateFromFilter}
                handleDateToFilter={handleDateToFilter}
                handleClearAll={handleClearAll}
                testFrom={dateFrom}
                testTo={dateTo}
                searchTextValue={searchText}
                handleDelete={handleDeleteSelected}
                disableDelete={disableDeleteSelected(checkedTemplates)}
                status={status}
                handleStatus={handleStatus}
                statusOptions={statusDropdown()}
                canonicalModels={canonicalModels}
                selectedCanonicalModel={selectedCanonicalModel}
                handleCanonicalModel={handleCanonicalModel}
            />
            <ModalComponent
                show={deleteModalData?.open}
                closeModal={handleNoButton}
                children={deleteModalData?.bodyData}
                size='md'
                header={deleteModalData?.header}
                extraClass='header-title-styles'
                footerContent={deleteModalFooterData}
            />
            <CustomTable
                pagination={true}
                columnsDef={testTemplateTableHeader}
                data={tableData || null}
                pageSizeCustom={pageSize}
                max={Math.ceil(totalElements / pageSize)}
                paginationFunc={paginationHandler}
                handleSort={handleSort}
                elementLabel={'test'}
                sortIcon={sort}
                sortCol={testTemplateTableHeader.map((e: any) => e?.field).indexOf(sortCol)}
                currentPageNo={currentPage}
                noDataMessage={t('norecordAvailable')}
                handleAllCheckBox={handleAllCheckBox}
                selectAllCheckBox={isMainCheckboxSelected(tableData, checkedTemplates)}
            />
        </div>
    );
}

export default TestTemplateTable;
