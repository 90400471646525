import './dataSelection.scss';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import { SessionStatus, Update } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'store';

import ButtonComponent from 'app/components/nds/button/button';
import { stepperRoutes } from 'constants/stepperConstants';
import { useTranslation } from 'react-i18next';
import {
    updateUserFlow,
    updateActiveStep,
    getCanonicalModelById,
    updateTestSession,
    updateCurrentTestSession,
} from '../../redux/testSessionReducer';
import { currentTestSession } from '../../redux/testSessionSelectors';
import { useSelector } from 'react-redux';
import AccordionDataSelection from './accordionDataSelection';
import { NexusCard } from '@nexus/react';
import ServerEvents from 'services/serverEvents';
import { selectAuthToken } from 'auth';
import { atApi } from 'constants/apiConstants';

const DataSelection: React.FC = () => {
    const [t] = useTranslation('lang');
    const dispatch = useAppDispatch();
    const [isConnEstablished, setIsConnEstablished] = useState(false);
    const accessToken = useSelector(selectAuthToken);
    const testSession = useSelector(currentTestSession);
    const {
        testSessionId,
        testSessionName,
        testSessionDescription,
        testSessionIsDraft,
        testSessionTestCases,
        dataSources = [],
    } = testSession;

    useEffect(() => {
        dataSources?.forEach((dataSource: any) => {
            if (dataSource?.id) {
                dispatch(getCanonicalModelById(dataSource?.id));
            }
        });
    }, []);

    const establishConnection = (payload: any) => {
        let updatedData: any;
        ServerEvents({
            accessToken,
            dispatchData: (data: any) => {
                if (data === 'Connection Established') {
                    setIsConnEstablished(true);
                    dispatch(updateTestSession(payload));
                }
                if (data !== 'Connection Established') {
                    updatedData = JSON.parse(data);
                    if (updatedData && Array.isArray(updatedData)) {
                        const updatedDataSources = dataSources.map((dataSource: any) => {
                            const matchedData = updatedData.find((data: any) => data.canonicalModel === dataSource.id);
                            if (matchedData) {
                                return {
                                    ...dataSource,
                                    dataInputFile: matchedData,
                                };
                            }
                            return dataSource;
                        });
                        dispatch(updateCurrentTestSession({ dataSources: updatedDataSources }));
                    }
                }
            },
            url: `${atApi}/test-sessions/${testSessionId}/data-input-files`,
        });
    };

    useEffect(() => {
        const allHaveUploadedFileId = dataSources.every((dataSource: any) => dataSource?.dataInputFile?.id);
        if (allHaveUploadedFileId) {
            const updatedTestSessionTestCases = testSessionTestCases.map((item: any) => {
                return {
                    testCaseId: item.id || item.testCaseId,
                };
            });
            const updatedDataSources = dataSources?.map((dataSource: any) => {
                return {
                    commonKey: dataSource.commonKey,
                    dataInputFile: { id: dataSource?.dataInputFile?.id },
                    id: dataSource.id,
                };
            });
            const payload: { testSessionRequest: any; id: number } = {
                testSessionRequest: {
                    dataSources: updatedDataSources,
                    description: testSessionDescription,
                    draftStatus: SessionStatus.step4,
                    isDraft: testSessionIsDraft !== undefined ? testSessionIsDraft : true,
                    name: testSessionName,
                    testSessionTestCases: updatedTestSessionTestCases,
                },
                id: testSessionId,
            };
            const connectionCheck = dataSources.every(
                (dataSource: any) =>
                    dataSource?.dataInputFile?.status === 'ready' || dataSource?.dataInputFile?.status === 'invalid',
            );
            if (!connectionCheck && !isConnEstablished) {
                establishConnection(payload);
            }
        }
    }, [dataSources]);

    const handleNextButton = () => {
        const updatedTestSessionTestCases = testSessionTestCases.map((item: any) => {
            return {
                testCaseId: item.id || item.testCaseId,
            };
        });
        const updatedDataSources = dataSources?.map((dataSource: any) => {
            return {
                commonKey: dataSource.commonKey,
                dataInputFile: dataSource?.dataInputFile?.id ? { id: dataSource?.dataInputFile?.id } : null,
                dateFrom: dataSource?.dateFrom ? dataSource.dateFrom : null,
                dateTo: dataSource?.dateTo ? dataSource.dateTo : null,
                filterBy: dataSource?.filterBy ? dataSource.filterBy : null,
                id: dataSource.id,
            };
        });
        const payload: { testSessionRequest: any; id: number } = {
            testSessionRequest: {
                dataSources: updatedDataSources,
                description: testSessionDescription,
                draftStatus: SessionStatus.step5,
                isDraft: testSessionIsDraft !== undefined ? testSessionIsDraft : true,
                name: testSessionName,
                testSessionTestCases: updatedTestSessionTestCases,
            },
            id: testSessionId,
        };
        dispatch(updateTestSession(payload)).then((response: any) => {
            if (response.payload.status === 200) {
                dispatch(updateActiveStep(stepperRoutes.step_5));
            }
        });
    };
    const handleBackButton = () => {
        if (dataSources?.length === 1) {
            dispatch(updateActiveStep(stepperRoutes.step_2));
        } else {
            dispatch(updateActiveStep(stepperRoutes.step_3));
        }
        dispatch(updateUserFlow(Update));
    };

    const handleNextState = () => {
        const hasUploadedFileId = dataSources.some((dataSource: any) => dataSource?.dataInputFile?.id);
        const hasFilterByDateRange = dataSources.some(
            (dataSource: any) => dataSource.filterBy || dataSource.dateFrom || dataSource.dateTo,
        );
        const allHaveUploadedFileId = dataSources.every((dataSource: any) => dataSource?.dataInputFile?.id);
        const allHaveFilterByDateRange = dataSources.every(
            (dataSource: any) => dataSource.filterBy && dataSource.dateFrom && dataSource.dateTo,
        );
        if (!hasUploadedFileId && !hasFilterByDateRange) {
            return false;
        } else if (allHaveUploadedFileId || allHaveFilterByDateRange) {
            if (allHaveUploadedFileId) {
                return !dataSources.every((dataSource: any) => dataSource?.dataInputFile?.status === 'ready');
            }
            return false;
        } else return true;
    };

    return (
        <div data-testid='data-selection'>
            <NexusCard>
                <div className='nexus-row'>
                    <div className='circle nexus-ml-1'>1</div>
                    <div className='page-title nexus-ml-1'>{t('dataSelection.dataSourcesInfo')}</div>
                </div>
                <div className='page-title-text nexus-mt-1 nexus-ml-3 nexus-mb-2'>
                    {t('dataSelection.dataSourcesText1')}
                    <div> {t('dataSelection.dataSourcesText2')}</div>
                </div>

                <div className='datasources-container'>
                    <div className='datasource-title nexus-mb-1'>{t('dataSelection.dataSources')}</div>
                    {dataSources?.map((dataSource: any) => {
                        const updatedDataSource = { ...dataSource };
                        return (
                            <AccordionDataSelection
                                key={dataSource?.id}
                                dataSource={updatedDataSource}
                                setIsConnEstablished={setIsConnEstablished}
                            />
                        );
                    })}
                </div>

                <div className='nexus-flex-row-reverse nexus-mt-3'>
                    <div className='nexus-row'>
                        <div className='nexus-mr-2'>
                            <ButtonComponent
                                type={['nexus-btn nexus-rhythm-top-5']}
                                label={t('buttons.back')}
                                click={handleBackButton}
                            />
                        </div>
                        <div className='nexus-mr-1'>
                            <ButtonComponent
                                extraClass='nexus-btn-default'
                                type={['nexus-btn-primary nexus-rhythm-top-5']}
                                label={t('buttons.next')}
                                click={handleNextButton}
                                disabled={handleNextState()}
                            />
                        </div>
                    </div>
                </div>
            </NexusCard>
        </div>
    );
};

export default DataSelection;
