/* eslint-disable sort-keys */

import { Update, MVEL, error } from 'constants/constants';
import { stepperRoutes } from 'constants/stepperConstants';
import { addMessage, removeMessage } from 'utils/actionUtils';
import { createAppSlice } from 'store/sliceHelper';
import { PayloadAction } from '@reduxjs/toolkit';
import {
    TestCaseApiFactory,
    PRCDataHierarchyApiFactory,
    CanonicalModelsApiFactory,
    DataInputFileApiFactory,
    DownloadFileApiFactory,
    TestCaseStatus,
    SearchTestCasePage,
    TestTemplateResponse,
    PrcDataDTO,
    TestTemplateRequest,
    CanonicalModelResponse,
    DataInputFileResponse,
    TestCaseResponse,
    TestTemplatePreviewRequest,
    StreamingResponseBody,
    TestCaseExecutionsResponsePage,
} from 'app/api/atAPI';
import { atApiConfig } from 'constants/apiConstants';

const testCaseApiFactory = TestCaseApiFactory(atApiConfig);
const prcDataHierarchyApiFactory = PRCDataHierarchyApiFactory(atApiConfig);
const canonicalModelsApiFactory = CanonicalModelsApiFactory(atApiConfig);
const dataInputFileApiFactory = DataInputFileApiFactory(atApiConfig);
const downloadFileApiFactory = DownloadFileApiFactory(atApiConfig);

export const INITIAL_STATE_TEST_TEMPLATE: any = {
    canonicalModels: [],
    checkedItems: [],
    controlData: [],
    currentTestTemplate: {
        selectedCanonicalModel: {},
        scriptConfiguration: {},
    },
    isLoading: false,
    lobData: [],
    messages: [],
    processData: [],
    riskData: [],
    sectorData: [],
    testTemplateTableData: {},
};

type TestTemplateState = Readonly<typeof INITIAL_STATE_TEST_TEMPLATE>;
export const testTemplateSliceReducer = createAppSlice({
    initialState: INITIAL_STATE_TEST_TEMPLATE as TestTemplateState,
    name: 'testTemplateData',
    reducers: (create) => {
        return {
            getTestTemplateTableData: create.asyncThunk(
                async (
                    body: {
                        status?: TestCaseStatus;
                        controlIds?: Array<string>;
                        testCaseCreationDateFrom?: string | null;
                        testCaseCreationDateTo?: string | undefined;
                        canonicalModelIds?: Array<number>;
                        search?: string;
                        page?: number;
                        size?: number;
                        sort?: Array<string>;
                    },
                    { rejectWithValue },
                ) => {
                    try {
                        const response: { data: SearchTestCasePage; status: number } =
                            await testCaseApiFactory.searchTestTemplate(
                                body.status,
                                body.controlIds,
                                undefined,
                                body.testCaseCreationDateFrom,
                                body.testCaseCreationDateTo,
                                body.canonicalModelIds,
                                body.search,
                                body.page,
                                body.size,
                                body.sort,
                            );
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getTestTemplateTableData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getTestTemplateTableData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: 'error',
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getTestTemplateTableData');
                        state.testTemplateTableData = action.payload?.data;
                    },
                },
            ),
            getPublishedTestTemplateData: create.asyncThunk(
                async (
                    body: {
                        status?: TestCaseStatus;
                        controlIds?: Array<string>;
                        testCaseCreationDateFrom?: string | null;
                        testCaseCreationDateTo?: string;
                        search?: string;
                        page?: number;
                        size?: number;
                        sort?: Array<string>;
                    },
                    { rejectWithValue },
                ) => {
                    try {
                        const response: { data: SearchTestCasePage; status: number } =
                            await testCaseApiFactory.searchTestTemplate(
                                body.status,
                                body.controlIds,
                                undefined,
                                body.testCaseCreationDateFrom,
                                body.testCaseCreationDateTo,
                                undefined,
                                body.search,
                                body.page,
                                body.size,
                                body.sort,
                            );
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getPublishedTestTemplateData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getPublishedTestTemplateData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: 'error',
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getPublishedTestTemplateData');
                        state.publishedTestTemplateData = action.payload?.data;
                    },
                },
            ),
            cloneTestTemplate: create.asyncThunk(
                async (testTemplateId: number, { rejectWithValue }) => {
                    try {
                        const response: { data: TestTemplateResponse; status: number } =
                            await testCaseApiFactory.cloneTestTemplate(testTemplateId);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'cloneTestTemplate');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'cloneTestTemplate',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: 'error',
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'cloneTestTemplate');
                        state.currentTestTemplate = {
                            ...action.payload.data,
                            draftStatus: stepperRoutes.step_1,
                            scriptConfiguration: {
                                ...action.payload.data.scriptConfiguration,
                                expression_string: '',
                            },
                            userFlow: Update,
                        };
                    },
                },
            ),
            getSectorDropdownData: create.asyncThunk(
                async (_: void, { rejectWithValue }) => {
                    try {
                        const response: { data: Array<PrcDataDTO>; status: number } =
                            await prcDataHierarchyApiFactory.getAllSectors();
                        if (response?.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'sectorData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'sectorData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'sectorData');
                        state.sectorData = action.payload?.data;
                    },
                },
            ),
            getLobDropdownData: create.asyncThunk(
                async (sectorId: string, { rejectWithValue }) => {
                    try {
                        const response: { data: Array<PrcDataDTO>; status: number } =
                            await prcDataHierarchyApiFactory.getLobs(sectorId);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'lobData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'lobData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'lobData');
                        state.lobData = action.payload?.data;
                    },
                },
            ),
            getProcessDropdownData: create.asyncThunk(
                async (lobId: string, { rejectWithValue }) => {
                    try {
                        const response: { data: Array<PrcDataDTO>; status: number } =
                            await prcDataHierarchyApiFactory.getProcesses(lobId);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'processData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'processData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'processData');
                        state.processData = action.payload?.data;
                    },
                },
            ),
            getRiskDropdownData: create.asyncThunk(
                async (processId: string, { rejectWithValue }) => {
                    try {
                        const response: { data: Array<PrcDataDTO>; status: number } =
                            await prcDataHierarchyApiFactory.getRisks([''], processId);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'riskData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'riskData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'riskData');
                        state.riskData = action.payload?.data;
                    },
                },
            ),
            getControlDropdownData: create.asyncThunk(
                async (riskId: string, { rejectWithValue }) => {
                    try {
                        const response: { data: Array<PrcDataDTO>; status: number } =
                            await prcDataHierarchyApiFactory.getControls([''], [riskId]);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'controlData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'controlData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'controlData');
                        state.controlData = action.payload?.data;
                    },
                },
            ),
            updateTestTemplate: create.asyncThunk(
                async (payload: { testTemplateId: number; requestBody: TestTemplateRequest }, { rejectWithValue }) => {
                    try {
                        const response: { data: TestTemplateResponse; status: number } =
                            await testCaseApiFactory.updateTestTemplateDetails(
                                payload.testTemplateId,
                                payload.requestBody,
                            );
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'updateTestTemplate');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'updateTestTemplate',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'updateTestTemplate');
                        state.currentTestTemplate = {
                            ...state.currentTestTemplate,
                            ...action.payload.data,
                            scriptConfiguration: {
                                ...state.currentTestTemplate?.scriptConfiguration,
                                ...action.payload.data.scriptConfiguration,
                                expression_string: state.currentTestTemplate?.scriptConfiguration.expression_string,
                            },
                        };
                    },
                },
            ),
            createTestTemplate: create.asyncThunk(
                async (testTemplateRequest: TestTemplateRequest, { rejectWithValue }) => {
                    try {
                        const response: { data: TestTemplateResponse; status: number } =
                            await testCaseApiFactory.saveTestTemplateDetails(testTemplateRequest);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'createTestTemplate');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'createTestTemplate',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                autoClose: 5000,
                                id: 'createTestTemplate',
                                message: 'Template creation successful',
                                module: 'template',
                                type: 'success',
                            },
                            removeToastMessage,
                        );
                        state.currentTestTemplate = {
                            ...state.currentTestTemplate,
                            ...action.payload.data,
                        };
                    },
                },
            ),
            getCanonicalModels: create.asyncThunk(
                async (_: void, { rejectWithValue }) => {
                    try {
                        const response: { data: Array<CanonicalModelResponse>; status: number } =
                            await canonicalModelsApiFactory.fetchAllCanonicalModelInfo();
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getCanonicalModels');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getCanonicalModels',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getCanonicalModels');
                        state.canonicalModels = action.payload?.data;
                    },
                },
            ),
            getTemplateFile: create.asyncThunk(async (id: string, { rejectWithValue }) => {
                try {
                    const response: { data: File; status: number } =
                        await canonicalModelsApiFactory.downloadTemplateForCanonicalModel(parseInt(id));
                    if (response.status >= 400) {
                        return rejectWithValue(response);
                    }
                    return response;
                } catch (error: any) {
                    return rejectWithValue(error);
                }
            }),
            uploadDataSourceFile: create.asyncThunk(
                async (data: { id: number; file: File }, { rejectWithValue }) => {
                    try {
                        const response: any = await dataInputFileApiFactory.uploadDataInputFile(data.id, data.file);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'uploadDataSourceFile');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'uploadDataSourceFile',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'uploadDataSourceFile');
                        state.currentTestTemplate.dataInputFile = action.payload?.data;
                    },
                },
            ),
            getDataSourceTemplateInfo: create.asyncThunk(
                async (dataFileId: string, { rejectWithValue }) => {
                    try {
                        const response: { data: DataInputFileResponse; status: number } =
                            await dataInputFileApiFactory.getDataInputFile(parseInt(dataFileId));
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.currentTestTemplate.dataInputFile = {
                            ...state.currentTestTemplate.dataInputFile,
                            ...action.payload.data,
                        };
                    },
                },
            ),
            getTestTemplate: create.asyncThunk(
                async (id: string, { rejectWithValue }) => {
                    try {
                        const response: { data: TestCaseResponse; status: number } =
                            await testCaseApiFactory.fetchTestCaseDetails(parseInt(id));
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getTestTemplate');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getTestTemplate',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getTestTemplate');
                        state.currentTestTemplate = {
                            ...state.currentTestTemplate,
                            ...action.payload.data,
                            ...(action.payload.data?.scriptConfiguration && {
                                scriptConfiguration: {
                                    ...JSON.parse(JSON.stringify(action.payload.data?.scriptConfiguration)),
                                    // retaining the same expression string as gettestemplate returns expression string with placeholders if type is expression
                                    expression_string:
                                        action.payload?.data?.scriptConfiguration?.type === MVEL
                                            ? action.payload?.data?.scriptConfiguration?.expression_string
                                            : state.currentTestTemplate?.scriptConfiguration?.expression_string,
                                },
                            }),
                            draftStatus: action.payload.data?.isDraft
                                ? action.payload.data?.draftStatus || stepperRoutes.step_1
                                : stepperRoutes.step_4,
                        };
                    },
                },
            ),
            getCannonicalModelById: create.asyncThunk(
                async (id: string, { rejectWithValue }) => {
                    try {
                        const response: { data: CanonicalModelResponse; status: number } =
                            await canonicalModelsApiFactory.fetchCanonicalModelInfo(parseInt(id));
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getCannonicalModelById');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getCannonicalModelById',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getCannonicalModelById');
                        state.currentTestTemplate.selectedCanonicalModel = {
                            ...state.currentTestTemplate.selectedCanonicalModel,
                            ...action.payload.data,
                        };
                    },
                },
            ),
            getExpPreview: create.asyncThunk(
                async (testTemplatePreviewRequest: TestTemplatePreviewRequest, { rejectWithValue }) => {
                    try {
                        const response: { data: string; status: number } = await testCaseApiFactory.generatePreview(
                            testTemplatePreviewRequest,
                        );
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getExpPreview');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getExpPreview',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getExpPreview');
                        state.currentTestTemplate.scriptConfiguration.expression_string = action.payload?.data;
                    },
                },
            ),
            executeTestCase: create.asyncThunk(
                async (id: number, { rejectWithValue }) => {
                    try {
                        const response: { data: void; status: number } = await testCaseApiFactory.executeTestCase(id);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.currentTestTemplate.executeError = action?.payload?.response?.data?.detail;
                    },
                },
            ),
            fetchTestCaseExecutions: create.asyncThunk(
                async (
                    body: { testCaseId?: any; page?: number; size?: number; sort?: Array<string> },
                    { rejectWithValue },
                ) => {
                    try {
                        const response: { data: TestCaseExecutionsResponsePage; status: number } =
                            await testCaseApiFactory.fetchTestCaseExecutions(
                                body.testCaseId,
                                body.page,
                                body.size,
                                body.sort,
                            );
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'fetchTestCaseExecutions');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'fetchTestCaseExecutions',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'fetchTestCaseExecutions');
                        state.currentTestTemplate.testExecutionData = action.payload?.data;
                    },
                },
            ),
            publishTestTemplate: create.asyncThunk(
                async (body: { id: number; testTemplateRequest: TestTemplateRequest }, { rejectWithValue }) => {
                    try {
                        const response = await testCaseApiFactory.updateTestTemplateDetails(
                            body.id,
                            body.testTemplateRequest,
                        );
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
            ),
            getFile: create.asyncThunk(async (downloadLink: string, { rejectWithValue }) => {
                try {
                    const response: { data: StreamingResponseBody; status: number } =
                        await downloadFileApiFactory.downloadFile(downloadLink);
                    if (response.status >= 400) {
                        return rejectWithValue(response);
                    }
                    return response;
                } catch (error: any) {
                    return rejectWithValue(error);
                }
            }),
            deleteTestTemplate: create.asyncThunk(
                async (id: string, { rejectWithValue }) => {
                    try {
                        const response: { data: void; status: number } = await testCaseApiFactory.deleteTestCase(
                            parseInt(id),
                        );
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return error;
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'deleteTestTemplate');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'deleteTestTemplate',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                autoClose: 5000,
                                id: 'deleteTestTemplate',
                                message: 'Test Template deleted successfully',
                                module: 'template',
                                type: 'success',
                            },
                            removeToastMessage,
                        );
                    },
                },
            ),
            deleteSelectedTemplate: create.asyncThunk(
                async (ids: Array<number>, { rejectWithValue }) => {
                    try {
                        const response: { data: void; status: number } = await testCaseApiFactory.deleteTestCases(ids);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return error;
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'deleteSelectedTemplate');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'deleteSelectedTemplate',
                                message: action?.payload?.response?.data?.detail,
                                module: 'template',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_TEMPLATE) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                autoClose: 5000,
                                id: 'deleteSelectedTemplate',
                                message: 'Selected Test Templates deleted successfully',
                                module: 'template',
                                type: 'success',
                            },
                            removeToastMessage,
                        );
                    },
                },
            ),
            updateTestTemplateScriptConfiguration: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                    state.currentTestTemplate.scriptConfiguration = {
                        ...state.currentTestTemplate.scriptConfiguration,
                        ...action.payload,
                    };
                },
            ),
            updateCheckedItems: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                    state.checkedItems = action.payload;
                },
            ),
            updateCurrentTestTemplate: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                    state.currentTestTemplate = { ...state.currentTestTemplate, ...action.payload };
                },
            ),
            showToastMessage: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                    state.messages = addMessage(state, { ...action.payload, module: 'template' }, removeToastMessage);
                },
            ),
            removeToastMessage: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                    state.messages = removeMessage(state, action.payload);
                },
            ),
            updateActiveStep: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                    state.currentTestTemplate.draftStatus = action.payload;
                },
            ),
            updateUserFlow: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                    state.currentTestTemplate.userFlow = action.payload;
                },
            ),
            reset: create.reducer<any>((state: typeof INITIAL_STATE_TEST_TEMPLATE, action: PayloadAction<any>) => {
                const key = action.payload as string;
                state[key] = INITIAL_STATE_TEST_TEMPLATE[key];
            }),
            resetAll: create.reducer<any>(() => INITIAL_STATE_TEST_TEMPLATE),
        };
    },
});

export const {
    getTestTemplateTableData,
    getPublishedTestTemplateData,
    cloneTestTemplate,
    getSectorDropdownData,
    getLobDropdownData,
    getProcessDropdownData,
    getRiskDropdownData,
    getControlDropdownData,
    updateTestTemplate,
    createTestTemplate,
    getCanonicalModels,
    getTemplateFile,
    uploadDataSourceFile,
    getDataSourceTemplateInfo,
    getTestTemplate,
    getCannonicalModelById,
    getExpPreview,
    executeTestCase,
    fetchTestCaseExecutions,
    publishTestTemplate,
    getFile,
    deleteTestTemplate,
    deleteSelectedTemplate,
    updateTestTemplateScriptConfiguration,
    updateCheckedItems,
    updateCurrentTestTemplate,
    showToastMessage,
    removeToastMessage,
    updateActiveStep,
    updateUserFlow,
    reset,
    resetAll,
} = testTemplateSliceReducer.actions;
export default testTemplateSliceReducer.reducer;
