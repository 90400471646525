import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
    selectAuthToken,
    selectIsAuthTokenExpired,
    selectIsAuthenticated,
    selectIsLogoutRequested,
    setAuthToken,
    setInitialState,
} from '../authSlice';
import { useNavigate } from 'react-router';
import { authenticateSilently, oktaLogout } from './oktaAuthProvider';
import { formatTokenData } from '../authHelper';
interface InputProps {
    children: React.ReactNode;
    doLogout?: boolean;
}
const OktaAuth = ({ children, doLogout }: InputProps) => {
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const isTokenExpired = useSelector(selectIsAuthTokenExpired);
    const isLogoutRequested = useSelector(selectIsLogoutRequested);
    const accessToken = useSelector(selectAuthToken);
    const [showChildren, setShowChildren] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loggingOut = isLogoutRequested || doLogout;

    useEffect(() => {
        if (isTokenExpired) {
            authenticateSilently({
                callBack: (data: any) => {
                    const tokenData = formatTokenData(data.accessToken, Number(data.expiresOn));
                    dispatch(setAuthToken(tokenData));
                },
            });
        }
    }, [isTokenExpired]);

    useEffect(() => {
        if (!loggingOut) {
            accessToken ? setShowChildren(true) : navigate('/');
        }
    }, [accessToken]);
    useEffect(() => {
        if (loggingOut) {
            oktaLogout({
                callBack: () => {
                    dispatch(setInitialState(''));
                    setShowChildren(false);
                    navigate('/');
                },
            });
        }
    }, [loggingOut]);
    return (
        <>
            {showChildren && isAuthenticated && <>{children}</>}
            {showChildren && !isAuthenticated && <>Not Authenticated - Check env settings.</>}
        </>
    );
};

export default OktaAuth;
