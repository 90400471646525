import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './app/App';
import './index.scss';
import './i18';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
reportWebVitals();
