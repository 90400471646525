import React, { useEffect, useState } from 'react';
import FileUploadComponent from 'app/components/nds/fileupload/fileUpload';
import { NexusIcon } from '@nexus/react';
import FileIcon from '@nexus/core/dist/assets/icons/action/ic_description_24px.svg';
import HourglassIcon from '@nexus/core/dist/assets/icons/action/ic_hourglass_bottom_24px.svg';
import CheckIcon from '@nexus/core/dist/assets/icons/action/ic_check_circle_24px.svg';
import CrossIcon from '@nexus/core/dist/assets/icons/action/ic_cancel_24px.svg';

import { fileStatusFlag } from 'constants/constants';

const CustomFileUpload = ({ handleUpload, removeUpload, disabled, fileInfo }: any) => {
    const updatedFileInfo = fileInfo !== undefined ? [{ ...fileInfo }] : [];
    const [queue, setQueue] = useState<Array<any>>(updatedFileInfo || []);
    const returnFileQueue = (fileQueue: any) => {
        setQueue(fileQueue);
        handleUpload(fileQueue);
    };

    useEffect(() => {
        if (removeUpload) {
            setQueue([]);
        }
    }, [removeUpload]);

    const removeFileQueue = () => {
        setQueue([]);
    };
    const getStatus = (status: string) => {
        switch (status) {
            case fileStatusFlag.uploaded:
            case fileStatusFlag.validation:
                return (
                    <div>
                        <NexusIcon src={HourglassIcon} />
                        <span>Validating</span>
                    </div>
                );
            case fileStatusFlag.invalid:
                return (
                    <div>
                        <NexusIcon src={CrossIcon} className='cross-icon' />
                        <span>Error uploading data source</span>
                    </div>
                );
            case fileStatusFlag.ready:
                return (
                    <div>
                        <NexusIcon src={CheckIcon} className='check-icon' />
                        <span>Data uploaded</span>
                    </div>
                );
        }
    };
    return (
        <>
            {queue.length ? (
                <div className='file-details'>
                    {queue.map((file: any) => {
                        return (
                            <>
                                <NexusIcon src={FileIcon} size='md' />
                                <div className='file-text'>
                                    <span className='bold-text'>Name:</span> {file?.name}
                                </div>
                                <div className='file-text'>
                                    <span className='bold-text'>Size:</span> {file?.size}
                                </div>
                                <div className='file-status'>{getStatus(fileInfo?.status)}</div>
                            </>
                        );
                    })}
                </div>
            ) : (
                <FileUploadComponent
                    accept='.csv'
                    returnFileQueue={returnFileQueue}
                    removeFileQueue={removeFileQueue}
                    classes={`custom-file-upload ${disabled ? 'disabled' : ''}`}
                    infoMsg='Click or drop files to upload'
                    showSizeMsg={false}
                    disabled={disabled}
                />
            )}
        </>
    );
};

export default CustomFileUpload;
