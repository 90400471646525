import './templateSelection.scss';
import { SessionStatus, Update } from 'constants/constants';
import { useAppDispatch } from 'store';
import ButtonComponent from 'app/components/nds/button/button';
import InputComponent from 'app/components/nds/input/input';
import { NexusCard, NexusIcon } from '@nexus/react';
import React, { useEffect, useState } from 'react';
import SearchComponent from 'app/components/nds/search/search';
import TemplateSelectionTable from './templateSelectionTable';
import { stepperRoutes } from 'constants/stepperConstants';
import { useTranslation } from 'react-i18next';
import {
    saveTestSessionData,
    getAllCanonicalModels,
    updateTestSession,
    updateCurrentTestSession,
    updateUserFlow,
    updateActiveStep,
    getSectorDropdownData,
} from '../../redux/testSessionReducer';
import Select from 'app/components/nds/select/select';
import { currentTestSession, canonicalModels, sectorDataList } from '../../redux/testSessionSelectors';
import { useSelector } from 'react-redux';
import TextareaComponent from 'app/components/nds/textarea/textarea';
import { getDropdownValues } from 'services/services';
import CloseIcon from '@nexus/core/dist/assets/icons/action/ic_close_24px.svg';
import CustomMultiSelect from 'app/components/nds/customMultiSelect/customMultiSelect';
import { IStepperStatus } from 'constants/commonExportedInterfaces';
import ModalComponent from 'app/components/nds/modal/modal';

// props to handle skipped status
const TemplateSelection = ({ steps, setSteps }: { steps?: any; setSteps?: any }) => {
    const [t] = useTranslation('lang');
    const dispatch = useAppDispatch();
    const [searchKeyword, setSearchKeyword] = useState<string>('');
    const canonicalModelsData = useSelector(canonicalModels);
    const sectorData = useSelector(sectorDataList);
    const testSession = useSelector(currentTestSession);
    const {
        userFlow,
        testSessionTestCases,
        dataSourceFilter = [],
        prc,
        filterInfo,
        selectedDataSources = [],
        selectedTestCases,
        dataSources,
    } = testSession || {};
    const [testSessionName, setTestSessionName] = useState<string>(testSession?.testSessionName || '');
    const [testSessionDescription, setTestSessionDescription] = useState<string>(
        testSession?.testSessionDescription || '',
    );
    const [sector, setSector] = useState<{ name: string; id: string }>({ name: '', id: '' });
    const [expand, setExpand] = useState(false);
    const [showSectorModal, setShowSectorModal] = useState(false);
    const [showDataSourceModal, setShowDataSourceModal] = useState(false);
    const [dataSourceToRemove, setDataSourceToRemove] = useState<string>('');
    const [sectorToChange, setSectorToChange] = useState<{ name: string; id: string }>({ name: '', id: '' });

    useEffect(() => {
        if (!canonicalModelsData || canonicalModelsData?.length === 0) {
            dispatch(getAllCanonicalModels());
        }
        if (!sectorData || sectorData?.length === 0) {
            dispatch(getSectorDropdownData());
        }
        const sectorVal = prc?.sector || filterInfo?.prc?.sector || filterInfo?.sector;
        if (sectorVal?.id) {
            setSector({ name: sectorVal?.name, id: sectorVal?.id });
        }
    }, []);

    useEffect(() => {
        dispatch(updateCurrentTestSession({ dataSourceFilter: filterInfo?.dataSources }));
    }, [filterInfo?.dataSources]);

    const handleNextButton = async () => {
        const updatedDataSources = dataSourceFilter.map((item: any) => ({
            id: item.id,
            name: item.name,
        }));
        const dataSourcesCheck = Array.from(new Set(selectedDataSources)).length === 1;
        const filteredDataSources = dataSources?.map((dataSource: any) => ({
            commonKey: dataSource.commonKey,
            id: dataSource.id,
            name: dataSource.name,
        }));
        // testTemplateId is used while creating a new test session as case id is not available
        // testSession?.testCase?.testCaseId is used while updating a test session
        const payload = {
            dataSources: filteredDataSources?.length > 0 && dataSources?.[0]?.commonKey ? filteredDataSources : null,
            dateFrom: testSession.dateFrom,
            dateTo: testSession.dateTo,
            description: testSessionDescription,
            draftStatus: dataSourcesCheck ? SessionStatus.step4 : SessionStatus.step3,
            // next screen skip logic in case of single datasource
            filterInfo: {
                dataSources: updatedDataSources,
                prc: prc,
                sector: {
                    id: sector?.id,
                    name: sector?.name,
                },
            },
            isDraft: testSession.isDraft === false ? false : true,
            name: testSessionName,
            testSessionTestCases: testSessionTestCases.map((testCase: any) => {
                return { testCaseId: testCase.testCaseId, testCaseParameters: testCase.testCaseParameters };
            }),
        };

        if (userFlow === Update) {
            dispatch(updateTestSession({ id: testSession.testSessionId, testSessionRequest: payload })).then(
                (response: any) => {
                    if (response.payload?.status === 200) {
                        if (response.payload?.data?.dataSources?.length === 1) {
                            dispatch(updateActiveStep(stepperRoutes.step_4));
                        } else {
                            dispatch(updateActiveStep(stepperRoutes.step_3));
                        }
                    }
                },
            );
        } else {
            payload.testSessionTestCases = testSessionTestCases.reduce((acc: any, item: any) => {
                return [...acc, { testCaseId: item.testCaseId }];
            }, []);
            dispatch(saveTestSessionData(payload)).then((response: any) => {
                if (response.payload?.status === 201) {
                    dispatch(updateUserFlow(Update));
                    const { pathname } = window.location;
                    window.history.pushState('', document.title, `${pathname}/${response.payload.data.id}`);
                    if (response.payload?.data?.dataSources?.length === 1) {
                        dispatch(updateActiveStep(stepperRoutes.step_4));
                        const updatedSteps = steps.map((step: any) => {
                            if (step.name === stepperRoutes.step_3) {
                                return { ...step, status: 'skipped' as IStepperStatus };
                            }
                            return step;
                        });
                        setSteps(updatedSteps);
                    } else {
                        dispatch(updateActiveStep(stepperRoutes.step_3));
                    }
                }
            });
        }
        return;
    };
    const handleBackButton = () => {
        dispatch(updateActiveStep(stepperRoutes.step_1));
    };
    const isButtonDisabled = () => {
        return !(testSessionName && testSessionName?.trim()?.length > 0 && testSessionTestCases?.length > 0);
    };

    let searchTimeout: NodeJS.Timeout | null = null;
    const handleSearchText = (event: React.FormEvent<HTMLNexusInputElement>) => {
        const target = event.target as HTMLNexusTextareaElement;
        let searchStr = target.value?.trim();
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        // debouncing added
        searchTimeout = setTimeout(() => {
            setSearchKeyword(searchStr);
        }, 500);
    };

    const handleRemoveDataSource = (item: any) => {
        const isItemInFilterInfo = filterInfo?.dataSources?.some((dataSource: any) => dataSource.id === item.id);
        if (isItemInFilterInfo) {
            setShowDataSourceModal(true);
            setDataSourceToRemove(item.id);
        } else {
            const updatedData = dataSourceFilter?.filter((row: any) => row?.id !== item?.id);
            const updatedSelectedTCs = selectedTestCases?.filter(
                (testCase: any) => testCase?.canonicalModel?.canonicalModelId !== item.id,
            );
            dispatch(
                updateCurrentTestSession({
                    dataSourceFilter: updatedData,
                    selectedTestCases: updatedSelectedTCs,
                    testSessionTestCases: updatedSelectedTCs.filter((testCase: any) => testCase.testCaseId),
                }),
            );
        }
    };

    const handleAddDataSource = (e: any, item: any) => {
        e.preventDefault();
        const isItemPresent = dataSourceFilter?.some((filterItem: any) => filterItem?.id === item?.id);
        if (isItemPresent) {
            const updatedData = dataSourceFilter?.filter((row: any) => row?.id !== item?.id);
            const isItemInFilterInfo = filterInfo?.dataSources?.some((dataSource: any) => dataSource.id === item.id);
            const updatedSelectedTCs = selectedTestCases?.filter(
                (testCase: any) => testCase?.canonicalModel?.canonicalModelId !== item.id,
            );
            const updatedTestSessionTestCases = updatedSelectedTCs?.filter((testCase: any) => testCase.testCaseId);
            if (isItemInFilterInfo) {
                setShowDataSourceModal(true);
                setDataSourceToRemove(item.id);
            } else
                dispatch(
                    updateCurrentTestSession({
                        dataSourceFilter: updatedData,
                        testSessionTestCases: updatedTestSessionTestCases,
                    }),
                );
        } else {
            const newDataSources = [...dataSourceFilter, { id: item?.id, name: item?.name }];
            const uniqueDataSources = Array.from(new Set(newDataSources.map((ds) => ds.id))).map((id) => {
                return newDataSources.find((ds) => ds.id === id);
            });
            dispatch(updateCurrentTestSession({ dataSourceFilter: uniqueDataSources }));
        }
    };

    const sectorDataOptions = () => {
        const sectorOptions =
            [{ id: 'Please Select', label: 'Please Select Sector', value: '' }, ...getDropdownValues(sectorData)] || [];
        return sectorOptions;
    };

    const handleSectorChange = (data: any) => {
        const sectorId = sectorData?.find((item: any) => item?.name === data)?.id;
        if (data !== sector?.name) {
            setSector({ name: data, id: '' });
            if (filterInfo?.sector?.id) {
                setShowSectorModal(true);
                setSectorToChange({ name: data, id: sectorId });
            } else {
                setSector({ name: data, id: sectorId });
                dispatch(
                    updateCurrentTestSession({
                        dataSourceFilter: [],
                        sectorFilter: sectorId,
                        testSessionTestCases: [],
                        selectedTestCases: [],
                        selectedDataSources: [],
                    }),
                );
            }
        }
    };

    const resetSectorChange = () => {
        setShowSectorModal(false);
        setSector(sectorToChange);
        dispatch(
            updateCurrentTestSession({
                dataSourceFilter: [],
                sectorFilter: sectorToChange?.id,
                testSessionTestCases: [],
                selectedTestCases: [],
                selectedDataSources: [],
            }),
        );
    };

    const noSectorChange = () => {
        setSector({ name: filterInfo?.sector?.name, id: filterInfo?.sector?.id });
        setShowSectorModal(false);
    };

    const sectorModalBody = (
        <>
            <div>{t('templateSelection.resetMsg')}</div>
            <div className='nexus-flex-row-reverse'>
                <div className='nexus-row'>
                    <div className='nexus-mr-2'>
                        <ButtonComponent
                            type={['nexus-btn nexus-rhythm-top-3']}
                            label={t('templateSelection.no')}
                            click={noSectorChange}
                        />
                    </div>
                    <div className='nexus-mr-1'>
                        <ButtonComponent
                            extraClass='nexus-btn-default'
                            type={['nexus-btn-primary nexus-rhythm-top-3']}
                            label={t('templateSelection.yes')}
                            click={resetSectorChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );

    const resetDataSourceChange = () => {
        const updatedDataSourceFilter = dataSourceFilter.filter(
            (dataSource: any) => dataSource.id !== dataSourceToRemove,
        );
        const updatedTestCases = testSessionTestCases.filter(
            (testCase: any) => testCase.canonicalModelId !== dataSourceToRemove,
        );
        dispatch(
            updateCurrentTestSession({
                dataSourceFilter: updatedDataSourceFilter,
                testSessionTestCases: updatedTestCases,
            }),
        );
        setShowDataSourceModal(false);
    };

    const noDataSourceChange = () => {
        setShowDataSourceModal(false);
    };

    const dataSourceModalBody = (
        <>
            <div>{t('templateSelection.dataSourceText')}</div>
            <div className='nexus-flex-row-reverse'>
                <div className='nexus-row'>
                    <div className='nexus-mr-2'>
                        <ButtonComponent
                            type={['nexus-btn nexus-rhythm-top-3']}
                            label={t('templateSelection.no')}
                            click={noDataSourceChange}
                        />
                    </div>
                    <div className='nexus-mr-1'>
                        <ButtonComponent
                            extraClass='nexus-btn-default'
                            type={['nexus-btn-primary nexus-rhythm-top-3']}
                            label={t('templateSelection.yes')}
                            click={resetDataSourceChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <div data-testid='template-selection'>
            <NexusCard className='nexus-card-border-0 nexus-p-3'>
                <div className=''>
                    <div className='nexus-row'>
                        <div className='circle nexus-ml-1'>1</div>
                        <div className='nexus-subtitle nexus-ml-1'>{t('templateSelection.header1')}</div>
                    </div>
                    <div className='nexus-row nexus-caption-copy nexus-mt-1 nexus-ml-3 nexus-mb-2'>
                        {t('templateSelection.subHeader1')}
                    </div>
                    <div className='table-filters'>
                        <div className='nexus-col-lg-4 no-padding'>
                            <SearchComponent
                                placeholder={t('riskControlTestcase.search')}
                                onInputSearchValue={(event: React.FormEvent<HTMLNexusInputElement>) =>
                                    handleSearchText(event)
                                }
                                initialValue={searchKeyword}
                                extraClass='search-box'
                            />
                        </div>
                        <div className='nexus-col-lg-4 no-padding'>
                            <Select
                                name={'sector'}
                                key={sector?.name || 'sector'}
                                selectedValue={sector?.name}
                                options={sectorDataOptions()}
                                customOnChange={handleSectorChange}
                                disabledOption={t('templateSelection.selectSector')}
                                classes='sector-select'
                                placeholder={t('templateSelection.selectSector')}
                                disabled={prc?.sector?.id || filterInfo?.prc?.sector?.id ? true : false}
                            />
                        </div>
                        <div className='datasource-container nexus-ml-2'>
                            <CustomMultiSelect
                                dataArray={canonicalModelsData}
                                setArray={dataSourceFilter}
                                expand={expand}
                                setExpand={setExpand}
                                handleAction={handleAddDataSource}
                                selectedMsg={t('templateSelection.dataSourcesSelected')}
                                selectMsg={t('templateSelection.selectDataSource')}
                                name='datasource'
                            />
                        </div>
                    </div>
                </div>

                {/* logic to display selected dataSources */}
                {dataSourceFilter?.length > 0 ? (
                    <div className='selected-data-sources-filter'>
                        {dataSourceFilter?.map((item: any) => {
                            return (
                                <div key={item.id} className='datasource-filter'>
                                    <NexusIcon
                                        className='close-icon'
                                        src={CloseIcon}
                                        onClick={() => handleRemoveDataSource(item)}
                                    />
                                    <label className='filter-value'>{item?.name}</label>
                                </div>
                            );
                        })}
                    </div>
                ) : null}

                <TemplateSelectionTable
                    searchKeyword={searchKeyword}
                    sectorId={sector.id}
                    checkedItems={testSessionTestCases?.reduce((acc: any, item: any) => {
                        return [...acc, item.testCaseId];
                    }, [])}
                />
                <div className='nexus-row'>
                    <div className='nexus-mt-2 nexus-ml-1 circle'>2</div>
                    <div className='nexus-subtitle nexus-mx-2 nexus-mt-2'>{t('templateSelection.header2')}</div>
                </div>
                <div className='nexus-row'>
                    <div className='nexus-col-lg-4'>
                        <div className='nexus-caption-copy nexus-mt-1 nexus-mb-2 nexus-ml-4'>
                            {t('templateSelection.subHeader2')}
                        </div>
                        <div className='nexus-ml-4'>
                            <div className='nexus-data nexus-mb-1' data-testid='label-test-session-name'>
                                {t('templateSelection.testSessionName')}
                            </div>
                            <InputComponent
                                type='text'
                                placeholder={t('templateSelection.addText')}
                                data-testid='input-textbox-projectName'
                                initialValue={testSession?.testSessionName || testSessionName}
                                onChange={(event) => {
                                    setTestSessionName(event.target.value);
                                }}
                                extraClass='input-width'
                                disabled={testSession?.testSessionName?.length > 0}
                            />
                        </div>
                    </div>
                    <div className='nexus-col-lg-4'>
                        <div className='nexus-caption-copy nexus-mt-1 nexus-mb-2'>
                            {t('templateSelection.subHeader3')}
                        </div>
                        <div className=''>
                            <div className='nexus-data nexus-mb-1' data-testid='label-test-session-name'>
                                {t('templateSelection.testSessionDescription')}
                            </div>
                            <TextareaComponent
                                placeholder={t('templateSelection.addText')}
                                data-testid='input-textbox-projectName'
                                value={testSession?.testSessionDescription || testSessionDescription}
                                onChange={(event: any) => {
                                    setTestSessionDescription(event.target.value);
                                }}
                                styles='input-width'
                            />
                        </div>
                    </div>
                </div>
                <div className='nexus-flex-row-reverse nexus-mt-3'>
                    <div className='nexus-row'>
                        <div className='nexus-mr-2'>
                            <ButtonComponent
                                type={['nexus-btn nexus-rhythm-top-5']}
                                label={t('buttons.back')}
                                disabled={userFlow === Update}
                                click={handleBackButton}
                            />
                        </div>
                        <div className='next-button-styles'>
                            <ButtonComponent
                                extraClass='nexus-btn-default'
                                type={['nexus-btn-primary nexus-rhythm-top-5']}
                                label={t('buttons.next')}
                                disabled={isButtonDisabled()}
                                click={handleNextButton}
                            />
                        </div>
                    </div>
                </div>
            </NexusCard>
            <ModalComponent
                show={showSectorModal}
                closeModal={noSectorChange}
                children={sectorModalBody}
                header={t('testDesigner.selectTemplate')}
                size='md'
                extraClass='header-alignment'
            />
            <ModalComponent
                show={showDataSourceModal}
                closeModal={noDataSourceChange}
                children={dataSourceModalBody}
                header={t('templateSelection.resetDataSource')}
                size='md'
                extraClass='header-alignment'
            />
        </div>
    );
};

export default TemplateSelection;
