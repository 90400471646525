import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './App.scss';
import store, { persistor } from '../store';
import ErrorBoundary from './components/errorboundry/errorBoundary';
import AppRoutes from './routes';
import { setNonce } from '@nexus/core/loader';
setNonce('07062024');

const App = () => {
    return (
        <React.StrictMode>
            <ErrorBoundary>
                <div data-testid='nexus-app'>
                    <Provider store={store}>
                        <PersistGate persistor={persistor}>
                            <AppRoutes />
                        </PersistGate>
                    </Provider>
                </div>
            </ErrorBoundary>
        </React.StrictMode>
    );
};

export default App;
