import './input.scss';

import { NexusInput } from '@nexus/react';
import React from 'react';

interface Props {
    type?: 'date' | 'email' | 'number' | 'password' | 'tel' | 'text';
    initialValue?: string;
    placeholder?: string;
    minLength?: number;
    maxLength?: number;
    disabled?: boolean;
    autocomplete?: 'off';
    readonly?: boolean;
    required?: boolean;
    min?: string;
    max?: string;
    id?: string;
    onChange?: (event: any) => void;
    extraClass?: string;
    dataID?: any;
    children?: React.ReactNode;
}

const InputComponent = ({
    type,
    initialValue,
    placeholder,
    minLength,
    maxLength,
    disabled,
    readonly,
    autocomplete,
    min,
    max,
    id,
    required,
    extraClass,
    onChange,
    dataID,
    children,
}: Props) => {
    return (
        <NexusInput
            data-testid={placeholder ? placeholder : dataID}
            value={initialValue}
            type={type}
            placeholder={placeholder}
            minLength={minLength}
            maxLength={maxLength}
            disabled={disabled}
            readonly={readonly}
            autocomplete={autocomplete}
            onInput={onChange}
            min={min}
            max={max}
            id={id}
            required={required}
            className={extraClass}
        >
            {children}
        </NexusInput>
    );
};

export default InputComponent;
