import './testSessionHome.scss';

import { NexusCard, NexusCardBody } from '@nexus/react';
import React, { useEffect } from 'react';
import { resetAll as resetTestTemplate } from 'app/views/testTemplate/redux/testTemplateReducer';
import ButtonComponent from 'app/components/nds/button/button';
import CountInfo from './countInfo';
import { Create } from 'constants/constants';
import TestSessionTable from './testSessionTable';
import { stepperRoutes } from 'constants/stepperConstants';
import { useAppDispatch } from 'store';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateUserFlow, resetAll, updateActiveStep } from '../redux/testSessionReducer';

function TestSessionHome() {
    const [t] = useTranslation('lang');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleCreateSession = () => {
        dispatch(updateUserFlow(Create));
        dispatch(updateActiveStep(stepperRoutes.step_1));
        navigate('/test-session/session');
    };
    useEffect(() => {
        dispatch(resetAll({}));
        dispatch(resetTestTemplate({}));
    }, []);
    return (
        <div data-testid='test-session-home'>
            <section className='nexus-container'>
                <div className='nexus-col-lg-2'>
                    <p className='nexus-h4 margin-top-4'>{t('testSessionDetails.runTest')}</p>
                </div>
                <div className='nexus-row handle-content'>
                    <CountInfo />
                    <div className='nexus-col-lg-offset-4 nexus-col-md-2 nexus-col-xs-2 nexus-col-lg-2 nexus-end-md nexus-end-xs nexus-mt-1'>
                        <ButtonComponent
                            type={['nexus-btn-large nexus-btn-primary nexus-mt-4']}
                            label={t('home.newTestSession')}
                            click={handleCreateSession}
                            labelStyle='session-btn'
                        />
                    </div>
                </div>
            </section>
            <section className='nexus-container'>
                <div data-testid='home-page-wrapper'>
                    <NexusCard className='nexus-px-2 nexus-py-1 nexus-card-border-0'>
                        <NexusCardBody>
                            <TestSessionTable />
                        </NexusCardBody>
                    </NexusCard>
                </div>
            </section>
        </div>
    );
}

export default TestSessionHome;
