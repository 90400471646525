import './testTemplateHome.scss';

import { NexusCard, NexusCardBody } from '@nexus/react';

import CreateTemplate from './createTemplate';
import TestTemplateTable from './testTemplateTable';
import { resetAll } from '../redux/testTemplateReducer';
import { resetAll as resetTestSession } from 'app/views/testSession/redux/testSessionReducer';
import { useAppDispatch } from 'store';
import { useEffect } from 'react';

const TestTemplateHome = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(resetAll({}));
        dispatch(resetTestSession({}));
    }, []);
    return (
        <>
            <div className='test-designer-page-styles' data-testid='test-template-home'>
                <section className='nexus-container'>
                    <div data-testid='home-page-wrapper'>
                        <CreateTemplate />
                    </div>
                </section>
                <section className='nexus-container'>
                    <div data-testid='home-page-wrapper'>
                        <NexusCard className='nexus-px-2 nexus-py-1 nexus-card-border-0'>
                            <NexusCardBody>
                                <TestTemplateTable />
                            </NexusCardBody>
                        </NexusCard>
                    </div>
                </section>
            </div>
        </>
    );
};
export default TestTemplateHome;
