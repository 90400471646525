import { NexusAccordion, NexusAccordionTrigger, NexusAccordionContent, NexusIcon } from '@nexus/react';
import React from 'react';
import './accordion.scss';
interface Props {
    accordionContent: {
        isOpen: boolean;
        title: string;
    };
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    align?: 'start' | 'center' | 'end';
    children?: React.ReactNode;
    icon?: {
        icon: string;
        iconClass?: string;
        iconSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    };
    showIcon?: boolean;
    iconStyle?: string;
    handleToggle?: () => void;
    dataSource?: string;
}

const AccordionComponent: React.FC<Props> = ({
    accordionContent,
    size,
    align,
    children,
    icon,
    showIcon,
    iconStyle,
    handleToggle,
    dataSource,
}) => {
    const getIconDisplay = () => {
        const iconStyle = showIcon ? 'show-icon' : 'hide-icon';
        return iconStyle;
    };
    return (
        <>
            <NexusAccordion
                open={accordionContent.isOpen}
                onToggleEvent={() => handleToggle && handleToggle()}
                size={size}
                data-testid='nexus-accordion'
                className='nexus-mb-2'
            >
                <NexusAccordionTrigger align={align || 'start'}>
                    <div className={iconStyle}>
                        <h1 className='nexus-body'>{accordionContent.title}</h1>
                        <div className={getIconDisplay()}>
                            <NexusIcon
                                src={icon?.icon}
                                className={icon?.iconClass}
                                size={icon?.iconSize ? icon?.iconSize : 'lg'}
                            />
                            {dataSource && <div>{dataSource}</div>}
                        </div>
                    </div>
                </NexusAccordionTrigger>
                <NexusAccordionContent>{children}</NexusAccordionContent>
            </NexusAccordion>
        </>
    );
};

export default AccordionComponent;
