import React, { useEffect, useRef, useState } from 'react';
import { getProvider, logout, loginRequest, tokenRequest } from './adb2cAuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAuthToken,
    setAuthToken,
    setAuthUser,
    setInitialState,
    selectIsAuthTokenExpired,
    selectIsLogoutRequested,
} from '../authSlice';
import { AppDispatch, RootState } from '../../store/index';

// Component Helpers

interface InputProps {
    children: React.ReactNode;
    store?: RootState;
    doLogout?: boolean;
}

/**
 * Authenticate user by redirecting to ADB2C screen.
 */
const authenticate = (adb2cProvider: any, dispatch: AppDispatch, accessTokenRef: any) => {
    /**
     * ADB2C process will call this after authentication. Extracts id token information and
     * requests acessToken
     */
    const authRedirectCallBack = (error: { [id: string]: string }, response: { [id: string]: string }) => {
        if (error) {
            // may want to force logout
            //return dispatch(setError(error));
        }

        if (response.tokenType === 'id_token') {
            if (adb2cProvider.getAccount()) {
                init(adb2cProvider, dispatch, accessTokenRef);
            }
        } else {
            // shouldn't reach this
            //  dispatch(setWarning('Received unexpected token type'));
        }
    };

    adb2cProvider.handleRedirectCallback(authRedirectCallBack);

    if (!adb2cProvider.getAccount() && !adb2cProvider.getLoginInProgress()) {
        adb2cProvider.loginRedirect({ scopes: loginRequest.scopes });
    } else {
        init(adb2cProvider, dispatch, accessTokenRef);
    }
};

/**
 * Get the access token and set within the provided ref
 * @param {} adb2cProvider
 * @param {*} dispatch
 * @param {*} accessTokenRef
 */
const getAccessToken = async (adb2cProvider: any, dispatch: AppDispatch, accessTokenRef: any) => {
    try {
        // Per MSAL docs, acquireTokenSilent will look for a valid token in the cache, and if it is close to
        // expiring or does not exist, will automatically try to refresh it.
        const token = await adb2cProvider.acquireTokenSilent({
            scopes: tokenRequest.scopes,
        });

        accessTokenRef.current = token.accessToken;

        const tokenData = {
            accessToken: token.accessToken,
            tokenExpiration: new Date(token.expiresOn).getTime(),
        };

        dispatch(setAuthToken(tokenData));
    } catch (error: any) {
        if (error.name === 'InteractionRequiredAuthError') {
            try {
                const { token } = await adb2cProvider.acquireTokenRedirect({
                    scopes: tokenRequest.scopes,
                });

                accessTokenRef.current = token;
                dispatch(setAuthToken(token));
            } catch (redirectError) {
                // may want to force logout
                // dispatch(setError(redirectError));
            }
        } else {
            // may want to force logout
            // dispatch(setError(error));
        }
    }
};

/**
 * Initialize by extracting id token information and requesting an access token
 */
const init = (adb2cProvider: any, dispatch: AppDispatch, accessTokenRef: any) => {
    const { idToken } = adb2cProvider.getAccount();

    const name = idToken.name || `${idToken.given_name} ${idToken.family_name}`;
    const [email] = idToken.emails;
    dispatch(setAuthUser({ name, email }));
    getAccessToken(adb2cProvider, dispatch, accessTokenRef);
};

/**
 * ADB2C functional component
 * @param {} param0
 */
const ADB2CAuth = ({ children, doLogout }: InputProps) => {
    const [showChildren, setShowChildren] = useState(false);
    const dispatch = useDispatch();
    const adb2cProvider = getProvider();

    // Check store (redux) for the token, if not available the auth request will occur.
    // Could persist the token so refreshes don't force a refetch but that introduces potential
    // security risks
    const accessToken = useRef(useSelector(selectAuthToken));
    const isTokenExpired = useSelector(selectIsAuthTokenExpired);
    const isLogoutRequested = useSelector(selectIsLogoutRequested);
    const loggingOut = isLogoutRequested || doLogout;

    useEffect(() => {
        accessToken.current ? setShowChildren(true) : authenticate(adb2cProvider, dispatch, accessToken);
    }, [accessToken.current]);

    useEffect(() => {
        if (isTokenExpired) {
            getAccessToken(adb2cProvider, dispatch, accessToken);
        }
    }, [isTokenExpired]);

    useEffect(() => {
        if (loggingOut) {
            accessToken.current = null;
            setShowChildren(false);
            dispatch(setInitialState(''));
            logout();
        }
    }, [loggingOut]);

    return <>{showChildren && children}</>;
};

export default ADB2CAuth;
