import { NexusToast } from '@nexus/react';
import React from 'react';
import './toast.scss';
interface Props {
    autoClose?: number;
    closeable?: boolean;
    iconSrc?: string;
    position?: 'bottom' | 'top';
    show?: boolean;
    variant?: any;
    message?: string;

    closeToast?: () => void;
}

const ToastComponent: React.FC<Props> = (props: Props) => {
    return (
        <NexusToast
            data-testid='nexus-toast'
            autoClose={props.autoClose}
            closeable={true}
            show={props.show}
            variant={props.variant}
            key={props.variant}
            className={`nexus-mb-1 ${props.show ? 'show' : 'remove'}`}
            onCloseEvent={props.closeToast}
        >
            {props.message}
        </NexusToast>
    );
};

export default ToastComponent;
