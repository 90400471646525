export const IS_NULL = 'is null';
export const defaultOperators: any = {
    boolean: [
        { label: '==', value: '==' },
        { label: '!=', value: '!=' },
        { label: IS_NULL, value: IS_NULL },
    ],
    date: [
        { label: '==', value: '==' },
        { label: '!=', value: '!=' },
        { label: '<', value: '<' },
        { label: '>', value: '>' },
        { label: '<=', value: '<=' },
        { label: '>=', value: '>=' },
        { label: IS_NULL, value: IS_NULL },
    ],
    integer: [
        { label: '==', value: '==' },
        { label: '!=', value: '!=' },
        { label: '<', value: '<' },
        { label: '>', value: '>' },
        { label: '<=', value: '<=' },
        { label: '>=', value: '>=' },
        { label: IS_NULL, value: IS_NULL },
    ],
    number: [
        { label: '==', value: '==' },
        { label: '!=', value: '!=' },
        { label: '<', value: '<' },
        { label: '>', value: '>' },
        { label: '<=', value: '<=' },
        { label: '>=', value: '>=' },
        { label: IS_NULL, value: IS_NULL },
    ],
    string: [
        { label: '==', value: '==' },
        { label: '!=', value: '!=' },
        { label: IS_NULL, value: IS_NULL },
    ],
};

export const allOperators = ['==', '!=', 'IN', 'LIKE', '>', '<', '<=', '>=', 'CONTAINS', 'MATCHES'];
