import { NexusIcon, NexusCheckbox } from '@nexus/react';
import Expand from '@nexus/core/dist/assets/icons/action/ic_expand_more_24px.svg';
import { useEffect } from 'react';

interface CustomMultiSelectProps {
    dataArray: any[];
    setArray: any[];
    expand: boolean;
    setExpand: (expand: boolean) => void;
    handleAction: (e: any, item: any) => void;
    selectedMsg: string;
    selectMsg: string;
    name: string;
}

const CustomMultiSelect = (props: CustomMultiSelectProps) => {
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
    }, []);

    // add name-container to your component
    const handleClickOutside = (event: MouseEvent) => {
        const dropdown = document.querySelector(`.${props.name}-container`);
        if (dropdown && !dropdown.contains(event.target as Node)) {
            props.setExpand(false);
        }
    };

    return (
        <div className={`filter-${props?.name}`} data-testid={`filter-${props?.name}`}>
            <div className={`nexus-row ${props?.name}-select`} onClick={() => props?.setExpand(!props?.expand)}>
                <div className={`${props?.name}-placeholder`}>
                    {props.setArray?.length ? `(${props?.setArray?.length}) ${props?.selectedMsg}` : props?.selectMsg}
                </div>
                <NexusIcon className='filter-expand-icon' src={Expand} />
            </div>
            {props?.expand && (
                <>
                    <div className='options-container'>
                        {props.dataArray?.map((item: any) => {
                            return (
                                <div key={item?.id} className='nexus-row nexus-p-1 single-option'>
                                    <NexusCheckbox
                                        className='checkbox'
                                        onClick={(e: any) => props?.handleAction(e, item)}
                                        key={item.id}
                                        checked={props.setArray?.some((ds: any) => ds?.id === item?.id)}
                                    />
                                    <div>{item?.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
};

export default CustomMultiSelect;
