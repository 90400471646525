import React, { useState } from 'react';

import { NexusSelect } from '@nexus/react';
import { RiskControlDropdown } from 'constants/constants';
import { NexusSelectCustomEvent } from '@nexus/core';

interface InputProps {
    attrId?: string;
    delimiter?: string;
    disabled?: boolean;
    multiple?: boolean;
    required?: boolean;
    value?: string;
    classes?: string;
    type?: 'custom' | 'native';
    selectedValue?: string;

    options?:
        | {
              label: string;
              value: string;
              disabled?: boolean;
              id?: string;
          }[]
        | any;

    customOnChange?: (nextValue?: any) => void;
    selectedType?: string;
    disabledOption?: string;
    name?: string;
    key?: string | number;
    placeholder?: string;
}

const Select: React.FC<InputProps> = (props: InputProps) => {
    const [value, setValue] = useState(props.selectedValue ? props.selectedValue : '');
    const onChangeOpts = (value: string) => {
        setValue(value);
        if (props.customOnChange) {
            props.customOnChange(value);
        }
    };
    return (
        <NexusSelect
            type='custom'
            placeholder={props.placeholder}
            key={props.key}
            value={props.selectedType === RiskControlDropdown ? props.selectedValue : value}
            attrId={props.attrId}
            disabled={props.disabled}
            multiple={props.multiple}
            options={props?.options?.map((option: any) => option.label) || ['please select an option']}
            required={props.required}
            delimiter={props.delimiter}
            data-testid={`nexus-select${props.name ? '-' + props.name : ''}`}
            onTriggerSelection={(event: NexusSelectCustomEvent<string>) => {
                const selectedValue = props.options.find((option: any) => option.label === event.target.value);
                onChangeOpts(selectedValue?.value || null);
            }}
            className={props.classes || ''}
        ></NexusSelect>
    );
};

export default Select;
