import './recursiveStyle.scss';

import { memo, useEffect, useState } from 'react';

import DragDrop from './DragDrop';
import { Form } from 'react-final-form';
import GroupHeader from './groupHeader';
import RuleExpression from './RuleExpression';
import { defaultOperators } from './constants';
import { expressionGenerator } from './expressionBuilderHelper';
import { getRandomNumber } from 'utils/mathUtils';
import { isObjectEmpty } from 'services/services';

const ExpressionBuilderComponent = ({
    cloneButon,
    jsonExpression,
    onChange,
    disable,
    fields,
    enableDragDrop,
    validate,
    fieldOperators,
    enableLockButton,
    enableNot,
    disableTextField,
    errorMessage = '',
    caseSensitive = false,
}: any) => {
    const parentGroupId = getRandomNumber();
    const parentGroupDefinition = {
        category: 'group',
        id: jsonExpression?.id || parentGroupId,
        parentId: jsonExpression?.id || parentGroupId,
    };
    const [query, setQuery] = useState<any>([{ ...jsonExpression, ...parentGroupDefinition }]);
    const [initialRender, setInitialRender] = useState(true);
    interface Expression {
        id: number;
        parentId: number;
        field: string;
        operator: string;
        value: string | number;
        user_value: string | number;
        disable?: boolean;
    }
    interface Group {
        id: number;
        parentId: number;
        combinator: string;
        rules: Array<Group | Expression>;
        category: 'group';
        disable?: boolean;
        notOperator?: boolean;
    }

    const newExpression = {
        category: 'rule',
        field: fields && fields[0]?.label,
        operator: defaultOperators && defaultOperators[fields[0]?.type] && defaultOperators[fields[0]?.type][0]?.value,
        type: fields && fields[0]?.type,
        user_value: '',
    };

    const newNestestRules = {
        category: 'group',
        combinator: 'AND',
        rules: [],
    };

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
            return;
        }
        if (query && query.length > 0) {
            onChange(expressionGenerator(query[0]));
        } else {
            onChange(expressionGenerator(parentGroupDefinition));
        }
    }, [query, disable]);

    const handleSubmit = () => {
        //donothng
    };

    const addCondition = (parentId: number) => {
        const newQuery = [...query];
        const parent = findNodeById(parentId, newQuery);
        parent.rules.push({ ...newExpression, id: getRandomNumber(), parentId: parent.id });
        setQuery(newQuery);
    };

    const addGroup = (parentId: number) => {
        const newQuery = [...query];
        const parent = findNodeById(parentId, newQuery);
        parent.rules.push({ ...newNestestRules, id: getRandomNumber(), parentId: parent.id });
        setQuery(newQuery);
    };

    const removeNode = (nodeId: number, parentId: number) => {
        const newQuery = [...query];
        if (nodeId === parentId) {
            const parent = findNodeById(nodeId, newQuery);
            parent.rules = [];
        } else {
            const parent = findNodeById(parentId, newQuery);
            parent.rules = parent.rules.filter((node: Group | Expression) => node.id !== nodeId);
        }
        setQuery(newQuery);
    };

    const updateCondition = (nodeId: number, field: string, value: string | number | any) => {
        const newQuery = [...query];
        const node = findNodeById(nodeId, newQuery);
        node[field] = value;
        if (field === 'compare_with_field') {
            if (value) {
                delete node.user_value;
                delete node.value;
            } else {
                delete node.compare_with_field;
                delete node.compare_field_name;
            }
        }
        setQuery(newQuery);
    };

    const findNodeById = (nodeId: number, nodes: any[]): any => {
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            if (node.id === nodeId) {
                return node;
            } else if (node.rules) {
                const foundNode = findNodeById(nodeId, node.rules);
                if (foundNode) {
                    return foundNode;
                }
            }
        }
        return null;
    };
    const changeCloneNodeId = (node: any, parentId: number) => {
        node.id = getRandomNumber();
        node.parentId = parentId;
        if (node.category === 'group') {
            for (let rule of node.rules) {
                changeCloneNodeId(rule, node.id);
            }
        }
    };
    const onClone = (node: any) => {
        const newQuery: Group[] = [...query];
        const sourceNode = findNodeById(node.parentId, newQuery);
        const nodeIndex = sourceNode.rules.findIndex((item: Group | Expression) => item.id === node.id);
        let cloneNode = JSON.parse(JSON.stringify(node));
        changeCloneNodeId(cloneNode, cloneNode.parentId);
        sourceNode.rules.splice(nodeIndex, 0, cloneNode);
        setQuery([...newQuery]);
    };
    const handleDrop = (sourceId: number, targetId: number) => {
        if (sourceId === targetId) return;
        const newQuery: Group[] = [...query];
        const sourceNode = findNodeById(sourceId, newQuery);
        const sourceParentNode = findNodeById(sourceNode.parentId, newQuery);
        const targetNode = findNodeById(targetId, newQuery);
        const targetParentNode = findNodeById(targetNode.parentId, newQuery);
        const targetNodeIndex = targetParentNode.rules.findIndex((item: Group | Expression) => item.id === targetId);
        sourceParentNode.rules = sourceParentNode.rules.filter((item: Group | Expression) => item.id !== sourceId);

        if (targetNode.category === 'group') {
            sourceNode.parentId = targetNode.id;
            targetNode.rules.push(sourceNode);
        } else {
            sourceNode.parentId = targetParentNode.id;
            targetParentNode.rules.splice(targetNodeIndex, 0, sourceNode);
        }
        setQuery([...newQuery]);
    };

    const renderNode = (node: any, isParentDisabled: boolean) => {
        if (node.category === 'group') {
            const isParentNode = node.id === node.parentId;
            return (
                <div className='rule-group' key={node.id}>
                    <DragDrop
                        draggable={!isParentNode && enableDragDrop && !(isParentDisabled || node.disable)}
                        onDrop={handleDrop}
                        nodeId={node.id}
                    >
                        <GroupHeader
                            disable={isParentDisabled}
                            updateCondition={updateCondition}
                            addCondition={addCondition}
                            node={node}
                            onClone={onClone}
                            addGroup={addGroup}
                            removeNode={removeNode}
                            cloneButon={cloneButon}
                            enableLockButton={enableLockButton}
                            enableNot={enableNot}
                            disableField={disableTextField}
                        />
                        <div className='rule-group-body'>
                            {node.rules?.map((childNode: Group | Expression) => renderNode(childNode, node.disable))}
                        </div>
                    </DragDrop>
                </div>
            );
        } else {
            return (
                <div className={'rule'} key={node.id}>
                    <DragDrop
                        onDrop={handleDrop}
                        key={node.id}
                        nodeId={node.id}
                        draggable={enableDragDrop && !(isParentDisabled || node.disable)}
                    >
                        <RuleExpression
                            handleExpDropdown={updateCondition}
                            handleFieldVal={() => {}}
                            onDelete={() => removeNode(node.id, node.parentId)}
                            item={node}
                            key={node.id}
                            id={node.id}
                            columnsDef={fields}
                            disable={isParentDisabled}
                            validate={validate}
                            onClone={onClone}
                            cloneButon={cloneButon}
                            fieldOperators={fieldOperators}
                            enableLockButton={enableLockButton}
                            disableField={disableTextField}
                            caseSensitive={caseSensitive}
                            enableNot={enableNot}
                        />
                    </DragDrop>
                </div>
            );
        }
    };
    return (
        <div className={`query-builder-branches flex-container`}>
            {errorMessage && <div className='warning-manual-builder'>{errorMessage}</div>}
            <Form
                destroyOnUnregister={true}
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => {
                    return !isObjectEmpty(jsonExpression) && query ? (
                        <form onSubmit={handleSubmit}>
                            <>
                                {query.map((item: any) => {
                                    return renderNode(item, disable);
                                })}
                            </>
                        </form>
                    ) : (
                        <></>
                    );
                }}
            />
        </div>
    );
};

export default memo(ExpressionBuilderComponent);
