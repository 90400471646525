import { Configuration as ATConfiguration } from 'app/api/atAPI';
import { Configuration as NotificationConfiguration } from 'app/api/notificationsAPI';

export const atApi = `${process.env.REACT_APP_BASE_URL}/api/at`;
export const notificationApi = `${process.env.REACT_APP_BASE_URL}/api/ns`;
export const notificationStreamApi = `${process.env.REACT_APP_BASE_URL}/api/ns/notifications-stream`;

export const atApiConfig: ATConfiguration = {
    basePath: atApi,
    isJsonMime: function (): boolean {
        return true;
    },
};

export const notificationApiConfig: NotificationConfiguration = {
    basePath: notificationApi,
    isJsonMime: function (): boolean {
        return true;
    },
};
