import './card.scss';

import { NexusCard, NexusCardBody, NexusIcon } from '@nexus/react';

import React from 'react';

type Props = {
    imageSrc: string;
    status?: string;
    count?: string;
    details?: string;
    btnLabel?: string;
    bgcolor?: string;
    height?: string;
    iconBg?: string;
};

const CardComponent: React.FC<Props> = ({ imageSrc, status, count, bgcolor, height, iconBg }) => {
    const bgColor = () => {
        switch (iconBg) {
            case 'failed':
                return 'failedBg';
            case 'inProgress':
                return 'inProgressBg';
            case 'passed':
                return 'passedBg';
            default:
                return 'passedBg';
        }
    };
    return (
        <NexusCard data-testid='card' bg-color={bgcolor} height={height} className='test-card'>
            <div className={`icon-bg ${bgColor()}`}>
                <NexusIcon src={imageSrc} size='md' className='test-icon' />
            </div>
            <NexusCardBody>
                <div data-testid='test-status' className='test-status'>
                    {status}
                </div>
                <h2 data-testid='test-count' className='test-count'>
                    {count}
                </h2>
            </NexusCardBody>
        </NexusCard>
    );
};

export default CardComponent;
