import './confirmation.scss';

import { Complete, ConfirmationPage, Fail, InProgress, EXPRESSION, fileStatusFlag } from 'constants/constants';
import { NexusCard, NexusCardBody, NexusIcon, NexusNotification } from '@nexus/react';
import { createFileFromResponse } from 'services/services';
import { useAppDispatch } from 'store';
import { useEffect, useState } from 'react';

import ApprovedIcon from '@nexus/core/dist/assets/icons/action/ic_check_circle_24px.svg';
import ButtonComponent from 'app/components/nds/button/button';
import DownLoadIcon from '@nexus/core/dist/assets/icons/action/ic_download_24px.svg';
import EditIcon from '@nexus/core/dist/assets/icons/action/ic_edit_24px.svg';
import ModalComponent from 'app/components/nds/modal/modal';
import { getButtonIndex } from 'utils/generateButtonIndex';
import { getHomeUrl } from 'utils/redirectionUtils';
import { stepperRoutes } from 'constants/stepperConstants';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    getExpPreview,
    executeTestCase,
    fetchTestCaseExecutions,
    publishTestTemplate,
    getFile,
    updateActiveStep,
    getCannonicalModelById,
} from '../../redux/testTemplateReducer';
import { currentTestTemplate } from '../../redux/testTemplateSelectors';
import { useSelector } from 'react-redux';

const Confirmation = () => {
    const dispatch = useAppDispatch();
    const [t] = useTranslation('lang');
    const [disableRunButton, setDisableRunButton] = useState<boolean>(false);
    const [disablePublishButton, setDisablePublishButton] = useState<boolean>(true);
    const [downloadLink, setDownLoadLink] = useState<string>('');
    const [checkStatus, setCheckStatus] = useState<string>('');
    const [showConfirmationPopUp, setShowConfirmationPopUp] = useState<boolean>(false);

    const testTemplate = useSelector(currentTestTemplate);
    const {
        name: testTemplateName,
        draftStatus: activeStep,
        id: testTemplateId,
        canonicalModelId,
        selectedCanonicalModel,
        dataInputFile: uploadedDataSourceData,
        scriptConfiguration,
        executeError,
        testExecutionData,
    } = testTemplate;
    const { type, expression_string, user_selected_columns, execution_parameters, expression_object } =
        scriptConfiguration || {};

    const navigate = useNavigate();
    //Check if the file is uploaded or not and if status is ready
    useEffect(() => {
        if (uploadedDataSourceData?.status && uploadedDataSourceData?.status !== fileStatusFlag?.ready) {
            setDisableRunButton(true);
        }
    }, [uploadedDataSourceData?.status]);
    useEffect(() => {
        if (canonicalModelId && Object.keys(selectedCanonicalModel).length === 0) {
            dispatch(getCannonicalModelById(canonicalModelId));
        }
    }, [canonicalModelId]);
    useEffect(() => {
        if (type === EXPRESSION && canonicalModelId && (expression_string === '' || expression_string === undefined)) {
            const payload = {
                canonicalModelId: canonicalModelId,
                scriptConfiguration: {
                    execution_parameters: execution_parameters,
                    expression_object: expression_object,
                    type: EXPRESSION,
                    user_selected_columns: user_selected_columns,
                },
            };
            dispatch(getExpPreview(payload));
        }
    }, [type, canonicalModelId]);
    const handleRunButton = () => {
        setDisableRunButton(true);
        dispatch(executeTestCase(testTemplateId)).then((data: any) => {
            if (data?.payload?.status >= 200 && data?.payload?.status < 300) {
                setCheckStatus(InProgress);
                let count = 0;
                const intervalID = setInterval(() => {
                    if (
                        window.location.pathname.includes('/test-template/template') &&
                        activeStep === stepperRoutes.step_4
                    ) {
                        ++count;
                        dispatch(
                            fetchTestCaseExecutions({
                                page: 0,
                                size: 1,
                                sort: ['timestamp,desc'],
                                testCaseId: testTemplateId,
                            }),
                        ).then((response: any) => {
                            setDisableRunButton(true);
                            if (response && response.payload) {
                                if (response.payload.status === 500) {
                                    setCheckStatus(Fail);
                                    clearInterval(intervalID);
                                    setDisableRunButton(false);
                                    return;
                                }
                                const sessionData = response?.payload?.data?.content?.[0];
                                if (sessionData) {
                                    const { status, resultFileId } = sessionData;
                                    if (status === Complete) {
                                        setCheckStatus(Complete);
                                        setDownLoadLink(resultFileId);
                                        setDisableRunButton(true);
                                        setDisablePublishButton(false);
                                        clearInterval(intervalID);
                                    } else if (status === InProgress) {
                                        setCheckStatus(InProgress);
                                        setDisableRunButton(false);
                                    } else if (status === Fail) {
                                        setCheckStatus(Fail);
                                        setDisableRunButton(false);
                                        clearInterval(intervalID);
                                    }
                                }
                            }
                        });
                        if (count >= 30) {
                            setDisableRunButton(false);
                            setCheckStatus(Fail);
                            clearInterval(intervalID);
                            return;
                        }
                    } else {
                        return;
                    }
                }, 5000);
            } else {
                setCheckStatus(Fail);
            }
        });
    };
    const handlePublishButton = () => {
        const payload = {
            testTemplateRequest: {
                dataInputFile: uploadedDataSourceData?.id || null,
                draftStatus: stepperRoutes.step_4,
                isDraft: false,
            },
            id: testTemplateId,
        };

        dispatch(publishTestTemplate(payload)).then((data: any) => {
            if (data?.payload?.status > 199 && data?.payload?.status < 300) {
                setShowConfirmationPopUp(true);
            } else if (data?.payload?.status === 400) {
                setDisableRunButton(false);
            }
        });
    };

    const handleDownloadFile = () => {
        if (downloadLink?.length) {
            dispatch(getFile(downloadLink)).then((response: any) => {
                if (response && response.payload) {
                    createFileFromResponse(response);
                }
            });
        }
    };
    const navigateToStep = (routes: string) => {
        dispatch(updateActiveStep(routes));
    };
    const statusMessage = () => {
        switch (checkStatus) {
            case InProgress:
                return (
                    <div className='nexus-row'>
                        <div className='nexus-col-lg-10'>
                            {t('testDesigner.confirmation.inProgress')}
                            <img
                                src={process.env.PUBLIC_URL + '/loading-dots.gif'}
                                alt='Loading...'
                                className={'gif-align'}
                            />
                        </div>
                    </div>
                );
            case Fail:
                return `${t('testDesigner.confirmation.fail')} ${
                    executeError || testExecutionData?.content?.[0]?.errorMessage || ''
                }`;
            case Complete:
                return t('testDesigner.confirmation.complete');
            default:
                return '';
        }
    };

    const eqButtonList = [
        {
            buttonDisabled: disablePublishButton,
            buttonStyles: ['nexus-btn-primary nexus-rhythm-top-5'],
            clickButton: handlePublishButton,
            label: t('buttons.publish'),
        },
    ];
    const closeModal = () => {
        const home = getHomeUrl();
        navigate(home);
    };
    const confirmationPopUpData = (
        <>
            <div className={'icon-modal-pop-up'}>
                <NexusIcon src={ApprovedIcon} className={`nexus-ml-1 edit-approved-icon-color`} />
            </div>
            <div className={`nexus-subtitle icon-modal-pop-up`}>{t('dataSelectionTestDesigner.modal')}</div>
        </>
    );

    return (
        <>
            <div data-testid='confirmation-designer'>
                {showConfirmationPopUp && (
                    <ModalComponent
                        show={showConfirmationPopUp}
                        closeModal={closeModal}
                        children={confirmationPopUpData}
                        size='md'
                        type={ConfirmationPage}
                    />
                )}
                <NexusCard className='nexus-card-border-0'>
                    <NexusCardBody>
                        <div className='nexus-row'>
                            <div
                                className={
                                    'nexus-mt-2 nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4 nexus-col-xl-5 paragraph-border'
                                }
                            >
                                <div className='nexus-row'>
                                    <div className={`circle alignment-text`}>1</div>

                                    <div className='nexus-ml-1 nexus-subtitle'>
                                        {t('testDesigner.confirmation.reviewInputs')}
                                    </div>
                                </div>
                                <div className={`nexus-caption-copy nexus-mt-2 alignment-text-styles`}>
                                    {t('testDesigner.confirmation.reviewStatement')}
                                </div>
                                <div className={`nexus-ml-2 icon-placement`}>
                                    <div className={`nexus-subtitle nexus-mt-3 alignment-text`}>
                                        {t('testDesigner.confirmation.templateName')}
                                    </div>
                                </div>
                                <div
                                    className={`nexus-subtitle nexus-mt-2  temp-info text-color alignment-text-styles`}
                                >
                                    {testTemplateName}
                                </div>

                                <div className={`nexus-ml-2 icon-placement`}>
                                    <div className={`nexus-subtitle nexus-mt-3 alignment-text`}>
                                        {t('dataSelection.header1')}
                                    </div>
                                    <div className='nexus-mt-1'>
                                        <NexusIcon
                                            src={EditIcon}
                                            className={`nexus-ml-1 edit-icon-color`}
                                            data-testid='data-selection-icon'
                                            onClick={() => navigateToStep(stepperRoutes.step_2)}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`nexus-subtitle nexus-mt-2  temp-info text-color alignment-text-styles`}
                                >
                                    {uploadedDataSourceData?.status === fileStatusFlag.ready ? (
                                        <div className='content-placement'>
                                            <NexusNotification variant='success' />
                                            {uploadedDataSourceData?.name}
                                        </div>
                                    ) : (
                                        selectedCanonicalModel?.tableName
                                    )}
                                    {uploadedDataSourceData?.status === fileStatusFlag?.invalid && (
                                        <div className='content-placement'>
                                            <NexusNotification variant='error' className='nexus-ml-1' />
                                            {t('dataSelection.validationFailedmessage')}
                                        </div>
                                    )}
                                    {uploadedDataSourceData?.status &&
                                        uploadedDataSourceData?.status !== fileStatusFlag?.invalid &&
                                        uploadedDataSourceData?.status !== fileStatusFlag?.ready && (
                                            <div className='icon-placement nexus-mt-1 inValidation'>
                                                <div className='loader-styles nexus-ml-1' />
                                                <div className={'nexus-subtitle-sm nexus-ml-2 nexus-mt-2'}>
                                                    {t('dataSelection.validationInProgress')}
                                                </div>
                                            </div>
                                        )}
                                </div>
                                <div className={`nexus-ml-2 icon-placement`}>
                                    <div className={`nexus-subtitle nexus-mt-3 alignment-text`}>
                                        {t('testSessionDetails.expression')}
                                    </div>
                                    <div className='nexus-mt-1'>
                                        <NexusIcon
                                            src={EditIcon}
                                            className={`nexus-ml-1 edit-icon-color`}
                                            data-testid='expression-icon'
                                            onClick={() => navigateToStep(stepperRoutes.step_3)}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`nexus-subtitle nexus-mt-2  temp-info text-color alignment-text-styles`}
                                >
                                    {expression_string}
                                </div>
                            </div>
                            <div
                                className={`nexus-mt-2 nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-5 nexus-col-xl-4 paragraph-border`}
                            >
                                <div className='nexus-row'>
                                    <div className='nexus-ml-2 circle'>2</div>
                                    <div className='nexus-subtitle nexus-ml-2'>
                                        {t('testDesigner.confirmation.runTestFile')}
                                    </div>
                                </div>
                                <div className={`nexus-caption-copy nexus-mt-3 review-statement-styles`}>
                                    {t('testDesigner.confirmation.runTestStatement')}
                                </div>
                                <ButtonComponent
                                    type={['nexus-btn nexus-rhythm-top-5 run-button-styles']}
                                    label={t('buttons.runTest')}
                                    disabled={disableRunButton}
                                    click={handleRunButton}
                                />
                                <div
                                    className={`nexus-caption-copy nexus-mt-2 review-statement-styles ${
                                        checkStatus === Fail ? 'error-message' : ''
                                    }`}
                                    data-testid='check-status'
                                >
                                    {statusMessage()}
                                </div>

                                {checkStatus === Complete && (
                                    <div
                                        className={`nexus-ml-3 nexus-mt-1 nexus-row`}
                                        onClick={handleDownloadFile}
                                        data-testid='download-file-link'
                                    >
                                        <NexusIcon
                                            src={DownLoadIcon}
                                            className={`nexus-ml-2 edit-icon-color edit-download-color`}
                                            data-testid='download-icon'
                                            size='sm'
                                        ></NexusIcon>
                                    </div>
                                )}
                            </div>
                            <div className='nexus-mt-2 nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-3 nexus-col-xl-3'>
                                <div className='nexus-row'>
                                    <div className='nexus-ml-2 circle'>3</div>
                                    <div className='nexus-subtitle nexus-ml-2'>
                                        {t('testDesigner.confirmation.publishTest')}
                                    </div>
                                </div>
                                <div className={`nexus-caption-copy nexus-mt-3 review-statement-styles`}>
                                    {t('testDesigner.confirmation.publishStatement')}
                                </div>
                                <div className='nexus-flex-row-reverse'>
                                    {eqButtonList.map(({ label, clickButton, buttonDisabled, buttonStyles }, index) => {
                                        return (
                                            <ButtonComponent
                                                type={buttonStyles}
                                                label={label}
                                                disabled={buttonDisabled}
                                                click={clickButton}
                                                index={index}
                                                key={getButtonIndex(index)}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </NexusCardBody>
                </NexusCard>
            </div>
        </>
    );
};

export default Confirmation;
