import { AuthContainer, UnAuthContainer } from '../containers';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { userProfile } from 'constants/constants';
import LoginPage from 'app/views/login/loginPage';
import Logout from 'app/views/logout/logout';
import PageNotFound from 'app/views/404/404';
import ProfilePage from 'app/views/profile/profile';
import React from 'react';
import TestSessionHome from 'app/views/testSession/home';
import TestSession from 'app/views/testSession/session';
import TestSessionDetails from 'app/views/testSession/testSessionDetails';
import PowerbiDashboard from 'app/views/testSession/powerBIDashboard/powerbiDashboard';
import TestTemplateHome from 'app/views/testTemplate/home';
import TestTemplate from 'app/views/testTemplate/template';
const { Analyst, TestDesigner } = userProfile;
const routes = [
    {
        auth: true,
        element: <TestSessionHome />,
        path: '/test-session',
        roles: [Analyst, TestDesigner],
    },
    {
        auth: true,
        element: <TestTemplateHome />,
        path: '/test-template',
        roles: [TestDesigner],
    },
    {
        auth: true,
        element: <TestSession />,
        path: '/test-session/session/:id',
        roles: [Analyst, TestDesigner],
    },
    {
        auth: true,
        element: <TestSession />,
        path: '/test-session/session',
        roles: [Analyst, TestDesigner],
    },
    {
        auth: true,
        element: <TestTemplate />,
        path: '/test-template/template/:id',
        roles: [TestDesigner],
    },
    {
        auth: true,
        element: <TestTemplate />,
        path: '/test-template/template',
        roles: [TestDesigner],
    },
    {
        auth: true,
        element: <TestSessionDetails />,
        path: '/test-session-details/:id',
        roles: [Analyst, TestDesigner],
    },
    {
        auth: true,
        element: <ProfilePage />,
        path: '/profile',
        roles: [Analyst, TestDesigner],
    },
    {
        auth: true,
        element: <PowerbiDashboard />,
        path: '/session/report',
        roles: [Analyst, TestDesigner],
    },
    {
        auth: false,
        element: <LoginPage />,
        path: '/',
        roles: [Analyst, TestDesigner],
    },
];
const AppRoutes: React.FC = () => {
    return (
        <>
            <Router>
                <Routes>
                    {routes.map((route) => {
                        if (route.auth) {
                            return (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={<AuthContainer roles={route.roles}>{route.element}</AuthContainer>}
                                />
                            );
                        } else {
                            return (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={<UnAuthContainer>{route.element}</UnAuthContainer>}
                                />
                            );
                        }
                    })}
                    <Route path='/logout' element={<Logout />} />
                    <Route
                        path='*'
                        element={
                            <AuthContainer>
                                <PageNotFound />
                            </AuthContainer>
                        }
                    />
                </Routes>
            </Router>
        </>
    );
};

export default AppRoutes;
