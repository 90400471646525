import CustomTable from 'app/components/nds/customTable/customTable';
import ModalComponent from 'app/components/nds/modal/modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { currentTestSession } from '../../redux/testSessionSelectors';
import { useSelector } from 'react-redux';
import DeleteIcon from '@nexus/core/dist/assets/icons/action/ic_delete_24px.svg';
import { NexusIcon } from '@nexus/react';
import { useAppDispatch } from 'store';
import { updateCurrentTestSession } from '../../redux/testSessionReducer';
import './templateSelection.scss';
import ButtonComponent from 'app/components/nds/button/button';

const ReviewTemplatesModal = ({ showReviewModal, setShowReviewModal }: any) => {
    const [t] = useTranslation('lang');
    const testSession = useSelector(currentTestSession);
    const { selectedTestCases, testSessionTestCases } = testSession;
    const dispatch = useAppDispatch();

    const reviewTableHeader = [
        {
            field: 'name',
            id: 'name',
            isSortable: true,
            label: t('templateSelection.testTemplate'),
            minWidth: 150,
        },
        {
            field: 'description',
            id: 'description',
            isSortable: true,
            label: t('templateSelection.testDescription'),
            minWidth: 220,
        },
        {
            field: 'sector',
            id: 'sector',
            isSortable: true,
            label: t('templateSelection.sector'),
            minWidth: 80,
        },
        {
            field: 'dataSource',
            id: 'dataSource',
            isSortable: true,
            label: t('templateSelection.dataSource'),
            minWidth: 80,
        },
        {
            field: 'delete',
            id: 'delete',
            isSortable: false,
            label: '',
            minWidth: 10,
        },
    ];

    const deleteSelectedTemplate = (id: string) => {
        dispatch(
            updateCurrentTestSession({
                selectedTestCases: selectedTestCases?.filter((data: any) => data?.testCaseId !== id),
                testSessionTestCases: testSessionTestCases?.filter((data: any) => data?.testCaseId !== id),
            }),
        );
    };

    let rowData: Array<any> =
        selectedTestCases !== undefined
            ? selectedTestCases?.map((data: any) => {
                  return {
                      dataSource: data?.canonicalModel?.name,
                      delete: (
                          <div>
                              <NexusIcon
                                  src={DeleteIcon}
                                  size='lg'
                                  onClick={() => deleteSelectedTemplate(data?.testCaseId)}
                              />
                          </div>
                      ),
                      description: data?.testCaseDescription,
                      name: data?.testCaseName,
                      sector: data?.sector?.name,
                  };
              })
            : [];

    const modalHeader = (
        <div>
            <div className='review-header'>{t('templateSelection.reviewSelections')}</div>
            <div className='review-subHeader'>{t('templateSelection.reviewStatement')}</div>
        </div>
    );

    return (
        <div className='review-container'>
            <ModalComponent
                show={showReviewModal}
                closeModal={() => setShowReviewModal(false)}
                children={
                    <>
                        <CustomTable
                            columnsDef={reviewTableHeader}
                            noDataMessage={t('norecordAvailable')}
                            data={rowData}
                        />
                        <ButtonComponent
                            type={['nexus-btn-primary nexus-rhythm-top-3']}
                            label={t('buttons.close')}
                            click={() => setShowReviewModal(false)}
                            extraClass='align-right'
                        />
                    </>
                }
                header={modalHeader}
                size='lg'
            />
        </div>
    );
};

export default ReviewTemplatesModal;
