import './testSessionTable.scss';

import {
    ASC,
    DESC,
    Update,
    Status,
    DELETE_MODAL_TYPE,
    homePageData,
    completeBadge,
    inProgress,
    Fail,
    draft,
} from 'constants/constants';
import { NexusDropdown, NexusIcon, NexusOption, NexusCheckbox } from '@nexus/react';
import React, { useEffect, useState } from 'react';

import BadgeComponent from 'app/components/nds/badge/badge';
import ButtonComponent from 'app/components/nds/button/button';
import CustomTable from 'app/components/nds/customTable/customTable';
import ModalComponent from 'app/components/nds/modal/modal';
import NavigationIcMoreVert24px from '@nexus/core/dist/assets/icons/action/ic_more_vert_24px.svg';
import TableColumnFilter from 'app/components/tableColumnFilter/TableColumnFilter';
import ViewReportIcon from '@nexus/core/dist/assets/icons/action/ic_visibility_24px.svg';
import { formatDateSelected, getDropdownValues } from 'services/services';
import { getButtonIndex } from 'utils/generateButtonIndex';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { disableDeleteSelected, isMainCheckboxSelected } from 'utils/actionUtils';
import { TestSessionStatus } from 'app/api/atAPI';
import {
    getTestSessionTableData,
    getTestSession,
    deleteTestSession,
    deleteSelectedSession,
    updateCheckedItems,
    updateUserFlow,
} from '../redux/testSessionReducer';
import { testSessionData, checkedItems } from '../redux/testSessionSelectors';

function TestSessionTable() {
    const [t] = useTranslation('lang');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //modal states
    const [testSessionTobeDeleted, setTestSessionTobeDeleted] = useState<any>({});
    //filter states
    const [searchText, setSearchText] = useState<string>('');
    const [dateFrom, setDateFrom] = useState<string | null>();
    const [dateTo, setDateTo] = useState<string | null>();
    const [status, setStatus] = useState<TestSessionStatus>(Status.Published);
    const { totalElements, content: testSessionTableData } = useSelector(testSessionData);
    const checkedSessions = useSelector(checkedItems);
    //pagination states
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    //sort states
    const [sortCol, setSortCol] = useState<string>('lastRun');
    const [sort, setSort] = useState<string>(DESC);
    //delete modal states
    const [deleteModalData, setDeleteModalData] = useState<{
        header: string;
        open: boolean;
        type: DELETE_MODAL_TYPE;
        bodyData: any;
    }>({
        bodyData: '',
        header: '',
        open: false,
        type: DELETE_MODAL_TYPE.DELETE,
    });
    const tableData = testSessionTableData
        ? testSessionTableData?.map((data: any) => {
              let badgeColor: string = '';
              let variant: any = '';
              if (data?.status) {
                  switch (data.status) {
                      case Fail:
                          badgeColor = 'fail-status';
                          variant = 'danger';
                          break;
                      case completeBadge:
                          badgeColor = 'complete-status';
                          variant = 'success';
                          break;
                      case inProgress:
                          badgeColor = 'in-progress-status';
                          variant = 'warning';
                          break;
                  }
              }
              return {
                  action: (
                      <NexusDropdown
                          // show={clickedDropdown[index] && toggleDropDown}
                          placement={'right'}
                          dropdown-type={'basic'}
                          height={150}
                          width={180}
                          className={`custom-table-dropdown hand-icon`}
                          data-testid='nexus-dropdown'
                      >
                          <NexusIcon
                              // onClick={(event: any) => openHandler(event, index)}
                              slot={'trigerer'}
                              id={'iconId'}
                              src={NavigationIcMoreVert24px}
                              data-testid='view-dropdown-icon'
                              role='dropdown-icon'
                          ></NexusIcon>

                          <NexusOption data-testid='edit-session-icon' onClick={() => editSessionPopUp(data)}>
                              {t('home.editSession')}
                          </NexusOption>
                          <NexusOption data-testid='delete-session-icon' onClick={() => deleteSessionPopUp(data)}>
                              {t('home.deleteSession')}
                          </NexusOption>
                      </NexusDropdown>
                  ),
                  checkBoxBtn: (
                      <>
                          <div className={'nexus-row checkbox-position'}>
                              <NexusCheckbox
                                  data-testid={`rowCheckBox`}
                                  onClick={(event) => handleSingleCheckBox(event, data?.testSessionId)}
                                  checked={checkedSessions?.includes(data?.testSessionId)}
                                  className='checkbox-styles'
                              ></NexusCheckbox>
                              {data?.col}
                          </div>
                      </>
                  ),
                  id: data?.testSessionId,
                  lastRun: data?.testSessionLastRun?.substring(0, 10),
                  schedulerFrequency: data?.schedulerFrequency,
                  status: data?.status && (
                      <BadgeComponent color={badgeColor} variant={variant} label={data?.status}></BadgeComponent>
                  ),
                  testCaseName: data?.testSessionTestCases?.length,
                  testCreationStatus: data?.testSessionIsDraft && (
                      <BadgeComponent color='draft-status' label={draft}></BadgeComponent>
                  ),
                  testSessionArea: data?.testSessionArea,
                  testSessionName: data?.testSessionName,
                  view: data?.testSessionIsDraft === false && (
                      <div onClick={() => handleViewTestInstanceDetails(data)} className={`hand-icon`}>
                          <NexusIcon src={ViewReportIcon} data-testid='view-report-icon'></NexusIcon>
                      </div>
                  ),
              };
          })
        : [];
    //useEffect to update table data
    useEffect(() => {
        if ((dateFrom && dateTo) || (!dateFrom && !dateTo) || searchText) getLatestTableData();
    }, [searchText, dateFrom, dateTo, currentPage, pageSize, sort, sortCol, status]);
    const getLatestTableData = async () => {
        const payload = {
            lastRunDateFrom: dateFrom && dateTo ? formatDateSelected(dateFrom) : null,
            lastRunDateTo: dateFrom && dateTo ? formatDateSelected(dateTo) : null,
            page: currentPage - 1 < 0 ? 0 : currentPage - 1,
            search: searchText || '',
            size: pageSize,
            sort: sort ? [sortCol + ',' + sort] : undefined,
            status: status,
        };
        await dispatch(getTestSessionTableData(payload));
    };

    const testSessionTableHeaders = [
        {
            enableCheckBox: true,
            field: 'checkBoxBtn',
            id: 'checkBoxBtn',
            isSortable: false,
            label: '',
            minWidth: 10,
        },
        {
            field: 'testSessionName',
            id: 'testSessionName',
            isSortable: true,
            label: t('home.testSession'),
            minWidth: 50,
        },
        {
            field: 'testCreationStatus',
            id: 'testCreationStatus',
            isSortable: false,
            label: '',
            minWidth: 30,
        },
        {
            field: 'testSessionArea',
            id: 'testSessionArea',
            isSortable: true,
            label: t('home.testArea'),
            minWidth: 50,
        },
        {
            field: 'testCaseName',
            id: 'testCaseName',
            isSortable: false,
            label: t('home.testTemplates'),
            minWidth: 50,
        },
        {
            field: 'schedulerFrequency',
            id: 'schedulerFrequency',
            isSortable: true,
            label: t('home.testCadence'),
            minWidth: 50,
        },
        {
            field: 'lastRun',
            id: 'lastRun',
            isSortable: true,
            label: t('home.lastRun'),
            minWidth: 50,
        },
        {
            field: 'status',
            id: 'status',
            isSortable: false,
            label: t('home.executionStatus'),
            minWidth: 60,
        },
        {
            field: 'view',
            id: 'view',
            isSortable: false,
            label: t('home.view'),
            minWidth: 7,
        },
        {
            field: 'action',
            id: 'action',
            isSortable: false,
            label: '',
            minWidth: 2,
        },
    ];
    const handleSingleCheckBox = (event: any, testSessionId: number) => {
        const checked = event.target.checked;
        if (checked === undefined) return;
        const selectedCol = tableData?.find((obj: any) => obj?.id === testSessionId);
        const isAlreadySelected = checkedSessions?.find((id: any) => id === testSessionId);
        if (checked) {
            if (selectedCol && !isAlreadySelected) {
                dispatch(updateCheckedItems([...checkedSessions, selectedCol.id]));
            }
        } else {
            if (selectedCol && isAlreadySelected) {
                const filteredArray = checkedSessions?.filter((id: any) => id !== testSessionId).map((id: any) => id);
                dispatch(updateCheckedItems(filteredArray));
            }
        }
    };
    const handleAllCheckBox = (event: any) => {
        const checked = event.target.checked;
        if (checked === undefined) return;
        if (checked) {
            const allCases = tableData.map((obj: any) => obj?.id);
            dispatch(updateCheckedItems([...checkedSessions, ...allCases]));
        } else {
            dispatch(updateCheckedItems([]));
        }
    };
    const handleDeleteSelected = async () => {
        setDeleteModalData({
            bodyData: <div className='nexus-body-sm'>{t('home.deleteSessionText')}</div>,
            header: t('home.deleteSessionHeader'),
            open: true,
            type: DELETE_MODAL_TYPE.BULK_DELETE,
        });
    };
    const editSessionPopUp = async (data: any) => {
        dispatch(updateUserFlow(Update));
        navigate(`/test-session/session/${data?.testSessionId}`);
    };
    const handleViewTestInstanceDetails = async (data: any) => {
        await dispatch(getTestSession(data?.testSessionId));
        navigate(`/test-session-details/${data?.testSessionId}`);
    };
    const deleteSessionPopUp = (data: any) => {
        setTestSessionTobeDeleted(data);
        setDeleteModalData({
            bodyData: (
                <div className='nexus-body-sm'>
                    {t('home.modalBody')}
                    {':'}
                    <span className='text-bold'>{' ' + data?.testSessionName + '?'}</span>
                </div>
            ),
            header: t('home.deleteSession'),
            open: true,
            type: DELETE_MODAL_TYPE.DELETE,
        });
        return data;
    };
    const handleYesButton = async () => {
        if (deleteModalData?.type === DELETE_MODAL_TYPE.DELETE) {
            await dispatch(deleteTestSession(testSessionTobeDeleted?.testSessionId));
            setTestSessionTobeDeleted({});
        }
        if (deleteModalData?.type === DELETE_MODAL_TYPE.BULK_DELETE) {
            await dispatch(deleteSelectedSession(checkedSessions));
            dispatch(updateCheckedItems([]));
        }
        setDeleteModalData({ bodyData: '', header: '', open: false, type: DELETE_MODAL_TYPE.DELETE });
        setCurrentPage(1);
        await getLatestTableData();
    };
    const handleNoButton = () => {
        if (deleteModalData?.type === DELETE_MODAL_TYPE.DELETE) {
            setTestSessionTobeDeleted({});
        }
        if (deleteModalData?.type === DELETE_MODAL_TYPE.BULK_DELETE) {
            dispatch(updateCheckedItems([]));
        }
        setDeleteModalData({ bodyData: '', header: '', open: false, type: DELETE_MODAL_TYPE.DELETE });
    };
    const eqButtonList = [
        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn nexus-rhythm-top-5'],
            clickButton: handleNoButton,
            label: t('buttons.No'),
        },

        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn-primary nexus-rhythm-top-5 nexus-mx-3'],
            clickButton: handleYesButton,
            label: t('buttons.Yes'),
        },
    ];
    //Delete Modal Footer Data
    const deleteModalFooterData = (
        <div className={`nexus-mt-2 align-delete-modal-btn`}>
            {eqButtonList?.map((data: any, _index: any) => {
                return (
                    <ButtonComponent
                        type={data?.buttonStyles}
                        label={data?.label}
                        disabled={data?.buttonDisabled}
                        click={data?.clickButton}
                        key={getButtonIndex(_index)}
                    />
                );
            })}
        </div>
    );
    let searchTimeout: NodeJS.Timeout | null = null;

    const handleSearchText = (event: React.FormEvent<HTMLNexusInputElement>) => {
        const target = event.target as HTMLNexusTextareaElement;
        const searchStr = target.value.trim();

        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        // debouncing added
        searchTimeout = setTimeout(() => {
            setSearchText(searchStr);
            dispatch(updateCheckedItems([]));
        }, 500);
    };

    const handleDateFromFilter = (date: string) => {
        setDateFrom(date);
    };

    const handleDateToFilter = (date: string) => {
        setDateTo(date);
        dispatch(updateCheckedItems([]));
    };

    const handleStatus = (status: TestSessionStatus) => {
        setStatus(status);
        setCurrentPage(0);
    };

    const statusDropdown = () => {
        const values = Object.values(Status).map((value: any) => ({ name: value, id: value }));
        return getDropdownValues(values);
    };

    const handleClearAll = () => {
        setSearchText('');
        setDateFrom(null);
        setDateTo(null);
        setStatus(Status.Published);
        setCurrentPage(0);
        dispatch(updateCheckedItems([]));
    };
    const paginationHandler = (currentPage: number, currentPageSize: number) => {
        setPageSize(currentPageSize);
        setCurrentPage(currentPage);
    };
    const handleSort = (label: string, field: string) => {
        setSortCol(field);
        if (sortCol === field) {
            setSort(sort === DESC ? ASC : DESC);
        } else {
            setSort(ASC);
        }
    };
    return (
        <div data-testid='test-session-table'>
            <TableColumnFilter
                showDeleteButton={tableData.length > 0}
                handleSearchText={handleSearchText}
                handleDateFromFilter={handleDateFromFilter}
                handleDateToFilter={handleDateToFilter}
                handleClearAll={handleClearAll}
                testFrom={dateFrom}
                testTo={dateTo}
                searchTextValue={searchText}
                handleDelete={handleDeleteSelected}
                disableDelete={disableDeleteSelected(checkedSessions)}
                status={status}
                handleStatus={handleStatus}
                statusOptions={statusDropdown()}
            />
            <ModalComponent
                show={deleteModalData?.open}
                closeModal={handleNoButton}
                children={deleteModalData?.bodyData}
                size='md'
                header={deleteModalData?.header}
                type={homePageData}
                extraClass='header-title-styles'
                footerContent={deleteModalFooterData}
            />
            <CustomTable
                pagination={true}
                columnsDef={testSessionTableHeaders}
                data={tableData || null}
                pageSizeCustom={pageSize}
                max={Math.ceil(totalElements / pageSize)}
                paginationFunc={paginationHandler}
                handleSort={handleSort}
                elementLabel={'test'}
                sortIcon={sort}
                sortCol={testSessionTableHeaders.map((e: any) => e?.field).indexOf(sortCol)}
                currentPageNo={currentPage}
                noDataMessage={t('norecordAvailable')}
                handleAllCheckBox={handleAllCheckBox}
                selectAllCheckBox={isMainCheckboxSelected(tableData, checkedSessions)}
            />
        </div>
    );
}

export default TestSessionTable;
