import { ASC, DESC } from 'constants/constants';

import CustomTable from 'app/components/nds/customTable/customTable';
import { NexusCheckbox } from '@nexus/react';
import styles from './dataSelection.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IDataSelectionTable {
    rows?: any;
    handleSingleCheckBox?: any;
    handleAllCheckBox?: any;
    checkedItems?: any;
}
const DataSelectionTable = ({ handleSingleCheckBox, rows, handleAllCheckBox, checkedItems }: IDataSelectionTable) => {
    const [t] = useTranslation('lang');
    const [sortCol, setSortCol] = useState<number>(0);
    const [sortValue, setSortValue] = useState<any>();
    const [fieldValue, setFieldValue] = useState<string>('');
    let dataSelectionTableHeader = [
        {
            enableCheckBox: true,
            field: 'checkBoxBtn',
            id: 'checkBoxBtn',
            isSortable: false,
            label: t('dataSelectionTestDesigner.availableCol'),
            minWidth: 220,
        },

        {
            field: 'dataType',
            id: 'dataType',
            isSortable: true,
            label: t('dataSelectionTestDesigner.dataType'),
            minWidth: 220,
            sortAscending: (firstValue: any, secondValue: any, sortField: string) =>
                firstValue[sortField] > secondValue[sortField] ? 1 : -1,
        },
        {
            field: 'sampleData',
            id: 'sampleData',
            isSortable: true,
            label: t('dataSelectionTestDesigner.sample'),
            minWidth: 350,
        },
    ];

    let rowData: Array<any> =
        rows !== undefined
            ? rows?.map((data: any) => {
                  return {
                      checkBoxBtn: (
                          <>
                              <div className={` nexus-row ${styles['checkbox-position']}`}>
                                  <NexusCheckbox
                                      data-testid={`rowCheckBox`}
                                      onClick={(event) => handleSingleCheckBox(event, data?.col)}
                                      checked={checkedItems?.includes(data?.col)}
                                      className='checkbox-styles'
                                  ></NexusCheckbox>
                                  {data?.col}
                              </div>
                          </>
                      ),

                      dataType:
                          data?.dataType?.charAt(0)?.toUpperCase() +
                          data?.dataType?.slice(1) +
                          (data?.format && ' (' + data?.format + ')'),
                      sampleData: data?.sampleData,
                  };
              })
            : [];

    const handleSort = (label: string, field: string, index: number) => {
        setSortCol(index);
        setFieldValue(field);
        if (index !== sortCol) {
            setSortValue(ASC);
        } else {
            if (sortValue === undefined) {
                setSortValue(ASC);
            } else if (sortValue === ASC) {
                setSortValue(DESC);
            } else if (sortValue === DESC) {
                setSortValue(undefined);
            }
        }
    };

    let sortedRowData: any = [...rowData];
    if (sortedRowData !== null) {
        sortedRowData.sort((firstElement: any, secondElement: any) => {
            if (firstElement[fieldValue] < secondElement[fieldValue]) {
                return sortValue === ASC ? -1 : 1;
            }
            if (firstElement[fieldValue] > secondElement[fieldValue]) {
                return sortValue === DESC ? 1 : -1;
            }
            return 0;
        });
    }
    return (
        <div data-testid='data-selection-table'>
            <CustomTable
                columnsDef={dataSelectionTableHeader}
                data={sortedRowData}
                handleSort={handleSort}
                sortIcon={sortValue}
                sortCol={sortCol}
                currentPageNo={0}
                noDataMessage={t('dataSelectionTestDesigner.message')}
                handleAllCheckBox={handleAllCheckBox}
                selectAllCheckBox={sortedRowData.length > 0 && sortedRowData.length === checkedItems?.length}
            />
        </div>
    );
};

export default DataSelectionTable;
