import React, { ReactNode } from 'react';

interface Props {
    /* Valid values of type are 
     1. nexus-btn
     2. nexus-btn-primary
     3. nexus-btn-warn
     4. nexus-btn-icon
     5. nexus-btn-link
     6. nexus-btn-medium
     7. nexus-btn-large
     8. nexus-btn-auto
     9.  nexus-btn-fluid
    */
    type: string[];
    disabled?: boolean;
    label?: string;
    click?: () => void;
    extraClass?: string;
    labelStyle?: string;
    buttonType?: any;
    index?: number;
    icon?: ReactNode;
}
const ButtonComponent: React.FC<Props> = ({
    label,
    type,
    disabled,
    click,
    extraClass,
    labelStyle,
    buttonType,
    index,
    icon,
}) => (
    <button
        onClick={click}
        data-testid={label}
        className={`${type.join(' ')} ${extraClass ? extraClass : ''}`}
        disabled={disabled}
        type={buttonType}
        key={`${label}-${index}`}
    >
        {icon && <span>{icon}</span>}
        {label && <span className={labelStyle}>{label}</span>}
    </button>
);

export default ButtonComponent;
