import './testParameters.module.scss';
import { Create, Update, asterik } from 'constants/constants';
import { NexusCard, NexusCardBody } from '@nexus/react';
import { getDropdownValues } from 'services/services';
import { useAppDispatch } from 'store';
import { useEffect, useRef, useState } from 'react';

import ButtonComponent from 'app/components/nds/button/button';
import InputComponent from 'app/components/nds/input/input';
import Select from 'app/components/nds/select/select';
import TextareaComponent from 'app/components/nds/textarea/textarea';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getHomeUrl } from 'utils/redirectionUtils';
import { getUniqueKey } from 'utils/generateButtonIndex';
import { stepperRoutes } from 'constants/stepperConstants';
import {
    createTestTemplate,
    getSectorDropdownData,
    getControlDropdownData,
    getLobDropdownData,
    getProcessDropdownData,
    getRiskDropdownData,
    updateTestTemplate,
    updateActiveStep,
    reset,
} from '../../redux/testTemplateReducer';
import { useSelector } from 'react-redux';
import {
    sectorDataList,
    lobDataList,
    processDataList,
    riskDataList,
    controlDataList,
    currentTestTemplate,
} from '../../redux/testTemplateSelectors';

const TestParameters = () => {
    const [t] = useTranslation('lang');
    const dispatch = useAppDispatch();
    const sectorData = useSelector(sectorDataList);
    const lobData = useSelector(lobDataList);
    const processData = useSelector(processDataList);
    const riskData = useSelector(riskDataList);
    const controlData = useSelector(controlDataList);
    const testTemplate = useSelector(currentTestTemplate);
    const { userFlow, id, name: testTemplateName, description: testTemplateDescription, prcContext } = testTemplate;

    const navigate = useNavigate();
    const [sector, setSector] = useState<{ sector: string; id: string }>({ sector: '', id: '' });
    const [lob, setLob] = useState<{ lob: string; id: string }>({ lob: '', id: '' });
    const [process, setProcess] = useState<{ process: string; id: string }>({ process: '', id: '' });
    const [risk, setRisk] = useState<{ risk: string; id: string }>({ risk: '', id: '' });
    const [control, setControl] = useState<{ control: string; id: string }>({ control: '', id: '' });
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const bottomRef: any = useRef(null);

    useEffect(() => {
        if (userFlow !== Update) {
            dispatch(getSectorDropdownData());
        }
    }, []);
    useEffect(() => {
        if (sector.id) {
            dispatch(reset('lobData'));
            dispatch(reset('processData'));
            dispatch(reset('riskData'));
            dispatch(reset('controlData'));
            setLob({ lob: '', id: '' });
            setProcess({ process: '', id: '' });
            setRisk({ risk: '', id: '' });
            setControl({ control: '', id: '' });
            dispatch(getLobDropdownData(sector.id));
        }
    }, [sector]);
    useEffect(() => {
        if (lob.id) {
            dispatch(reset('processData'));
            dispatch(reset('riskData'));
            dispatch(reset('controlData'));
            setProcess({ process: '', id: '' });
            setRisk({ risk: '', id: '' });
            setControl({ control: '', id: '' });
            dispatch(getProcessDropdownData(lob.id));
        }
    }, [lob]);
    useEffect(() => {
        if (process.id) {
            dispatch(reset('riskData'));
            dispatch(reset('controlData'));
            setRisk({ risk: '', id: '' });
            setControl({ control: '', id: '' });
            dispatch(getRiskDropdownData(process.id));
        }
    }, [process]);
    useEffect(() => {
        if (risk.id) {
            dispatch(reset('controlData'));
            setControl({ control: '', id: '' });
            dispatch(getControlDropdownData(risk.id));
        }
    }, [risk]);
    useEffect(() => {
        if (id) {
            setName(testTemplateName || '');
            setDescription(testTemplateDescription || '');
            setControl({
                control: prcContext?.control?.name || '',
                id: prcContext?.control?.id || '',
            });
        }
    }, [testTemplate]);
    const handleBackButton = () => {
        const home = getHomeUrl();
        navigate(home);
    };
    const handleNextButton = () => {
        const payload = {
            description,
            draftStatus: stepperRoutes.step_2,
            isDraft: true,
            name,
            prcContext: {
                control: { id: control.id },
                lob: { id: lob.id },
                process: { id: process.id },
                risk: { id: risk.id },
                sector: { id: sector.id },
            },
        };
        if (userFlow === Create) {
            dispatch(createTestTemplate(payload)).then((res: any) => {
                if (res.payload.status >= 200 && res.payload.status < 300) {
                    dispatch(updateActiveStep(stepperRoutes.step_2));
                    const { pathname } = window.location;
                    window.history.pushState('', document.title, `${pathname}/${res.payload.data.id}`);
                }
            });
        } else if (userFlow === Update) {
            const { id, name: tempateName, description: templateDescription, isDraft, prcContext } = testTemplate;
            const prcContextIds = {
                control: { id: prcContext?.control?.id },
                lob: { id: prcContext?.lob?.id },
                process: { id: prcContext?.process?.id },
                risk: { id: prcContext?.risk?.id },
                sector: { id: prcContext?.sector?.id },
            };

            dispatch(
                updateTestTemplate({
                    testTemplateId: id,
                    requestBody: {
                        description: description || templateDescription,
                        draftStatus: stepperRoutes.step_2,
                        isDraft,
                        name: name || tempateName,
                        prcContext: prcContextIds,
                    },
                }),
            );
        }
    };
    const eqButtonList = [
        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn nexus-rhythm-top-5'],
            clickButton: handleBackButton,
            label: t('buttons.back'),
            type: 'button',
        },
        {
            buttonDisabled: !(
                (name || testTemplateName) &&
                (description || testTemplateDescription) &&
                (control?.id || prcContext?.control?.id)
            ),
            buttonStyles: ['nexus-btn-primary nexus-rhythm-top-5 nexus-ml-3 nexus-mr-2'],
            clickButton: handleNextButton,
            label: t('buttons.next'),
            labelStyles: 'nexus-btn-default',
            type: 'button',
        },
    ];

    const selectDropdownValue = (inputValue: string) => {
        return inputValue?.length ? inputValue : t('dataSelection.pleaseSelect');
    };

    return (
        <>
            <div className='design-parameters' data-testid='designer-parameters'>
                <NexusCard className='nexus-card-border-0 nexus-p-2'>
                    <NexusCardBody>
                        <div className='nexus-row'>
                            <div className='nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-5 nexus-col-xl-5  nexus-mt-2 nexus-ml-2'>
                                <div className='nexus-row'>
                                    <div className={`circle adjust-circle-styles`}>1</div>
                                    <div className='nexus-ml-1 nexus-subtitle'>
                                        {t('testDesigner.name')}
                                        <span className={'asterik-styles'}>{asterik}</span>
                                    </div>
                                </div>

                                <div className={`nexus-caption-copy  nexus-mt-2 adjust-text-styles`}>
                                    {t('testDesigner.testHierarchy.title')}
                                </div>
                                <div className={`nexus-data nexus-mt-2 adjust-text-styles`}>
                                    {t('testDesigner.testHierarchy.textBoxHeader')}
                                </div>
                                <div className={'adjust-text-styles'}>
                                    <InputComponent
                                        type='text'
                                        placeholder={t('testDesigner.testHierarchy.placeholder')}
                                        data-testid='input-data-source'
                                        initialValue={name || testTemplateName}
                                        extraClass='input-area'
                                        onChange={(event) => {
                                            setName(event.target.value);
                                        }}
                                    />
                                </div>

                                <div className='nexus-subtitle-sm nexus-mt-5  nexus-row'>
                                    <div className={`circle adjust-circle-styles`}>2</div>
                                    <div className='nexus-ml-1'>
                                        {t('testDesigner.testHierarchy.description')}
                                        <span className={'asterik-styles'}>{asterik}</span>
                                    </div>
                                </div>
                                <div className={`nexus-caption-copy nexus-mt-1 adjust-text-styles`}>
                                    {t('testDesigner.testHierarchy.templateDescriptionUseCase')}
                                </div>
                                <div className={`nexus-mt-2 nexus-data adjust-text-styles`}>
                                    {t('testDesigner.testHierarchy.templateDescription')}
                                </div>

                                <div className={'adjust-text-styles'}>
                                    <TextareaComponent
                                        styles='textarea-eq-schema'
                                        value={description || testTemplateDescription}
                                        onChange={(event) => {
                                            setDescription(event.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-offset-2 nexus-col-xl-offset-2 nexus-col-lg-4 nexus-col-xl-4  nexus-mt-2'>
                                <div className={`nexus-flex-row dropdowm-label-styles`}>
                                    <div className={`circle adjust-circle nexus-mr-1`}>3</div>
                                    <div className={`nexus-subtitle adjust-text`}>
                                        {t('testDesigner.testHierarchy.testHierarchy')}
                                        <span className={'asterik-styles'}>{asterik}</span>
                                    </div>
                                </div>

                                <div className={`nexus-caption-copy nexus-ml-2 nexus-mt-1 dropdowm-label-styles`}>
                                    {t('testDesigner.testHierarchy.selectParameters')}
                                </div>
                                <div className='nexus-col' data-testid='sector-container'>
                                    <div className='nexus-mt-2 nexus-col-lg-12 nexus-col-xl-12'>
                                        <span className='nexus-data'>{t('testParameters.sector')}</span>
                                        <Select
                                            name={'sector'}
                                            key={sectorData[0] ? sectorData[0]?.name : 'sector'}
                                            selectedValue={
                                                prcContext?.sector?.name || selectDropdownValue(sector?.sector)
                                            }
                                            options={getDropdownValues(sectorData) || []}
                                            customOnChange={(data: any) => {
                                                const sectorId = sectorData.find(
                                                    (item: any) => item?.name === data,
                                                )?.id;
                                                setSector({ sector: data, id: sectorId });
                                            }}
                                            disabledOption={t('dataSelection.pleaseSelect')}
                                            disabled={prcContext?.sector?.name}
                                        />
                                    </div>
                                    <div className='nexus-mt-2 nexus-col-lg-12 nexus-col-xl-12'>
                                        <span className='nexus-data'>{t('testParameters.lineofBusiness')}</span>
                                        <Select
                                            disabledOption={t('dataSelection.pleaseSelect')}
                                            customOnChange={(data: any) => {
                                                const lobId = lobData.find((item: any) => item?.name === data)?.id;
                                                setLob({ lob: data, id: lobId });
                                            }}
                                            options={getDropdownValues(lobData) || []}
                                            selectedValue={prcContext?.lob?.name || selectDropdownValue(lob?.lob)}
                                            key={lobData[0] ? lobData[0]?.name : sector?.id}
                                            name={'lob'}
                                            disabled={prcContext?.lob?.name || !sector?.id}
                                        />
                                    </div>
                                    <div className='nexus-mt-2 nexus-col-lg-12 nexus-col-xl-12'>
                                        <span className='nexus-data'>{t('testParameters.process')}</span>
                                        <Select
                                            disabled={prcContext?.process?.name || !lob?.id}
                                            name={'process'}
                                            key={processData[0] ? processData[0]?.name : 'lob'}
                                            selectedValue={
                                                prcContext?.process?.name || selectDropdownValue(process?.process)
                                            }
                                            options={getDropdownValues(processData) || []}
                                            customOnChange={(data: any) => {
                                                const processId = processData.find(
                                                    (item: any) => item?.name === data,
                                                )?.id;

                                                setProcess({ process: data, id: processId });
                                            }}
                                            disabledOption={t('dataSelection.pleaseSelect')}
                                        />
                                    </div>
                                    <div className='nexus-mt-2 nexus-col-lg-12 nexus-col-xl-12'>
                                        <span className='nexus-data'>{t('testParameters.risk')}</span>
                                        <Select
                                            disabled={prcContext?.risk?.name || !process?.id}
                                            name={'risk'}
                                            key={riskData[0] ? riskData[0]?.name : 'process'}
                                            selectedValue={prcContext?.risk?.name || selectDropdownValue(risk?.risk)}
                                            options={getDropdownValues(riskData) || []}
                                            customOnChange={(data: any) => {
                                                const riskId = riskData.find((item: any) => item?.name === data)?.id;
                                                setRisk({ risk: data, id: riskId });
                                            }}
                                            disabledOption={t('dataSelection.pleaseSelect')}
                                        />
                                    </div>

                                    <div className='nexus-mt-2 nexus-col-lg-12 nexus-col-xl-12'>
                                        <span className='nexus-data'>{t('testParameters.control')}</span>
                                        <Select
                                            disabled={prcContext?.control?.name || !risk?.id}
                                            name={'control'}
                                            key={controlData[0] ? controlData[0]?.name : 'risk'}
                                            selectedValue={
                                                prcContext?.control?.name || selectDropdownValue(control?.control)
                                            }
                                            options={getDropdownValues(controlData) || []}
                                            customOnChange={(data: any) => {
                                                const controlId = controlData.find(
                                                    (item: any) => item?.name === data,
                                                )?.id;
                                                setControl({ control: data, id: controlId });
                                            }}
                                            disabledOption={t('dataSelection.pleaseSelect')}
                                        />
                                    </div>
                                </div>
                                {/* )} */}
                            </div>
                        </div>

                        <div className='nexus-flex-row-reverse nexus-mt-3'>
                            <div>
                                {eqButtonList.map(
                                    (
                                        { label, clickButton, buttonDisabled, buttonStyles, labelStyles, type },
                                        index,
                                    ) => {
                                        return (
                                            <ButtonComponent
                                                type={buttonStyles}
                                                key={getUniqueKey(index)}
                                                label={label}
                                                disabled={buttonDisabled}
                                                click={clickButton}
                                                labelStyle={labelStyles}
                                                buttonType={type}
                                                index={index}
                                            />
                                        );
                                    },
                                )}
                            </div>
                        </div>
                    </NexusCardBody>
                </NexusCard>
                <div ref={bottomRef} />
            </div>
        </>
    );
};
export default TestParameters;
