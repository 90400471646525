import './dropdown.scss';

import { NexusDropdown, NexusOption } from '@nexus/react';
import React from 'react';

import { Link } from 'react-router-dom';

const getValueIdx = (value: any, index: number) => {
    return `${value.label}-${index}`;
};

const DropdownComponent: React.FC<{
    placement?: any;
    toggleDropDown?: boolean;
    dropdownOptions?: any;
    triggererContent?: React.ReactNode;
    dropdownType?: any;
    height?: number;
    width?: number;
}> = ({ toggleDropDown, dropdownOptions, triggererContent, placement, dropdownType, height, width }) => {
    return (
        <NexusDropdown
            data-testid='drop-down'
            show={toggleDropDown}
            placement={placement}
            dropdown-type={dropdownType}
            height={height}
            width={width}
            slot={'trigerelement'}
            className='dropdown-styles'
        >
            <span slot={'trigerer'}>{triggererContent}</span>

            {dropdownOptions.map((value: any, index: number) => {
                return (
                    <NexusOption key={getValueIdx(value, index)}>
                        <Link to={value.link} onClick={value.clickButton}>
                            <span className={value.extraStyle}>{value.label}</span>
                        </Link>
                    </NexusOption>
                );
            })}
        </NexusDropdown>
    );
};

export default DropdownComponent;
