export const expressionGenerator = (data: any) => {
    if (data) {
        let placeHolderCount = 0;
        let execution_parameters: any = {};
        let user_selected_columns = new Set();

        const formatData = (data: any) => {
            if (data.category === 'group') {
                data?.rules?.forEach((rule: any) => {
                    formatData(rule);
                });
            } else if (data.category === 'rule') {
                const placeHolder = `placeholder_${placeHolderCount}`;
                user_selected_columns.add(data.field);
                if (data.compare_with_field) {
                    user_selected_columns.add(data.compare_field_name);
                } else {
                    execution_parameters[placeHolder] = data.user_value;
                    data.value = placeHolder;
                    placeHolderCount++;
                }
            }
        };
        formatData(data);
        return {
            execution_parameters,
            expression_object: data,
            type: 'expression',
            user_selected_columns: Array.from(user_selected_columns),
        };
    }
};
