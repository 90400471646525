import './fileUpload.scss';

import { NexusChip, NexusChipGroup, NexusFileUpload, NexusFormField } from '@nexus/react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

interface InputProps {
    successSubmit?: boolean;
    accept?: string;
    cancelBtn?: any;
    uploadPatch?: (dataFileId: any) => void;
    returnFileQueue?: any;
    removeFileQueue?: any;
    classes?: string;
    infoMsg?: string;
    showSizeMsg?: boolean;
    disabled?: boolean;
}

const FileUploadComponent = (props: InputProps) => {
    const [t] = useTranslation('lang');
    const [queue, setQueue] = useState<Array<any>>([]);

    const [uploaded, setUploaded] = useState<Array<any>>([]);

    const addToQueueUnique = (prevQueue: Array<any>, newFiles: Array<any>) => {
        const ids = new Set(prevQueue.map((prevFile: any) => prevFile.name));

        const merged = [...prevQueue, ...newFiles.filter((newFile) => !ids.has(newFile.name))];

        return merged;
    };

    const handleChange = (data: any) => {
        setQueue(addToQueueUnique(queue, [data]));
        props.returnFileQueue([data]); // function to send file to data selection page
    };

    useEffect(() => {
        if (props.successSubmit === true) {
            setuploaded();
        }
    }, [props.successSubmit]);

    const setuploaded = () => {
        const nextQueue = queue.map((file) => {
            file.uploaded = true;

            return file;
        });

        setQueue([]);
        setUploaded(addToQueueUnique(uploaded, nextQueue));
    };

    const removeFile = (removedFile: any) => {
        setQueue(queue.filter((file) => file.name !== removedFile.name));
        props.removeFileQueue(); // remove file
    };

    const removeUploadedFile = (removedFile: any) => {
        setUploaded(uploaded.filter((file) => file.name !== removedFile.name));
        props.removeFileQueue(); // remove file
    };

    const renderQueue = (queue: any, cb: any) =>
        queue.map((file: any) => (
            <NexusChip
                aria-label={`Click to remove file - ${file.name}`}
                removable={true}
                key={file.name}
                success={file.uploaded}
                onClick={() => cb(file)}
            >
                {file.name}
            </NexusChip>
        ));

    return (
        <div>
            <NexusFormField className={props.classes || 'maxWidth'}>
                <NexusFileUpload
                    accept={props.accept}
                    data-testid='nexus-fileupload'
                    onInput={(event: any) => handleChange(event.target.files[0])}
                    onDrop={(event: any) => handleChange(event.dataTransfer.files[0])}
                    disabled={!!queue.length || props.disabled}
                >
                    <p>{props?.infoMsg ? props?.infoMsg : t('fileUpload.infoMessage')}</p>
                    <p>{props?.showSizeMsg ? t('fileUpload.fileSize') : null}</p>
                </NexusFileUpload>
            </NexusFormField>
            {queue.length > 0 && (
                <NexusChipGroup className='nexus-rhythm-4'>
                    {/* span required for react to render dynamic content into the correct slot */}
                    <span>{renderQueue(queue, removeFile)}</span>
                </NexusChipGroup>
            )}

            {uploaded.length > 0 && (
                <NexusChipGroup className='nexus-rhythm-4'>
                    {/* span required for react to render dynamic content into the correct slot */}
                    <span>{renderQueue(uploaded, removeUploadedFile)}</span>
                </NexusChipGroup>
            )}
        </div>
    );
};

export default FileUploadComponent;
