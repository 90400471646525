import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';

export const publishedTestTemplateDataList = createSelector(
    (state: RootState) => state.testTemplateData,
    (testTemplateData) => testTemplateData?.publishedTestTemplateData,
);

export const testTemplateTableData = createSelector(
    (state: RootState) => state.testTemplateData,
    (testTemplateData) => testTemplateData?.testTemplateTableData,
);

export const currentTestTemplate = createSelector(
    (state: RootState) => state.testTemplateData,
    (testTemplateData) => testTemplateData?.currentTestTemplate,
);

export const sectorDataList = createSelector(
    (state: RootState) => state.testTemplateData,
    (testTemplateData) => testTemplateData?.sectorData,
);

export const lobDataList = createSelector(
    (state: RootState) => state.testTemplateData,
    (testTemplateData) => testTemplateData?.lobData,
);

export const processDataList = createSelector(
    (state: RootState) => state.testTemplateData,
    (testTemplateData) => testTemplateData?.processData,
);

export const riskDataList = createSelector(
    (state: RootState) => state.testTemplateData,
    (testTemplateData) => testTemplateData?.riskData,
);

export const controlDataList = createSelector(
    (state: RootState) => state.testTemplateData,
    (testTemplateData) => testTemplateData?.controlData,
);

export const canonicalModelsList = createSelector(
    (state: RootState) => state.testTemplateData,
    (testTemplateData) => testTemplateData?.canonicalModels,
);

export const checkedItems = createSelector(
    (state: RootState) => state.testTemplateData,
    (testTemplateData) => testTemplateData?.checkedItems,
);
