import './manualEditor.scss';

import { NexusCard } from '@nexus/react';
import Table from 'app/components/nds/table/table';
import TextareaComponent from '../nds/textarea/textarea';
import { manualTableHeader } from 'constants/constants';
import { useState } from 'react';

const ManualEditorBuilder = ({
    columnsArr,
    inputElementCallBack,
    expression = '',
    cannonicalModelProperties = {},
}: any) => {
    const [inputElement, setInputElement] = useState(expression);
    const tableData: {
        COLUMN_NAME: string;
        DATA_TYPE: string;
        SAMPLE_VALUE: string;
        AVAILABLE_OPERATORS: string;
    }[] = columnsArr?.map((item: string) => {
        return {
            AVAILABLE_OPERATORS:
                cannonicalModelProperties[item].type === 'string' ? '== , !=' : '< , > , >= , <= ,== , !=',
            COLUMN_NAME: item,
            DATA_TYPE:
                cannonicalModelProperties[item].type.charAt(0).toUpperCase() +
                cannonicalModelProperties[item].type.slice(1),
            SAMPLE_VALUE: cannonicalModelProperties[item]
                ? cannonicalModelProperties[item].examples
                    ? cannonicalModelProperties[item]?.examples?.join(', ')
                    : cannonicalModelProperties[item].example
                : 'No Data Available',
        };
    });

    const handleTextArea = (event: any) => {
        setInputElement(event?.target?.value);
        inputElementCallBack(event?.target?.value);
    };
    const handleDelete = () => {
        setInputElement('');
        inputElementCallBack('');
    };
    return (
        <div className='nexus-mx-2' data-testid='manual-editor-builder'>
            <NexusCard bgColor='#e3f2fd' height='12rem' className='nexus-p-1'>
                <div className='main-content nexus-col-lg-12'>
                    <TextareaComponent
                        placeholder={'Type your expressions here..'}
                        value={inputElement}
                        onChange={(event) => {
                            handleTextArea(event);
                        }}
                        rows={5}
                    />
                    <div className='nexus-col-lg-offset-11 nexus-col-lg-1 cursor' onClick={handleDelete}>
                        Clear All
                    </div>
                </div>
            </NexusCard>
            <h5>Column Overview</h5>
            <section className='nexus-p-0'>
                <Table pagination={true} totalItems={tableData?.length} row={tableData} columns={manualTableHeader} />
            </section>
        </div>
    );
};

export default ManualEditorBuilder;
