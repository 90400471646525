import React from 'react';
import { NexusIcon } from '@nexus/react';
import './unauthorized.scss';
import Badmood from '@nexus/core/dist/assets/icons/sentiment/ic_sentiment_dissatisfied_24px.svg';
import { Link } from 'react-router-dom';
import { getHomeUrl } from 'utils/redirectionUtils';

const Unauthorized = () => {
    const navigateTo = getHomeUrl();
    return (
        <div className='page-not-found nexus-center-md'>
            <NexusIcon src={Badmood} className='icon-color'></NexusIcon>
            <h1 className='nexus-h1 font-color'>UNAUTHORIZED</h1>
            <h3 className='nexus-h3 message'>You do not have access for the page.</h3>
            <Link to={navigateTo}>Go back to home</Link>
        </div>
    );
};

export default Unauthorized;
