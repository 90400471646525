import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './tableColumnFilter.scss';

import { NexusFormField, NexusIcon, NexusLabel } from '@nexus/react';

import ButtonComponent from 'app/components/nds/button/button';
import CalendarIcon from '@nexus/core/dist/assets/icons/media/ic_today_24px.svg';
import DeleteIcon from '@nexus/core/dist/assets/icons/action/ic_delete_24px.svg';
import { DateFormat } from 'constants/constants';
import DatePicker from 'react-date-picker';
import React from 'react';
import SearchComponent from '../nds/search/search';
import { useTranslation } from 'react-i18next';
import Select from 'app/components/nds/select/select';
interface ITableColumnFilter {
    handleSearchText: (event: React.FormEvent<HTMLNexusInputElement>) => void;
    handleDateFilter?: any;
    handleDateFromFilter?: any;
    handleDateToFilter?: any;
    handleClearAll: () => void;
    testFrom: any;
    testTo: any;
    searchTextValue: string;
    handleDelete?: () => void;
    disableDelete?: boolean;
    showDeleteButton?: boolean;
    status?: string;
    handleStatus?: any;
    statusOptions?: any;
    canonicalModels?: any;
    selectedCanonicalModel?: any;
    handleCanonicalModel?: any;
}

const TableColumnFilter = ({
    handleSearchText,
    handleDateFromFilter,
    handleDateToFilter,
    handleClearAll,
    testFrom,
    testTo,
    searchTextValue,
    handleDelete,
    disableDelete,
    showDeleteButton = false,
    status,
    handleStatus,
    statusOptions,
    canonicalModels,
    selectedCanonicalModel,
    handleCanonicalModel,
}: ITableColumnFilter) => {
    const [t] = useTranslation('lang');
    return (
        <div data-testid='Table-Column-Filter'>
            <NexusFormField>
                <div className='alignHeading nexus-col-2xs-1 nexus-col-xs-1 nexus-col-sm-1 nexus-col-md-1 nexus-col-lg-2 nexus-col-xl-3 nexus-col-2xl-2 nexus-h4 nexus-mt-1'>
                    {window?.location?.pathname.includes('/test-session')
                        ? t('home.testSessionHeading')
                        : t('testDesigner.templateHeading')}
                </div>
                <div className='nexus-row'>
                    <div className='nexus-col-lg-offset-3 nexus-col-lg-2 nexus-mt-1'>
                        <NexusLabel data-testid='label-status'>{t('home.status')}</NexusLabel>
                    </div>
                    <div className='nexus-col-lg-2 nexus-mt-1'>
                        <NexusLabel data-testid='label-date-field-from'>{t('home.testFrom')}</NexusLabel>
                    </div>
                    <div className='nexus-col-lg-3 nexus-mt-1'>
                        <NexusLabel data-testid='label-date-field-to'>{t('home.testTo')}</NexusLabel>
                    </div>
                    {window?.location?.pathname.includes('/test-template') && (
                        <div className='nexus-col-lg-2 nexus-col-xl-2'>
                            <Select
                                name={'data source'}
                                key={selectedCanonicalModel?.label}
                                selectedValue={
                                    selectedCanonicalModel?.label || t('dataSelectionTestDesigner.pleaseSelect')
                                }
                                options={canonicalModels}
                                customOnChange={handleCanonicalModel}
                            />
                        </div>
                    )}
                </div>
                <div className='nexus-row '>
                    <div className='nexus-col-lg-1 nexus-col-xl-1 line-height-inherit delete-button-wrapper'>
                        {showDeleteButton && (
                            <ButtonComponent
                                disabled={disableDelete}
                                type={['nexus-btn']}
                                click={handleDelete}
                                extraClass='delete-button'
                                icon={<NexusIcon data-testid='Delete Selected' src={DeleteIcon} size='lg' />} //give testid
                            />
                        )}
                    </div>
                    <div className='nexus-col-lg-offset-2 nexus-col-lg-2 nexus-col-xl-2 nexus-mt-1'>
                        <Select
                            name={'status'}
                            key={'status' + status}
                            selectedValue={status}
                            options={statusOptions}
                            customOnChange={(data: any) => handleStatus(data)}
                            classes='fit-height'
                        />
                    </div>
                    <div className='nexus-col-lg-2 nexus-mt-1'>
                        <DatePicker
                            value={testFrom}
                            onChange={(date: any) => handleDateFromFilter(date)}
                            data-testid='input-textbox-date-from'
                            format={process.env.REACT_APP_DATE_FORMAT}
                            monthPlaceholder={DateFormat.monthPlaceholder}
                            dayPlaceholder={DateFormat.dayPlaceholder}
                            yearPlaceholder={DateFormat.yearPlaceholder}
                            className='date-picker'
                            maxDate={testTo}
                            calendarIcon={<NexusIcon src={CalendarIcon} size='sm' />}
                            nativeInputAriaLabel='date-test-from'
                        />
                    </div>
                    <div className='nexus-col-lg-2 nexus-mt-1'>
                        <DatePicker
                            value={testTo}
                            onChange={(date: any) => handleDateToFilter(date)}
                            data-testid='input-textbox-date-to'
                            format={process.env.REACT_APP_DATE_FORMAT}
                            monthPlaceholder={DateFormat.monthPlaceholder}
                            dayPlaceholder={DateFormat.dayPlaceholder}
                            yearPlaceholder={DateFormat.yearPlaceholder}
                            className='date-picker'
                            minDate={testFrom}
                            calendarIcon={<NexusIcon src={CalendarIcon} size='sm' />}
                            nativeInputAriaLabel='date-test-to'
                        />
                    </div>
                    <div className='nexus-col-2xs-1 nexus-col-xs-1 nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2 nexus-col-xl-2 nexus-col-2xl-2 nexus-mt-1'>
                        <SearchComponent
                            extraClass='line-height-inherit'
                            placeholder={t('riskControlTestcase.search')}
                            onInputSearchValue={(event: React.FormEvent<HTMLNexusInputElement>) =>
                                handleSearchText(event)
                            }
                            initialValue={searchTextValue}
                        />
                    </div>
                    <div className='nexus-col-lg-1 nexus-col-xl-1 line-height-inherit'>
                        <ButtonComponent
                            type={['nexus-btn nexus-btn-fluid']}
                            label={t('home.clearAll')}
                            click={handleClearAll}
                        />
                    </div>
                </div>
            </NexusFormField>
        </div>
    );
};

export default TableColumnFilter;
