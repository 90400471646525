export default function DragDrop({ children, onDrop, nodeId, draggable = true }) {
    function handleDropDragStart(event) {
        event?.dataTransfer?.setData('text/plain', JSON.stringify({ sourceId: event.target.id }));
    }

    function handleDropDragEnter(event) {
        event.preventDefault();
    }

    function handleDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
        const dropTarget = event?.currentTarget;
        dropTarget.firstChild.classList.add('drop-target');
        event.dataTransfer.dropEffect = 'move';
    }

    function handleDragLeave(event) {
        const dropTarget = event.currentTarget;
        dropTarget.firstChild.classList.remove('drop-target');
    }
    function getDropZone(element) {
        // Check if the element is a drop zone
        if (element.getAttribute('data-dropzone')) {
            return element;
        }
        // Recursively check if any parent element is a drop zone
        if (element.parentNode) {
            return getDropZone(element.parentNode);
        }
        return null;
    }
    function handleDrop(event, targetId) {
        event.stopPropagation();
        const element = getDropZone(event.target);
        const data = event?.dataTransfer?.getData('text/plain');
        const { sourceId } = data ? JSON.parse(data) : {};
        event.preventDefault();
        if (element && sourceId !== undefined) {
            onDrop(parseInt(sourceId), targetId);
        }
        const dropTarget = event.currentTarget;
        dropTarget.firstChild.classList.remove('drop-target');
    }

    return (
        <div
            className={`nexus-mb-2  ${draggable ? 'draggable drag-drop' : 'drag-drop'}`}
            data-testid='drag-drop'
            data-dropzone
            draggable={draggable}
            id={nodeId}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(event) => handleDrop(event, nodeId)}
            onDragStart={(event) => handleDropDragStart(event)}
            onDragEnter={handleDropDragEnter}
        >
            {children}
        </div>
    );
}
