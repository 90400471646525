import './expressionBuilder.scss';

import { NexusCard, NexusCardBody, NexusTabBar } from '@nexus/react';
import { EXPRESSION, MVEL, tabOptions as constantTabOptions } from 'constants/constants';
import { useAppDispatch } from 'store';
import { useEffect, useState } from 'react';

import ButtonComponent from 'app/components/nds/button/button';
import ExpressionBuilderComponent from 'app/components/expressionBuilder/ExpressionBuilderComponent';
import ManualEditorBuilder from 'app/components/manualEditor/manualEditorBuilder';
import ModalComponent from 'app/components/nds/modal/modal';
import { constructExpressionBuilderObject } from './expressionBuilderHelper';
import { getButtonIndex } from 'utils/generateButtonIndex';
import { getRandomNumber } from 'utils/mathUtils';
import { isObjectEmpty } from 'services/services';
import { stepperRoutes } from 'constants/stepperConstants';
import { useTranslation } from 'react-i18next';
import {
    getExpPreview,
    updateTestTemplate,
    updateTestTemplateScriptConfiguration,
    updateActiveStep,
    getCannonicalModelById,
} from '../../redux/testTemplateReducer';
import { currentTestTemplate } from '../../redux/testTemplateSelectors';
import { useSelector } from 'react-redux';

const ExpressionBuilder = () => {
    const dispatch = useAppDispatch();
    const [t] = useTranslation('lang');

    const [columnsArr, setColumnArr] = useState<any>([]);
    const [rulesObject, setRulesObject] = useState({});
    const [showPopUp, setShowPopUp] = useState<boolean>(false);
    const [saveIndex, setSaveIndex] = useState<number>(0);
    const [tabOptions, setTabOptions] = useState([
        {
            isActive: true,
            title: t('expressionBuilder.editorTab'),
            value: constantTabOptions.option1,
        },
        {
            isActive: false,
            title: t('expressionBuilder.manualTab'),
            value: constantTabOptions.option2,
        },
    ]);
    const testTemplate = useSelector(currentTestTemplate);
    const {
        id: testTemplateId,
        scriptConfiguration,
        canonicalModelId,
        selectedCanonicalModel,
        dataInputFile,
    } = testTemplate;
    const {
        type,
        expression_string,
        user_selected_columns,
        execution_parameters,
        expression_object,
        selected_user_selected_columns,
        user_selected_columns: execution_columns,
    } = scriptConfiguration || {};
    useEffect(() => {
        const selectedTab = [...tabOptions];
        if (type === MVEL) {
            selectedTab[0].isActive = false;
            selectedTab[1].isActive = true;
        } else if (type === EXPRESSION) {
            selectedTab[0].isActive = true;
            selectedTab[1].isActive = false;
        }
        setTabOptions(selectedTab);
    }, [type]);
    // generate expression string if not present initially
    useEffect(() => {
        if (
            expression_string &&
            user_selected_columns?.length > 0 &&
            expression_object?.rules.length > 0 &&
            selectedCanonicalModel?.id
        ) {
            previewExpression();
        }
    }, []);
    // to retrive the column type and format
    useEffect(() => {
        if (canonicalModelId && Object.keys(selectedCanonicalModel).length === 0) {
            dispatch(getCannonicalModelById(canonicalModelId));
        }
    }, [canonicalModelId]);

    // set expression object in state by stringifying and parsing it to avoid object reference issues
    useEffect(() => {
        setRulesObject(
            expression_object
                ? constructExpressionBuilderObject(JSON.parse(JSON.stringify(expression_object)), execution_parameters)
                : {
                      combinator: 'AND',
                      id: getRandomNumber(),
                      rules: [],
                  },
        );
    }, [expression_object, execution_parameters]);

    // to update the column dropdown values in the expression builder
    useEffect(() => {
        const getColumnFormat = (col: any) => {
            switch (col?.type) {
                case 'integer':
                    return 'number';
                default:
                    return col?.type;
            }
        };
        const user_selected_columns = selected_user_selected_columns || execution_columns;
        if (user_selected_columns?.length > 0) {
            const colArr = user_selected_columns.map((col: any) => {
                const colDef = selectedCanonicalModel?.schema?.properties[col];

                return {
                    format: colDef?.format || '',
                    label: col,
                    type: getColumnFormat(colDef),
                    value: col,
                };
            });
            setColumnArr(colArr);
        }
    }, [selectedCanonicalModel?.id]);

    const handleNextButton = async () => {
        let data: any = {
            execution_parameters: execution_parameters,
            expression_object: expression_object,
            type: 'expression',
            user_selected_columns: user_selected_columns,
        };
        if (tabOptions[1].isActive) {
            data = {
                expression_string: expression_string,
                type: 'mvel',
                user_selected_columns: user_selected_columns,
            };
        }

        const payload = {
            requestBody: {
                canonicalModelId: canonicalModelId,
                dataInputFile: dataInputFile?.id || null,
                draftStatus: stepperRoutes.step_4,
                isDraft: true,
                scriptConfiguration: data,
            },

            testTemplateId: testTemplateId,
        };
        // mvel type expression does not need validation
        let valid = false;
        if (type === MVEL) {
            valid = true;
        } else if (expression_string !== '') {
            valid = true;
        } else {
            const res: any = await previewExpression();
            if (res && res?.payload?.status >= 200 && res?.payload?.status < 300) {
                valid = true;
            }
        }
        if (!valid) {
            return;
        }
        dispatch(updateTestTemplate(payload));
    };
    const handleBackButton = () => {
        dispatch(updateActiveStep(stepperRoutes.step_2));
    };
    const handleExpOpt = (datafromChild: any) => {
        const updatedData = JSON.parse(JSON.stringify(datafromChild));
        updatedData?.expression_object?.rules?.forEach((rule: any) => {
            let ruleValue = rule.value;
            if (rule?.operator === 'is null') {
                if (ruleValue && updatedData?.execution_parameters?.hasOwnProperty(ruleValue)) {
                    delete updatedData?.execution_parameters[ruleValue];
                }
                delete rule.value;
                delete rule.user_value;
                delete rule.ignore_case;
            }
            return {
                ...rule,
            };
        });
        dispatch(
            updateTestTemplateScriptConfiguration({
                ...scriptConfiguration,
                execution_parameters: updatedData?.execution_parameters,
                expression_object: updatedData?.expression_object,
                expression_string: '',
            }),
        );
    };
    const handleManualExpOpt = (expression: any) => {
        dispatch(updateTestTemplateScriptConfiguration({ expression_string: expression, type: 'mvel' }));
    };

    const handleYesButton = () => {
        const selectedTab = [...tabOptions];

        selectedTab.forEach((option) => {
            option.isActive = false;
        });
        selectedTab[saveIndex].isActive = true;
        setTabOptions(selectedTab);
        setShowPopUp(false);
        dispatch(
            updateTestTemplateScriptConfiguration({
                expression_string: '',
                expression_object: {
                    category: 'group',
                    combinator: 'AND',
                    rules: [],
                },
            }),
        );
    };
    const handleNoButton = () => {
        setShowPopUp(false);
    };
    const closeModal = () => {
        setShowPopUp(false);
    };
    const ChangeActive = (index: number) => {
        if (tabOptions[index].isActive) {
            return;
        }
        setShowPopUp(true);
        setSaveIndex(index);
    };
    const eqButtonList = [
        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn nexus-rhythm-top-5'],
            clickButton: handleNoButton,
            label: t('buttons.No'),
        },

        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn-primary nexus-rhythm-top-5 nexus-mx-3'],
            clickButton: handleYesButton,
            label: t('buttons.Yes'),
        },
    ];

    const getUniqueKey = (index: number) => {
        return index;
    };

    const previewExpression = () => {
        const payload = {
            canonicalModelId: selectedCanonicalModel?.id,
            scriptConfiguration: {
                execution_parameters: execution_parameters,
                expression_object: expression_object,
                type: 'expression',
                user_selected_columns: user_selected_columns,
            },
        };

        return dispatch(getExpPreview(payload));
    };

    const confirmationPopUpData = <div className='nexus-body-sm'>{t('home.switchTabs')}</div>;
    const footerPopUpData = (
        <>
            {eqButtonList.map((data: any, _index: any) => {
                return (
                    <ButtonComponent
                        type={data?.buttonStyles}
                        label={data?.label}
                        disabled={data?.buttonDisabled}
                        click={data?.clickButton}
                        key={getButtonIndex(_index)}
                    />
                );
            })}
        </>
    );
    return (
        <>
            {showPopUp && (
                <ModalComponent
                    show={showPopUp}
                    closeModal={closeModal}
                    children={confirmationPopUpData}
                    size='xs'
                    header={t('home.switchTabsHeader')}
                    extraClass='header-title-styles'
                    footerContent={footerPopUpData}
                />
            )}
            <NexusCard className='nexus-card-border-0' data-testid='designer-expression-builder'>
                <NexusCardBody>
                    <div className='nexus-subtitle-sm nexus-mb-1'>{t('expressionBuilder.header1')}</div>
                    <div className='nexus-caption-copy nexus-mb-1'>{t('expressionBuilder.subHeader4')}</div>
                    <NexusTabBar className='nexus-mt-2'>
                        <span>
                            {tabOptions.map((option, index) => (
                                <button
                                    data-testid={'tab-bar-' + option?.title}
                                    key={getUniqueKey(index)}
                                    className={`nexus-link ${option?.isActive ? 'nexus-active' : ''}`}
                                    onClick={() => ChangeActive(index)}
                                >
                                    {option.title}
                                </button>
                            ))}
                        </span>
                    </NexusTabBar>
                    <div data-testid='tab-bar-selected-label'>
                        {tabOptions.map((option, index) => (
                            <div data-testid='tab-bar-selected-label' key={getUniqueKey(index)}>
                                {option.isActive &&
                                option.value === constantTabOptions.option1 &&
                                !isObjectEmpty(rulesObject) ? (
                                    <>
                                        <ExpressionBuilderComponent
                                            key={'expressionBuilderComponent'}
                                            enableDragDrop={false}
                                            jsonExpression={rulesObject}
                                            disable={false}
                                            fields={columnsArr}
                                            enableLockButton={false}
                                            enableNot={true}
                                            onChange={handleExpOpt}
                                            validate={false}
                                            cloneButon={false}
                                            disableTextField={false}
                                            caseSensitive
                                        />
                                        <div className='nexus-flex-row-reverse'>
                                            <ButtonComponent
                                                type={['nexus-btn nexus-btn-primary nexus-mx-3']}
                                                label={t('buttons.preview')}
                                                disabled={isObjectEmpty(expression_object?.rules[0])}
                                                click={previewExpression}
                                            />
                                        </div>
                                        <h5 className=''>{t('expressionBuilder.header2')}</h5>
                                        <NexusCard className='card-expression-editor nexus-caption-copy' key={type}>
                                            {expression_string}
                                        </NexusCard>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {option.isActive && option.value === constantTabOptions.option2 && (
                                    <>
                                        <ManualEditorBuilder
                                            cannonicalModelProperties={selectedCanonicalModel?.schema?.properties}
                                            expression={expression_string || ''}
                                            inputElementCallBack={handleManualExpOpt}
                                            columnsArr={columnsArr.reduce((res: any, item: any) => {
                                                res.push(item.value);
                                                return res;
                                            }, [])}
                                        />
                                    </>
                                )}
                            </div>
                        ))}
                        <div className='nexus-flex-row-reverse nexus-mt-3'>
                            <div className='nexus-row'>
                                <button
                                    key={'expressionBuilder.backButton'}
                                    type='button'
                                    onClick={handleBackButton}
                                    className={`nexus-ml-4 nexus-btn nexus-btn nexus-rhythm-top-5`}
                                    data-testid='buttons.back'
                                >
                                    {t('buttons.back')}
                                </button>
                                <button
                                    key={'expressionBuilder.nextButton'}
                                    type='submit'
                                    onClick={handleNextButton}
                                    className={`nexus-ml-4 nexus-btn nexus-btn-primary nexus-rhythm-top-5 nexus-mx-3`}
                                    disabled={
                                        (tabOptions[0].isActive &&
                                            isObjectEmpty(expression_object?.rules && expression_object?.rules[0])) ||
                                        (tabOptions[1].isActive && !expression_string)
                                    }
                                    data-testid='buttons.next'
                                >
                                    {t('buttons.next')}
                                </button>
                            </div>
                        </div>
                    </div>
                </NexusCardBody>
            </NexusCard>
        </>
    );
};

export default ExpressionBuilder;
