import React, { useEffect, useState } from 'react';
import AuthProvider from '../../../auth/authProvider';
import { deleteAllCookies } from 'utils/cookieUtils';
import axios from 'axios';
import LoaderComponent from 'app/components/nds/loader/loader';
import { REACT_APP_BASE_URL } from '../../../config/settings';

// abstracts the use of authprovider, may render content eventually
const Logout = () => {
    const [logout, setLogout] = useState(false);
    const deleteAccessToken = async () => {
        await axios.delete(`${REACT_APP_BASE_URL}/api/logout`);
        setLogout(true);
    };
    useEffect(() => {
        deleteAllCookies();
        deleteAccessToken();
        sessionStorage.removeItem('redirectUrl');
    }, []);
    return (
        <AuthProvider doLogout={logout}>
            <LoaderComponent show={true} fullscreen={true} />
        </AuthProvider>
    );
};

export default Logout;
