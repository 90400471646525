import './textarea.scss';

import { NexusFormField, NexusTextarea } from '@nexus/react';

import React from 'react';

interface Props {
    attrId?: string;
    disabled?: boolean;
    maxLength?: number;
    minLength?: number;
    placeholder?: string;
    required?: boolean;
    value?: string;
    styles?: string;
    rows?: number;
    onChange?: (event: any) => void;
}
const TextareaComponent: React.FC<Props> = (props: Props) => (
    <NexusFormField>
        <NexusTextarea
            data-testid='nexus-textarea'
            attrId={props.attrId}
            disabled={props.disabled}
            maxLength={props.maxLength}
            minLength={props.minLength}
            placeholder={props.placeholder}
            required={props.required}
            rows={props.rows}
            value={props.value}
            className={props.styles}
            onInput={props.onChange}
        ></NexusTextarea>
    </NexusFormField>
);

export default TextareaComponent;
