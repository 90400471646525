import { stepperRoutes, testSessionSteps } from 'constants/stepperConstants';

import ConfirmationComponent from './confirmation';
import DataSelection from './dataSelection';
import ExpressionBuilder from './expressionBuilder';
import React, { useEffect } from 'react';
import StepperLayout from 'app/components/stepperLayout';
import TemplateSelection from './templateSelection';
import TestParameters from './testHierarchy';
import Mapping from './mapping';
import { getHomeUrl } from 'utils/redirectionUtils';
import { useTranslation } from 'react-i18next';
import { scrollTo } from 'utils/actionUtils';
import { useDispatch } from 'react-redux';
import { Update } from 'constants/constants';
import { useParams } from 'react-router';
import { getTestSession, getTestCases, updateUserFlow } from '../redux/testSessionReducer';
import { currentTestSession } from '../redux/testSessionSelectors';
import { useSelector } from 'react-redux';

function TestSession() {
    const [t] = useTranslation('lang');
    const testSession = useSelector(currentTestSession);
    const { testSessionName: name, draftStatus: activeStep } = testSession;
    const testSessionName = name || t('home.newTestSession');
    const [steps, setSteps] = React.useState(testSessionSteps); // to update steps object in stepper
    const dispatch = useDispatch();
    let { id } = useParams();
    const breadcrumbData = [
        { isEnabled: false, name: 'Home', path: getHomeUrl() },
        { isEnabled: false, name: testSessionName, path: '' },
    ];
    useEffect(() => {
        if (id && testSession?.testSessionId + '' !== id + '') {
            dispatch(getTestSession(parseInt(id)));
            dispatch(updateUserFlow(Update));
        }
    }, [id]);
    const testSessionsWithOutData = (testSessions: any[]) => {
        let ids = [];
        for (let i = 0; i < testSessions?.length; i++) {
            if (Object.keys(testSession?.testSessionTestCases[i]).length < 3) {
                ids.push(testSessions[i].testCaseId);
            }
        }
        return ids;
    };
    useEffect(() => {
        const ids = testSessionsWithOutData(testSession?.testSessionTestCases);
        if (testSession?.testSessionTestCases?.length > 0 && ids.length > 0 && activeStep !== stepperRoutes.step_2) {
            dispatch(getTestCases(ids));
        }
    }, [testSession?.testSessionTestCases]);
    useEffect(() => {
        scrollTo({ behavior: 'smooth', left: 0, top: 0 });
    }, [activeStep]);
    const getComponent = () => {
        switch (activeStep) {
            case stepperRoutes.step_1:
                return <TestParameters />;
            case stepperRoutes.step_2:
                return <TemplateSelection steps={steps} setSteps={setSteps} />;
            case stepperRoutes.step_3:
                return <Mapping />;
            case stepperRoutes.step_4:
                return <DataSelection />;
            case stepperRoutes.step_5:
                return <ExpressionBuilder />;
            case stepperRoutes.step_6:
                return <ConfirmationComponent />;
            default:
                return <></>;
        }
    };
    return (
        <div>
            <StepperLayout
                key={testSessionName}
                steps={steps}
                activeStep={activeStep}
                breadcrumbData={breadcrumbData}
                header={testSessionName}
            >
                {getComponent()}
            </StepperLayout>
        </div>
    );
}

export default TestSession;
