import * as React from 'react';

import { NexusRadio } from '@nexus/react';

interface InputProps {
    input?: any;
    label?: string;
    value: string;
    disabled?: boolean;
    meta?: {
        touched: boolean;
        error: boolean;
    };
    selectedValue?: string;
    name?: string;
    OnChangeHandler: (event: any) => void;
}

export const RadioComponent: React.FC<InputProps> = (props: InputProps) => {
    return (
        <NexusRadio
            data-testid='nexus-radio'
            {...props.input}
            name={props.name}
            value={props.value}
            checked={props.value === props.selectedValue}
            disabled={props.disabled}
            key={props.value}
            onInput={props.OnChangeHandler}
        >
            {props.label}
        </NexusRadio>
    );
};

export default RadioComponent;
