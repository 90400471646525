/* eslint-disable sort-keys */
import { createAppSlice } from 'store/sliceHelper';
import { PayloadAction } from '@reduxjs/toolkit';
import { addMessage, removeMessage } from 'utils/actionUtils';
import { NotificationList, NotificationsApiFactory } from 'app/api/notificationsAPI';
import { notificationApiConfig } from 'constants/apiConstants';

const notificationsApiFactory = NotificationsApiFactory(notificationApiConfig);

export const INITIAL_STATE_NOTIFICATION: any = {
    isLoading: false,
    unreadCount: 0,
    notificationsData: [],
    notificationsMetaData: {},
    messages: [],
};

type NotificationsState = Readonly<typeof INITIAL_STATE_NOTIFICATION>;
export const notificationsSliceReducer = createAppSlice({
    initialState: INITIAL_STATE_NOTIFICATION as NotificationsState,
    name: 'notificationsData',
    reducers: (create) => {
        return {
            getNotifications: create.asyncThunk(
                async (body: { afterId?: string; beforeId?: string; limit?: number }, { rejectWithValue }) => {
                    try {
                        const response: { data: NotificationList; status: number } =
                            await notificationsApiFactory.getNotifications(body?.afterId, body?.beforeId, body?.limit);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_NOTIFICATION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getNotifications');
                    },
                    rejected: (state: typeof INITIAL_STATE_NOTIFICATION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getNotifications',
                                message: action?.payload?.response?.data?.detail,
                                module: 'notifications',
                                type: 'error',
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_NOTIFICATION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getNotifications');
                        state.notificationsData = [
                            ...state.notificationsData,
                            ...action.payload?.data?.data.filter(
                                (notification: any) =>
                                    !state.notificationsData.some(
                                        (existingNotification: any) => existingNotification.id === notification.id,
                                    ),
                            ),
                        ];
                        state.notificationsMetaData = action.payload?.data?.meta;
                    },
                },
            ),
            getNotificationsCount: create.asyncThunk(
                async (_: void, { rejectWithValue }) => {
                    try {
                        const response: { status: number } = await notificationsApiFactory.getNotificationsCount();
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_NOTIFICATION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getNotificationsCount');
                    },
                    rejected: (state: typeof INITIAL_STATE_NOTIFICATION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getNotificationsCount',
                                message: action?.payload?.response?.data?.detail,
                                module: 'notifications',
                                type: 'error',
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_NOTIFICATION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getNotificationsCount');
                        state.unreadCount = action.payload?.data;
                    },
                },
            ),
            readANotification: create.asyncThunk(
                async (id: string, { rejectWithValue }) => {
                    try {
                        const response: { data: void; status: number } =
                            await notificationsApiFactory.markNotificationAsRead(id);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_NOTIFICATION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'readNotification');
                    },
                    rejected: (state: typeof INITIAL_STATE_NOTIFICATION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'readNotification',
                                message: action?.payload?.response?.data?.detail,
                                module: 'notifications',
                                type: 'error',
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_NOTIFICATION, action: any) => {
                        state.isLoading = false;
                        state.notificationsData = state.notificationsData.map((notification: any) => {
                            if (notification.id === action.meta.arg) {
                                return {
                                    ...notification,
                                    isRead: true,
                                };
                            }
                            return notification;
                        });
                        state.messages = addMessage(
                            state,
                            {
                                autoClose: 5000,
                                id: 'readNotification',
                                message: 'Notification read successfully',
                                module: 'notifications',
                                type: 'success',
                            },
                            removeToastMessage,
                        );
                    },
                },
            ),
            readAllNotifications: create.asyncThunk(
                async (_: void, { rejectWithValue }) => {
                    try {
                        const response: { data: void; status: number } =
                            await notificationsApiFactory.markAllNotificationsAsRead();
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_NOTIFICATION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'readAllNotifications');
                    },
                    rejected: (state: typeof INITIAL_STATE_NOTIFICATION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'readAllNotifications',
                                message: action?.payload?.response?.data?.detail,
                                module: 'notifications',
                                type: 'error',
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_NOTIFICATION) => {
                        state.isLoading = false;
                        state.unreadCount = 0;
                        state.notificationsData = state.notificationsData.map((notification: any) => {
                            notification.isRead = true;
                            return notification;
                        });
                        state.messages = addMessage(
                            state,
                            {
                                autoClose: 5000,
                                id: 'readAllNotifications',
                                message: 'Notification read successfully',
                                module: 'notifications',
                                type: 'success',
                            },
                            removeToastMessage,
                        );
                    },
                },
            ),
            removeToastMessage: create.reducer<any>(
                (state: typeof INITIAL_STATE_NOTIFICATION, action: PayloadAction<any>) => {
                    state.messages = removeMessage(state, action.payload);
                },
            ),
            updateNotifications: create.reducer<any>(
                (state: typeof INITIAL_STATE_NOTIFICATION, action: PayloadAction<any>) => {
                    const updatedData = JSON.parse(action.payload);
                    const newNotification = {
                        id: updatedData?.id,
                        title: updatedData?.title,
                        message: updatedData?.message,
                        isRead: updatedData?.isRead,
                        notificationType: updatedData?.type?.toLowerCase(),
                        relatedEntityType: updatedData?.relatedEntityType,
                        relatedEntityId: updatedData?.relatedEntityId,
                        createdAt: updatedData?.createdAt,
                        updatedAt: updatedData?.updatedAt,
                    };
                    state.notificationsData = [newNotification, ...state.notificationsData];
                    state.unreadCount = state.unreadCount + 1;
                },
            ),
        };
    },
});

export const {
    getNotifications,
    getNotificationsCount,
    readANotification,
    readAllNotifications,
    removeToastMessage,
    updateNotifications,
} = notificationsSliceReducer.actions;
export default notificationsSliceReducer.reducer;
