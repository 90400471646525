import React, { useEffect } from 'react';
import StepperLayout from 'app/components/stepperLayout';
import { stepperRoutes, testTemplateSteps } from 'constants/stepperConstants';
import { getHomeUrl } from 'utils/redirectionUtils';
import { useTranslation } from 'react-i18next';
import TestParameters from './testParameter';
import DataSelection from './dataSelection';
import ExpressionBuilder from './expressionBuilder';
import Confirmation from './confirmation';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Update } from 'constants/constants';
import { getTestTemplate, updateUserFlow } from '../redux/testTemplateReducer';
import { currentTestTemplate } from '../redux/testTemplateSelectors';

function TestTemplate() {
    const [t] = useTranslation('lang');
    const testTemplate = useSelector(currentTestTemplate);
    const { draftStatus: activeStep } = testTemplate || {};
    const testTemplateName = testTemplate.name || t('home.newTestTemplate');
    const dispatch = useDispatch();
    let { id } = useParams();
    const breadcrumbData = [
        { isEnabled: false, name: 'Home', path: getHomeUrl() },
        { isEnabled: false, name: testTemplateName, path: '' },
    ];
    useEffect(() => {
        if (testTemplate?.id + '' !== id + '' && id !== undefined) {
            dispatch(updateUserFlow(Update));
            dispatch(getTestTemplate(id));
        }
    }, [id]);
    const getComponent = () => {
        switch (activeStep) {
            case stepperRoutes.step_1:
                return <TestParameters />;
            case stepperRoutes.step_2:
                return <DataSelection />;
            case stepperRoutes.step_3:
                return <ExpressionBuilder />;
            case stepperRoutes.step_4:
                return <Confirmation />;
            default:
                return <></>;
        }
    };
    return (
        <div>
            <StepperLayout
                key={testTemplateName}
                steps={testTemplateSteps}
                activeStep={activeStep}
                breadcrumbData={breadcrumbData}
                header={testTemplateName}
            >
                {getComponent()}
            </StepperLayout>
        </div>
    );
}

export default TestTemplate;
