import React from 'react';
import { useTranslation } from 'react-i18next';

const PowerbiDashboard: React.FC = () => {
    const sampleReportUrl = sessionStorage.getItem('powerBiReportLink');
    const [t] = useTranslation('lang');
    return (
        <div className='nexus-container-iframe' data-testid='powerBI-component'>
            {sampleReportUrl ? (
                <iframe title='PowerBiReport' src={sampleReportUrl} className='responsive-iframe'></iframe>
            ) : (
                t('norecordFound')
            )}
        </div>
    );
};

export default PowerbiDashboard;
