import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import { DateFormat, EXPRESSION, MVEL, fileStatusFlag } from 'constants/constants';
import { NexusCard, NexusCardBody, NexusIcon } from '@nexus/react';
import { useAppDispatch } from 'store';
import React, { useState, useEffect } from 'react';

import ApprovedIcon from '@nexus/core/dist/assets/icons/action/ic_check_circle_24px.svg';
import ButtonComponent from 'app/components/nds/button/button';
import CalendarIcon from '@nexus/core/dist/assets/icons/media/ic_today_24px.svg';
import DatePicker from 'react-date-picker';
import EditIcon from '@nexus/core/dist/assets/icons/action/ic_edit_24px.svg';
import ModalComponent from 'app/components/nds/modal/modal';
import Select from 'app/components/nds/select/select';
import { getHomeUrl } from 'utils/redirectionUtils';
import { stepperRoutes } from 'constants/stepperConstants';
import './confirmationComponent.scss';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getButtonIndex } from 'utils/generateButtonIndex';
import {
    getTestSession,
    getCanonicalModelById,
    getExpPreview,
    runTestSession,
    updateTestSession,
    updateActiveStep,
} from '../../redux/testSessionReducer';
import { currentTestSession } from '../../redux/testSessionSelectors';
import { useSelector } from 'react-redux';
import ArrowSplit from '@nexus/core/dist/assets/icons/action/ic_arrow_split_24px.svg';

const ConfirmationComponent = () => {
    const [t] = useTranslation('lang');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [intialPreviewCall, setIntialPreviewCall] = useState<boolean>(true); // to avoid multiple preview calls on initial load

    const schedulerOptions = [
        t('confirmationPage.dropdownValues.adHoc'),
        t('confirmationPage.dropdownValues.daily'),
        t('confirmationPage.dropdownValues.weekly'),
        t('confirmationPage.dropdownValues.monthly'),
        t('confirmationPage.dropdownValues.quarterly'),
        t('confirmationPage.dropdownValues.semiAnnually'),
        t('confirmationPage.dropdownValues.annually'),
    ];

    const schedulerDropdownValues = schedulerOptions.map((value) => ({ disabled: false, label: value, value: value }));
    const [dropdownValue, setDropdownValue] = useState<string>(t('dataSelection.pleaseSelect'));
    const [showPopUp, setShowPopUp] = useState<boolean>(false);
    const testSession = useSelector(currentTestSession);
    const {
        testSessionId,
        testSessionTestCases,
        canonicalModelData: cannonicalModelData,
        uploadedDataSourceData,
        sectorName,
    } = testSession;

    const canonicalModelId = testSessionTestCases?.[0]?.canonicalModelId;

    // initial preview call on load to get expression string
    useEffect(() => {
        if (intialPreviewCall && testSessionTestCases.length > 0 && canonicalModelId) {
            getPreviewExpression();
            setIntialPreviewCall(false);
        }
    }, [testSessionTestCases]);

    // to get the template details and the data source details
    useEffect(() => {
        if (canonicalModelId && (!cannonicalModelData || Object.keys(cannonicalModelData).length === 0)) {
            dispatch(getCanonicalModelById(canonicalModelId));
        }
    }, [canonicalModelId]);

    const handleDropDown = (value: string) => {
        setDropdownValue(value);
    };

    const handleBackButton = () => {
        dispatch(updateActiveStep(stepperRoutes.step_5));
    };

    const handleEdit = (routes: string) => {
        dispatch(updateActiveStep(routes));
    };

    const handleRunButton = () => {
        const payload = {
            testSessionRequest: {
                draftStatus: undefined,
                isDraft: false,
                scheduler: {
                    frequency: 'once',
                },
            },
            id: testSessionId,
        };

        dispatch(updateTestSession(payload)).then((data: any) => {
            if (data?.payload?.status >= 200 && data?.payload?.status < 300) {
                dispatch(runTestSession(testSessionId)).then((value: any) => {
                    if (value?.payload?.status >= 200 && value?.payload?.status < 300) {
                        setShowPopUp(true);
                    }
                });
            }
        });
    };

    const goToHome = () => {
        setShowPopUp(false);
        const home = getHomeUrl();
        navigate(home);
    };
    const viewTest = async () => {
        await dispatch(getTestSession(testSessionId));
        navigate(`/test-session-details/${testSessionId}`);
    };
    const checkDisabledParams = () => {
        if (
            uploadedDataSourceData?.status === fileStatusFlag.invalid ||
            uploadedDataSourceData?.status === fileStatusFlag.uploaded ||
            uploadedDataSourceData?.status === fileStatusFlag.validation ||
            dropdownValue === t('dataSelection.pleaseSelect')
        ) {
            return true;
        }
        return false;
    };
    const getPreviewExpression = () => {
        for (let testCase of testSessionTestCases) {
            const { scriptConfiguration, testCaseParameters, testCasePreview } = testCase;
            if (scriptConfiguration?.type === MVEL) continue;
            if (testCasePreview !== undefined && testCasePreview !== '') continue;
            const payload = {
                canonicalModelId: testCase.canonicalModelId,
                scriptConfiguration: {
                    execution_parameters:
                        testCaseParameters?.execution_parameters || scriptConfiguration?.execution_parameters,
                    expression_object: scriptConfiguration?.expression_object,
                    type: EXPRESSION,
                    user_selected_columns: scriptConfiguration?.user_selected_columns,
                },
            };
            dispatch(getExpPreview({ testTemplatePreviewRequest: payload, testCaseId: testCase.testCaseId }));
        }
    };
    const confirmationPopUpData = (
        <>
            <div className='modal-content'>
                <NexusIcon src={ApprovedIcon} className='nexus-ml-1 edit-approved-icon-color' />
            </div>
            <div className={'nexus-subtitle modal-content'}>{t('confirmationPage.modal')}</div>
        </>
    );
    const eqButtonList = [
        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn nexus-rhythm-top-5'],
            clickButton: goToHome,
            label: t('buttons.goToHome'),
        },

        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn-primary nexus-rhythm-top-5 nexus-mx-3'],
            clickButton: viewTest,
            label: t('buttons.viewTest'),
        },
    ];
    // Modal Footer Data
    const modalFooterData = (
        <div className={`nexus-mt-2 align-delete-modal-btn`}>
            {eqButtonList?.map((data: any, _index: any) => {
                return (
                    <ButtonComponent
                        type={data?.buttonStyles}
                        label={data?.label}
                        disabled={data?.buttonDisabled}
                        click={data?.clickButton}
                        key={getButtonIndex(_index)}
                    />
                );
            })}
        </div>
    );
    return (
        <>
            <ModalComponent
                show={showPopUp}
                closeModal={goToHome}
                children={confirmationPopUpData}
                size='md'
                extraClass='header-alignment'
                footerContent={modalFooterData}
            />
            <div className='nexus-row' data-testid='confirmation-component'>
                <div>
                    <NexusCard className='nexus-card-border-0 nexus-py-1 nexus-pl-2 card-height'>
                        <NexusCardBody>
                            <div className='nexus-row nexus-mt-2'>
                                <div className='circle nexus-ml-1'>1</div>
                                <div className='page-title nexus-ml-1'>{t('confirmationPage.confirmationHeader')}</div>
                            </div>
                            <div className='page-title-text nexus-mt-1 nexus-ml-3 nexus-mb-2'>
                                {t('confirmationPage.confirmationBody')}
                            </div>

                            <div className='confirmation-card'>
                                <div className='confirmation-inner-border confirmation-section-1'>
                                    <div className='nexus-mt-2'>
                                        <div>
                                            <div className='nexus-flex-row align-mapping'>
                                                <div>
                                                    <span className='sub-title-bold'>
                                                        {' '}
                                                        {t('confirmationPage.sectorName')}:{' '}
                                                    </span>
                                                    {sectorName}
                                                </div>
                                                <div className=' nexus-flex-row align-mapping mapping-section'>
                                                    <NexusIcon src={ArrowSplit}></NexusIcon>
                                                    <span onClick={() => handleEdit(stepperRoutes.step_3)}>
                                                        {t('confirmationPage.mapping')}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=' confirmation-border'></div>
                                    </div>
                                    <div className='nexus-col-lg-12 nexus-mt-2'>
                                        <div className='nexus-row'>
                                            <div className='nexus-col-lg-4 align'>
                                                <div className='capital nexus-mt-1 sub-title-bold'>
                                                    {t('confirmationPage.templateSelection')}
                                                </div>
                                                <NexusIcon
                                                    src={EditIcon}
                                                    className='nexus-ml-1 edit-icon-color'
                                                    onClick={() => handleEdit(stepperRoutes.step_2)}
                                                    data-testid='expression-icon'
                                                />
                                            </div>
                                            <div className='nexus-col-lg-4 align'>
                                                <div className='capital nexus-mt-1 sub-title-bold'>
                                                    {t('confirmationPage.dataSelection')}
                                                </div>
                                                <NexusIcon
                                                    src={EditIcon}
                                                    className='nexus-ml-1 edit-icon-color'
                                                    onClick={() => handleEdit(stepperRoutes.step_4)}
                                                    data-testid='expression-icon'
                                                />
                                            </div>
                                            <div className=' nexus-col-lg-4 align'>
                                                <div className='nexus-mt-1 capital sub-title-bold'>
                                                    {t('expressionBuilder.header1')}
                                                </div>
                                                <NexusIcon
                                                    src={EditIcon}
                                                    className='nexus-ml-1 edit-icon-color'
                                                    onClick={() => handleEdit(stepperRoutes.step_5)}
                                                    data-testid='expression-icon'
                                                />
                                            </div>
                                            {testSessionTestCases?.map((data: any) => {
                                                const { scriptConfiguration, testCasePreview, name, dataSourceName } =
                                                    data;
                                                return (
                                                    <div className='nexus-row nexus-col-lg-12'>
                                                        <div className='nexus-body nexus-col-lg-4 nexus-mt-1 exp-builder-info template-align'>
                                                            {name}
                                                        </div>
                                                        <div className='nexus-body nexus-ml-3 nexus-col-lg-4 nexus-mt-1 temp-selection-info data-align'>
                                                            {dataSourceName}
                                                        </div>

                                                        <div className='nexus-body nexus-col-lg-3 nexus-mt-1 exp-builder-info expression-align'>
                                                            {scriptConfiguration?.type === EXPRESSION
                                                                ? testCasePreview
                                                                : scriptConfiguration?.expression_string}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className='confirmation-inner-border confirmation-section-2'>
                                    <div>
                                        <div className='nexus-subtitle nexus-my-2'>
                                            {t('confirmationPage.testScheduler')}
                                        </div>
                                        <div className='nexus-data nexus-mt-4 nexus-mb-1'>
                                            {t('confirmationPage.frequency')}
                                        </div>
                                        <Select
                                            selectedValue={dropdownValue}
                                            options={schedulerDropdownValues}
                                            customOnChange={handleDropDown}
                                            disabledOption={t('dataSelection.pleaseSelect')}
                                        />

                                        <div className='nexus-mt-4 nexus-pb-1'>
                                            <div className='nexus-data'>{t('confirmationPage.endDate')}</div>
                                            <DatePicker
                                                data-testid='input-textbox-date-to'
                                                format={'dd-MM-y'}
                                                monthPlaceholder={DateFormat.monthPlaceholder}
                                                dayPlaceholder={DateFormat.dayPlaceholder}
                                                yearPlaceholder={DateFormat.yearPlaceholder}
                                                className='date-picker'
                                                disabled={true}
                                                calendarIcon={<NexusIcon src={CalendarIcon} size='sm' />}
                                            />
                                        </div>
                                        <div className='nexus-mt-4'>
                                            <div className='nexus-data'>{t('confirmationPage.startDate')}</div>
                                            <DatePicker
                                                data-testid='input-textbox-date-from'
                                                format={'dd-MM-y'}
                                                monthPlaceholder={DateFormat.monthPlaceholder}
                                                dayPlaceholder={DateFormat.dayPlaceholder}
                                                yearPlaceholder={DateFormat.yearPlaceholder}
                                                className='date-picker'
                                                disabled={true}
                                                calendarIcon={<NexusIcon src={CalendarIcon} size='sm' />}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='nexus-flex-row-reverse nexus-mt-1'>
                                <div className='nexus-row'>
                                    <div className='nexus-mr-2'>
                                        <ButtonComponent
                                            type={['nexus-btn nexus-rhythm-top-5']}
                                            label={t('buttons.back')}
                                            disabled={false}
                                            click={handleBackButton}
                                        />
                                    </div>
                                    <div className='nexus-mr-1'>
                                        <ButtonComponent
                                            extraClass='nexus-btn-default'
                                            type={['nexus-btn-primary nexus-rhythm-top-5']}
                                            label={t('buttons.runTest')}
                                            click={handleRunButton}
                                            disabled={checkDisabledParams()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </NexusCardBody>
                    </NexusCard>
                </div>
            </div>
        </>
    );
};
export default ConfirmationComponent;
