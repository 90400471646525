/* eslint-disable camelcase */
import { PublicClientApplication } from '@azure/msal-browser';
import { REACT_APP_CLIENT_ID, REACT_APP_OAUTH_BASE_URL, REACT_APP_TENANT_ID } from '../../config/settings';
import { IAdSpaAuthProps } from '../authProviderTypes';

// Configuration params for AD azure requests
const authority = REACT_APP_OAUTH_BASE_URL + '/' + REACT_APP_TENANT_ID || '';
const client_id = REACT_APP_CLIENT_ID || '';
const scope = ['openid', 'profile'];

export const adSpaAuthenticate = async ({ callBack }: IAdSpaAuthProps) => {
    const msalConfig = {
        auth: {
            authority,
            clientId: client_id,
            scopes: scope,
        },
    };

    const msalInstance = new PublicClientApplication(msalConfig);

    // Create the authentication request
    const loginRequest = {
        scopes: scope,
    };
    try {
        const { accessToken, expiresOn, idToken, tokenType } = await msalInstance.loginPopup(loginRequest);
        callBack && callBack({ accessToken, expiresOn, idToken, tokenType });
        return { accessToken, expiresOn, idToken, tokenType };
    } catch (error) {
        return error;
    }
};

export const adSpaSilentAuthenticate = async ({ callBack, email }: any) => {
    const msalConfig = {
        auth: {
            authority,
            clientId: client_id,
            scopes: scope,
        },
    };
    const msalInstance = new PublicClientApplication(msalConfig);
    const account = msalInstance?.getAllAccounts()?.find((account) => account.username === email);

    // Create the authentication request
    const loginRequest = {
        scopes: scope,
        account: account,
    };
    try {
        const { accessToken, expiresOn, idToken, tokenType } = await msalInstance.acquireTokenSilent(loginRequest);
        callBack && callBack({ accessToken, expiresOn, idToken, tokenType });
        return { accessToken, expiresOn, idToken, tokenType };
    } catch (error) {
        return error;
    }
};

export const adSpaLogout = async ({ callback }: any) => {
    const msalConfig = {
        auth: {
            authority,
            clientId: client_id,
            redirectUri: window.location.origin,
            scopes: scope,
        },
    };

    const msalInstance = new PublicClientApplication(msalConfig);

    // Create the authentication request
    try {
        await msalInstance.logoutPopup();
        callback();
        return;
    } catch (error) {
        return error;
    }
};
