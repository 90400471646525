import store from 'store';
import { format } from 'date-fns';

export const addMessage = (state: any, message: any, action: any) => {
    if (message.autoClose) {
        setTimeout(() => {
            store.dispatch(action(message.id));
        }, message.autoClose);
    }
    return [...state.messages, message];
};
export const removeMessage = (state: any, id: string) => {
    if (state.messages.length === 0) return [];
    return state.messages.filter((message: any) => message.id !== id);
};
export const allSelectedOnCurrentPage = (currentPageItems: any, checkedItems: any) => {
    const allSelectedOnCurrentPage = currentPageItems.every((id: any) => checkedItems?.includes(id));
    return allSelectedOnCurrentPage;
};
export const isMainCheckboxSelected = (tableData: any, checkedItems: any, key: string = 'id') => {
    const currentPageItems = tableData.map((item: any) => item[key]);
    const selectedOnCurrentPage = allSelectedOnCurrentPage(currentPageItems, checkedItems);
    return tableData.length > 0 && selectedOnCurrentPage;
};

export const disableDeleteSelected = (checkedItems: any) => {
    return checkedItems?.length <= 0;
};

export const scrollTo = (options: ScrollToOptions) => {
    window.scrollTo(options);
};

export const getAvatarLabel = (label: string) => {
    return label
        ?.split(' ')
        ?.map((word: string, i: number) => {
            if (i < 2) return word?.charAt(0);
            return '';
        })
        ?.join('');
};

export const formatDateTime = (dateString: string) => {
    const date: Date = new Date(dateString);
    const formattedDate: string = format(date, 'dd/MM hh:mm a');
    return formattedDate;
};
