import { NexusIcon, NexusInput, NexusSearch } from '@nexus/react';

import ActionIcSearch24px from '@nexus/core/dist/assets/icons/action/ic_search_24px.svg';
import React from 'react';

type InputProps = {
    autocomplete?: 'off';
    placeholder?: string;
    initialValue?: string;
    onInputSearchValue?: (e: React.FormEvent<HTMLNexusInputElement>) => void;
    extraClass?: string;
};

const SearchComponent: React.FC<InputProps> = (props: InputProps) => {
    return (
        <NexusSearch>
            <NexusInput
                data-testid='search-bar'
                onInput={(event: React.FormEvent<HTMLNexusInputElement>) => props.onInputSearchValue?.(event)}
                autocomplete={props.autocomplete}
                placeholder={props.placeholder}
                value={props.initialValue}
                className={props.extraClass}
            >
                <NexusIcon data-testid='search-icon' src={ActionIcSearch24px}></NexusIcon>
            </NexusInput>
        </NexusSearch>
    );
};

export default SearchComponent;
