import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';

export const isLoading = createSelector(
    (state: RootState) => state.notificationsData,
    (notificationsData) => notificationsData?.isLoading,
);

export const unreadCount = createSelector(
    (state: RootState) => state.notificationsData,
    (notificationsData) => notificationsData?.unreadCount,
);
export const notificationsData = createSelector(
    (state: RootState) => state.notificationsData,
    (notificationsData) => notificationsData?.notificationsData,
);
export const notificationsMetaData = createSelector(
    (state: RootState) => state.notificationsData,
    (notificationsData) => notificationsData?.notificationsMetaData,
);
