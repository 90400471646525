import React, { useState } from 'react';

import AddIcon from '@nexus/core/dist/assets/icons/action/ic_add_24px.svg';
import CloneIcon from '@nexus/core/dist/assets/icons/action/ic_content_copy_24px.svg';
import DeleteIcon from '@nexus/core/dist/assets/icons/action/ic_delete_24px.svg';
import LockIcon from '@nexus/core/dist/assets/icons/action/ic_lock_24px.svg';
import { NexusCheckbox, NexusIcon } from '@nexus/react';
import OpenLockIcon from '@nexus/core/dist/assets/icons/action/ic_lock_open_24px.svg';
import Select from '../nds/select/select';

export default function GroupHeader({
    disable,
    updateCondition,
    addCondition,
    node,
    onClone,
    addGroup,
    removeNode,
    cloneButon,
    enableLockButton,
    enableNot,
    disableField,
}: any) {
    const isParentNode = node.id === node.parentId;
    const [lock, setLock] = useState(disable);

    const handleLock = (val: any) => {
        setLock(val);
        updateCondition(node.id, 'disable', val);
    };
    return (
        <div className={'flex-container rule-group-header'}>
            <div>
                <Select
                    disabled={lock || disableField}
                    customOnChange={(selection) => updateCondition(node.id, 'combinator', selection)}
                    selectedValue={node?.combinator?.toUpperCase()}
                    options={[
                        { label: 'AND', value: 'AND' },
                        { label: 'OR', value: 'OR' },
                    ]}
                ></Select>
            </div>
            {enableNot && (
                <NexusCheckbox
                    checked={node.notOperator}
                    disabled={lock || disableField}
                    data-testid={'test'}
                    onClick={(e: any) => {
                        updateCondition(node.id, 'notOperator', e.target.checked);
                    }}
                >
                    {'NOT'}
                </NexusCheckbox>
            )}
            <div onClick={() => addCondition(node.id)} className={lock || disableField ? 'disable' : ''}>
                <NexusIcon size='sm' src={AddIcon} className='align-icon' />
                <strong className='cursor'>Add Expression</strong>
            </div>
            <div onClick={() => addGroup(node.id)} className={lock || disableField ? 'disable' : ''}>
                <NexusIcon size='sm' src={AddIcon} className='align-icon' />
                <strong className='cursor'>Add Group</strong>
            </div>
            {!isParentNode && cloneButon && (
                <NexusIcon
                    data-testid='clone-icon'
                    className={lock ? 'disable' : ''}
                    size='xs'
                    src={CloneIcon}
                    onClick={() => onClone(node)}
                />
            )}
            {enableLockButton &&
                (lock ? (
                    <NexusIcon
                        data-testid='lock-icon'
                        className={disable ? 'disable' : ''}
                        size='xs'
                        src={LockIcon}
                        onClick={() => handleLock(false)}
                    />
                ) : (
                    <NexusIcon
                        data-testid='unlock-icon'
                        className={disable ? 'disable' : ''}
                        size='xs'
                        src={OpenLockIcon}
                        onClick={() => handleLock(true)}
                    />
                ))}
            <div>
                <NexusIcon
                    data-testid='delete-icon'
                    size='md'
                    className={lock || disableField ? 'disable' : 'align-icon nexus-col-md-6'}
                    src={DeleteIcon}
                    onClick={() => removeNode(node.id, node.parentId)}
                />
            </div>
        </div>
    );
}
