import { NexusBadge } from '@nexus/react';
import React from 'react';

interface InputProps {
    label: string;
    overlap?: any;
    variant?: 'default' | 'error' | 'success' | 'warning';
    color?: string;
}
const BadgeComponent: React.FC<InputProps> = (props: InputProps) => {
    return (
        <>
            <NexusBadge
                overlap={props.overlap}
                variant={props.variant}
                className={props.color}
                data-testid='nexus-badge'
            >
                {props.label}
            </NexusBadge>
        </>
    );
};

export default BadgeComponent;
