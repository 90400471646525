import React, { useEffect, useState } from 'react';
import { NexusFormField, NexusInput, NexusLabel, NexusCard, NexusIcon } from '@nexus/react';
import Logo from '@nexus/core/dist/assets/images/nexus-logo.svg';
import './login.scss';
import { SSO_PROVIDERS, getConfiguredProviderType, providerTypes } from '../../../auth/authProviderTypes';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthEmail, setAuthToken, setDisableAuthRefresh, setLoading } from '../../../auth';
import { authenticate } from '../../../auth/authProvider';
import LoaderComponent from '../nds/loader/loader';
import { REACT_APP_IGNORE_AUTH_REFRESH } from 'config/settings';
import { useSearchParams } from 'react-router-dom';

interface LoginProps {
    onLogin: (res: any) => void;
}

const Login = ({ onLogin }: LoginProps) => {
    const emailFromStore = useSelector(selectAuthEmail);
    const [email, setEmail] = useState(emailFromStore);
    const loading = useSelector((state: any) => state.auth.loading);
    const [password, setPassword] = useState();
    const type = getConfiguredProviderType();
    const dispatch = useDispatch();
    const isSSO = SSO_PROVIDERS.includes(type);
    const [searchParams] = useSearchParams();
    let disableAuthRefreshParamVal = searchParams.get('disableAuthRefresh');
    let accessTokenParamVal = searchParams.get('accessToken');
    useEffect(() => {
        const disableAuthRefreshVal =
            REACT_APP_IGNORE_AUTH_REFRESH === 'allow' && disableAuthRefreshParamVal === 'true';
        dispatch(setDisableAuthRefresh(disableAuthRefreshVal));
        if (disableAuthRefreshVal === true) {
            dispatch(setAuthToken({ accessToken: accessTokenParamVal }));
        }
        if (isSSO && !loading && !disableAuthRefreshVal) {
            dispatch(setLoading(true));
            authenticate({ callBack: onLogin, email, password });
        }
    }, []);
    useEffect(() => {
        setEmail(emailFromStore);
    }, [emailFromStore]);

    const handleSignIn = async () => {
        dispatch(setLoading(true));
        await authenticate({ callBack: onLogin, email, password });
    };
    return (
        <div className='nexus-container nexus-body'>
            <div className='nexus-row'>
                <div className='nexus-col-xs-4 nexus-col-lg-offset-3 nexus-col-lg-5'>
                    <div className='nexus-center-xs login-page-logo'>
                        <NexusIcon src={Logo} />
                        <div className='login-page-subheader'>
                            <p className='nexus-h4'>Welcome to Nexus for Risk</p>
                        </div>
                    </div>

                    <div className='nexus-theme-dark'>
                        <NexusCard bg-color='black'>
                            {email && (
                                <>
                                    <NexusLabel>Email :</NexusLabel>
                                    <NexusFormField>
                                        <NexusInput
                                            type='email'
                                            placeholder='Email'
                                            disabled={isSSO}
                                            value={email}
                                            onInput={(event: any) => {
                                                setEmail(event.target.value);
                                            }}
                                        />
                                    </NexusFormField>
                                </>
                            )}
                            {type === providerTypes.COGNITO && (
                                <>
                                    <NexusLabel>Password :</NexusLabel>
                                    <NexusFormField>
                                        (
                                        <NexusInput
                                            type='password'
                                            placeholder='Password'
                                            onInput={(event: any) => {
                                                setPassword(event.target.value);
                                            }}
                                        />
                                        )
                                    </NexusFormField>
                                </>
                            )}
                            {loading ? (
                                <button className='nexus-btn nexus-btn-primary nexus-btn-fluid'>
                                    <LoaderComponent show={loading} />
                                </button>
                            ) : (
                                <button className='nexus-btn nexus-btn-primary nexus-btn-fluid' onClick={handleSignIn}>
                                    {isSSO ? 'SSO Login' : 'Sign In'}
                                </button>
                            )}
                        </NexusCard>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Login;
