/**
 * Active Directory Authentication component. Uses Redux store to manage token data and hidden iframe for
 * refreshes.
 */

/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { authenticate, authenticateSilently, logout } from './oauth2Provider';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAuthToken,
    selectIsAuthenticated,
    setInitialState,
    selectIsAuthTokenExpired,
    selectIsLogoutRequested,
    selectRefreshToken,
    selectIDToken,
} from '../authSlice';
import {} from '../../utils/tokenUtils';
import { RootState } from '../../store/index';
import { getTokenDataFromCode } from '../authHelper';

interface InputProps {
    children: React.ReactNode;
    store?: RootState;
    doLogout?: boolean;
}

const OAuth = ({ children, doLogout, store }: InputProps) => {
    const [showChildren, setShowChildren] = useState(false);
    const dispatch = useDispatch();
    // Check store (redux) for the token, if not available the auth request will occur.
    // Could persist the token so refreshes don't force a refetch but that introduces potential
    // security risks

    const accessToken = useSelector(selectAuthToken);
    const idToken = useSelector(selectIDToken);
    const refreshToken = useSelector(selectRefreshToken);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const isTokenExpired = useSelector(selectIsAuthTokenExpired);
    const isLogoutRequested = useSelector(selectIsLogoutRequested);
    const loggingOut = isLogoutRequested || doLogout;

    useEffect(() => {
        if (isTokenExpired) {
            authenticateSilently(refreshToken, dispatch);
        }
    }, [isTokenExpired]);

    useEffect(() => {
        getTokenDataFromCode(dispatch);
    }, [store]);

    useEffect(() => {
        if (!loggingOut) {
            accessToken ? setShowChildren(true) : authenticate();
        }
    }, [accessToken]);

    useEffect(() => {
        if (loggingOut) {
            dispatch(setInitialState(''));
            setShowChildren(false);
            logout(idToken);
        }
    }, [loggingOut]);

    return (
        <>
            {showChildren && isAuthenticated && <>{children}</>}
            {showChildren && !isAuthenticated && <>Not Authenticated - Check env settings.</>}
        </>
    );
};

export default OAuth;
