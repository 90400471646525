import { NexusBreadcrumb, NexusBreadcrumbItem } from '@nexus/react';

import { Link } from 'react-router-dom';
import React from 'react';
import { getUniqueKey } from 'utils/generateButtonIndex';
import './breadcrumb.scss';
interface Props {
    separator: string;
    data: {
        isEnabled: boolean;
        name: string;
        path: string;
    }[];
}

const BreadcrumbComponent: React.FC<Props> = ({ separator, data }) => {
    return (
        <div className='nexus-pb-2 align-left'>
            <NexusBreadcrumb separator={separator}>
                {data.map((breadcrumbItem: { name: string; path: string; isEnabled: boolean }, idx) => {
                    return (
                        <NexusBreadcrumbItem
                            key={getUniqueKey(idx)}
                            data-testid={breadcrumbItem.name}
                            disabled={breadcrumbItem.isEnabled}
                        >
                            {breadcrumbItem.path ? (
                                <Link to={breadcrumbItem.path}>{breadcrumbItem.name}</Link>
                            ) : (
                                breadcrumbItem.name
                            )}
                        </NexusBreadcrumbItem>
                    );
                })}
            </NexusBreadcrumb>
        </div>
    );
};

export default BreadcrumbComponent;
