import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';

export const testSessionCountInfo = createSelector(
    (state: RootState) => state.testSessionData,
    (testSessionData) => testSessionData?.testSessionCountInfo,
);

export const testSessionData = createSelector(
    (state: RootState) => state.testSessionData,
    (testSessionData) => testSessionData.testSessionTableData,
);

export const canonicalModels = createSelector(
    (state: RootState) => state.testSessionData,
    (testSessionData) => testSessionData.canonicalModelsData,
);

export const checkedItems = createSelector(
    (state: RootState) => state.testSessionData,
    (testSessionData) => testSessionData.checkedItems,
);

export const currentTestSession = createSelector(
    (state: RootState) => state.testSessionData,
    (testSessionData) => testSessionData.currentTestSession,
);

export const sectorDataList = createSelector(
    (state: RootState) => state.testSessionData,
    (testSessionData) => testSessionData.sectorData,
);

export const lobDataList = createSelector(
    (state: RootState) => state.testSessionData,
    (testSessionData) => testSessionData.lobData,
);

export const processDataList = createSelector(
    (state: RootState) => state.testSessionData,
    (testSessionData) => testSessionData.processData,
);

export const riskDataList = createSelector(
    (state: RootState) => state.testSessionData,
    (testSessionData) => testSessionData.riskData,
);

export const controlDataList = createSelector(
    (state: RootState) => state.testSessionData,
    (testSessionData) => testSessionData.controlData,
);

export const templateSelectionData = createSelector(
    (state: RootState) => state.testSessionData,
    (testSessionData) => testSessionData.templateSelectionData,
);

export const isLoading = createSelector(
    (state: RootState) => state.testSessionData,
    (testSessionData) => testSessionData.isLoading,
);
